import React, { useEffect } from "react";
import Select from "react-select";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { fetchBrick } from "../../../Redux/brickReduce";
import {
  personnelAdded,
  personnelGetById,
} from "../../../Redux/personnelReduce";
import { fetchSpecialite } from "../../../Redux/specialiteReduce";
import { fetchGouvernorat } from "../../../Redux/gouvernoratReduce";
import { fetchTypepersonnel } from "../../../Redux/typepersonnelReduce";
import { fetchCategorie } from "../../../Redux/categorieReduce";

function AjouterPersonnel() {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  const [nomPrenom, setNomPrenom] = React.useState("");
  const [adresse, setAdresse] = React.useState("");
  const [code, setCode] = React.useState("");
  const [testBtn, setTestBtn] = React.useState(false);

  // brick
  const [bricksOptions, setBricksOptions] = React.useState([
    {
      value: "",
      label: "Brick",
      isDisabled: true,
    },
  ]);
  const [brickSelect, setBrickSelect] = React.useState({
    value: "",
    label: "Brick",
    isDisabled: true,
  });
  const [brickRequired] = React.useState(true);

  // specialite
  const [specialiteOptions, setSpecialiteOptions] = React.useState([
    {
      value: "",
      label: "Spécialité",
      isDisabled: true,
    },
  ]);
  const [specialiteSelect, setSpecialiteSelect] = React.useState({
    value: "",
    label: "Spécialité",
    isDisabled: true,
  });
  const [specialiteRequired] = React.useState(true);

  // gouvernorat
  const [gouvernoratOptions, setGouvernoratOptions] = React.useState([
    {
      value: "",
      label: "Gouvernorat",
      isDisabled: true,
    },
  ]);
  const [gouvernoratSelect, setGouvernoratSelect] = React.useState({
    value: "",
    label: "Gouvernorat",
    isDisabled: true,
  });
  const [gouvernoratRequired] = React.useState(true);

  // type
  // gouvernorat
  const [typeOptions, setTypeOptions] = React.useState([
    {
      value: "0",
      label: "Type",
      isDisabled: true,
    },
  ]);
  const [typeSelect, setTypeSelect] = React.useState({
    value: "0",
    label: "Type",
    isDisabled: true,
  });
  const [typeRequired] = React.useState(true);

  // categorie
  const [categorieSelect, setCategorieSelect] = React.useState({
    value: "0",
    label: "Catégorie",
    isDisabled: true,
  });
  const [categoriesOptions, setCategoriesOptions] = React.useState([
    {
      value: "0",
      label: "Catégorie",
      isDisabled: true,
    },
  ]);

  const [categorieRequired] = React.useState(true);

  const [id, setId] = React.useState(0);

  function submitForm() {
    var typePers = typeSelect.value;
    var idBrick = brickSelect.value;
    var idSpec = specialiteSelect.value;
    var idGouv = gouvernoratSelect.value;
    var categoriePers = categorieSelect.value;

    var typeClass = document.querySelector("#typeClass .react-select__control");
    typeClass.style.borderColor = "#ccc";
    if (typePers == 0) {
      typeClass.style.borderColor = "red";
      notify(2, "Choisir un type personnel");
    }

    var brickClass = document.querySelector(
      "#brickClass .react-select__control"
    );
    brickClass.style.borderColor = "#ccc";
    if (idBrick === "") {
      brickClass.style.borderColor = "red";
      notify(2, "Choisir un brick");
    }

    var gouvClass = document.querySelector("#gouvClass .react-select__control");
    gouvClass.style.borderColor = "#ccc";
    if (idGouv === "") {
      gouvClass.style.borderColor = "red";
      notify(2, "Choisir une gouvernorat");
    }

    var specClass = document.querySelector("#specClass .react-select__control");
    specClass.style.borderColor = "#ccc";
    if (idSpec === "") {
      specClass.style.borderColor = "red";
      notify(2, "Choisir une spécialité ");
    }

    var categClass = document.querySelector(
      "#categClass .react-select__control"
    );
    categClass.style.borderColor = "#ccc";
    if (categoriePers == 0) {
      categClass.style.borderColor = "red";
      notify(2, "Choisir une catégorie ");
    }

    if (
      nomPrenom !== "" &&
      adresse !== "" &&
      idBrick > 0 &&
      idGouv > 0 &&
      idSpec > 0 &&
      categoriePers !== null &&
      typePers !== null &&
      code !== ""
    ) {
      setTestBtn(true);
      dispatch(
        personnelAdded({
          nomPrenom,
          adresse,
          idBrick,
          idGouv,
          idSpec,
          categoriePers,
          typePers,
          code,
          id,
        })
      );
      if (isNaN(location.id) === true) notify(1, "Insertion avec succès");
      else notify(1, "Modifié avec succès");
      setTimeout(async () => {
        listPersonnel();
      }, 1500);
    } else {
      notify(2, "Vérifier vos données");
    }
  }

  async function getBrick(p) {
    var entities = [];
    var arrayOption = [];
    var brick = await dispatch(fetchBrick());
    entities = brick.payload;
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      if (e.id === p) {
        setBrickSelect({ value: e.id, label: e.nom });
      }
    });
    setBricksOptions(arrayOption);
  }

  async function getSpecialite(p) {
    var entities = [];
    var arrayOption = [];
    var specialitee = await dispatch(fetchSpecialite());
    entities = specialitee.payload;
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      if (e.id === p) {
        setSpecialiteSelect({ value: e.id, label: e.nom });
      }
    });
    setSpecialiteOptions(arrayOption);
  }

  async function getGouvernorat(p) {
    var entities = [];
    var arrayOption = [];
    var gv = await dispatch(fetchGouvernorat());
    entities = gv.payload;
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      if (e.id === p) {
        setGouvernoratSelect({ value: e.id, label: e.nom });
      }
    });
    setGouvernoratOptions(arrayOption);
  }

  async function getType(p) {
    var entities = [];
    var arrayOption = [];
    var typee = await dispatch(fetchTypepersonnel());
    entities = typee.payload;
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      if (e.id === p) {
        setTypeSelect({ value: e.id, label: e.nom });
      }
    });
    setTypeOptions(arrayOption);
  }

  async function getCategorie(p) {
    var entities = [];
    var arrayOption = [];
    var typee = await dispatch(fetchCategorie());
    entities = typee.payload;
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      if (e.id === p) {
        setCategorieSelect({ value: e.id, label: e.nom });
      }
    });
    setCategoriesOptions(arrayOption);
  }

  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (isNaN(location.id) === false) {
          var personnel = await dispatch(personnelGetById(location.id));
          var entities = personnel.payload;
          setNomPrenom(entities.nomPrenom);
          setAdresse(entities.adresse);
          setCode(entities.code);
          setId(location.id);
          resolve(entities);
        } else {
          resolve(0);
        }
      }, 0);
    });

    promise.then((value) => {
      var id_brick = 0;
      var id_specialite = 0;
      var id_gouvernorat = 0;
      var type = 0;
      var categ = 0;
      if (value !== 0) {
        id_brick = value.id_brick;
        id_specialite = value.id_specialite;
        id_gouvernorat = value.id_gouvernorat;
        type = value.type;
        categ = value.categorie;
      }
      getBrick(id_brick);
      getSpecialite(id_specialite);
      getGouvernorat(id_gouvernorat);
      getType(type);
      getCategorie(categ);
    });
  }, [location.id, dispatch]);

  function listPersonnel() {
    navigate.push("/listPersonnel");
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={listPersonnel}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        {typeof location.id == "undefined"
                          ? "Ajouter personnel"
                          : "Modifier personnel"}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Nom et prénom * </label>
                            <Form.Control
                              defaultValue={nomPrenom}
                              placeholder="Nom"
                              type="text"
                              onChange={(value) => {
                                setNomPrenom(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Adresse * </label>
                            <Form.Control
                              defaultValue={adresse}
                              placeholder="Adresse"
                              type="text"
                              onChange={(value) => {
                                setAdresse(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="brickClass">
                            <label> Brick * </label>
                            <Select
                              placeholder="Brick"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={brickSelect}
                              onChange={(value) => {
                                setBrickSelect(value);
                              }}
                              options={bricksOptions}
                            />
                          </Form.Group>
                          {brickRequired ? null : (
                            <div className="error">Brick est obligatoire.</div>
                          )}
                        </Col>

                        <Col className="pl-1" md="6">
                          <Form.Group id="specClass">
                            <label> Spécialité * </label>
                            <Select
                              placeholder="Spécialité"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={specialiteSelect}
                              onChange={(value) => {
                                setSpecialiteSelect(value);
                              }}
                              options={specialiteOptions}
                            />
                          </Form.Group>
                          {specialiteRequired ? null : (
                            <div className="error">
                              Spécialité est obligatoire.
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="gouvClass">
                            <label> Gouvernorat * </label>
                            <Select
                              placeholder="Brick"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={gouvernoratSelect}
                              onChange={(value) => {
                                setGouvernoratSelect(value);
                              }}
                              options={gouvernoratOptions}
                            />
                          </Form.Group>
                          {gouvernoratRequired ? null : (
                            <div className="error">
                              Gouvernorat est obligatoire.
                            </div>
                          )}
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group id="typeClass">
                            <label> Type * </label>
                            <Select
                              placeholder="Type"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={typeSelect}
                              onChange={(value) => {
                                setTypeSelect(value);
                              }}
                              options={typeOptions}
                            />
                          </Form.Group>
                          {typeRequired ? null : (
                            <div className="error">Type est obligatoire.</div>
                          )}
                        </Col>
                      </Row>{" "}
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="categClass">
                            <label> Catégorie * </label>
                            <Select
                              placeholder="Catégorie"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={categorieSelect}
                              onChange={(value) => {
                                setCategorieSelect(value);
                              }}
                              options={categoriesOptions}
                            />
                          </Form.Group>
                          {categorieRequired ? null : (
                            <div className="error">
                              Catégorie est obligatoire.
                            </div>
                          )}
                        </Col>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Code One Key * </label>
                            <Form.Control
                              defaultValue={code}
                              placeholder="Code"
                              type="text"
                              onChange={(value) => {
                                setCode(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          id="saveAdd"
                          onClick={submitForm}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default AjouterPersonnel;
