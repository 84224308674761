import React, { useEffect } from "react";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { planAdded, planGetById } from "../../../Redux/planReduce";
import {
  evaluationVadGetById,
  vadUpdateDate,
} from "../../../Redux/evaluationVadReduce";

function UpdateDate() {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();

  const [date, setDate] = React.useState("");

  const [id, setId] = React.useState(0);
  const [testBtn, setTestBtn] = React.useState(false);

  function submitForm() {
    setTestBtn(true);
    dispatch(
      vadUpdateDate({
        id,
        date,
      })
    );
    notify(1, "Modifié avec succès");
    setTimeout(async () => {
      navigate.goBack();
    }, 1500);
  }

  useEffect(() => {
    async function getEvaluationVad() {
      if (isNaN(location.id) === false) {
        var vadd = await dispatch(evaluationVadGetById(location.id));
        setId(vadd.payload.evaluationn.id);
        setDate(vadd.payload.evaluationn.createdAt.split("T")[0]);
      }
    }
    getEvaluationVad();
  }, [location.id, dispatch]);

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={(event) => {
                    navigate.goBack();
                  }}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        {"Modifier date d'insertion"}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Date d'inserstion * </label>
                            <Form.Control
                              defaultValue={date}
                              type="date"
                              onChange={(value) => {
                                setDate(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          onClick={() => submitForm()}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default UpdateDate;
