import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const getNotification = createAsyncThunk(
  "notification/getNotification",
  async (action) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "notification/getNotification",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(action),
      }
    );
    const notif = await response.json();
    return notif;
  }
);
export const updateNotifaction = createAsyncThunk("notification/deleteNotif", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "notification/deleteNotif" , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),

  });
  const notif = await response.json();
  return notif;
});


const notificationReduce = createSlice({
  name: "notification",
  initialState: {
    entities: [],
    loading: false,
  },
  reducers: {
    updateNotif(state, action) {
      fetch(Configuration.BACK_BASEURL + "notification/update/"+action.payload.id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-access-token':token
        },
      });
    },
  },
  extraReducers: {

    [getNotification.pending]: (state, action) => {
      state.loading = true;
    },
    [getNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.entities = [...state.entities, ...action.payload];
    },
    [getNotification.rejected]: (state, action) => {
      state.loading = false;
    },
    
  },
});

export const { updateNotif } = notificationReduce.actions;

export default notificationReduce.reducer;
