import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  evalUpdateEtat,
  evaluationGetById,
} from "../../../Redux/evaluationReduce";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
function DetailEval({ obj }) {
  var idRole = obj.user.id_role;
  var idUser = obj.user.id;

  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [data, setData] = React.useState({});
  const [rep, setRep] = React.useState("");
  const [date, setDate] = React.useState("");
  const [etat, setEtat] = React.useState(0);
  const [score, setScore] = React.useState(0);
  const [alert, setAlert] = React.useState(null);
  const [testBtn, setTestBtn] = React.useState(false);

  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  var id = location.id;

  const getEval = useCallback(async () => {
    var grilles = await dispatch(evaluationGetById(id));
    setData(grilles.payload.grillesObject);
    setEtat(grilles.payload.evaluationn.etat);
    setRep(grilles.payload.evaluationn.reps.nom);
    const date = grilles.payload.evaluationn.createdAt.split("T")[0];
    setDate(date);
    var sommePourcentage = grilles.payload.scoreObject.sommePourcentage;
    var nbVads = grilles.payload.scoreObject.nbVads;
    var scoree = parseFloat(sommePourcentage) / parseInt(nbVads);
    setScore(scoree);
  }, [dispatch]);

  const confirmMessage = (id, etat) => {
    localStorage.setItem("commentaire", "");
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={
          etat == 2
            ? "Êtes-vous sûr d'approuver cette Evaluation trimestrielle ?"
            : "Êtes-vous sûr d'annuler cette Evaluation trimestrielle ?"
        }
        onConfirm={() => changeEtat(id, etat)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
        <div>
          <Form.Group className="input-comment">
            <label>Note</label>
            <textarea
              className="form-control"
              onChange={(value) => {
                localStorage.setItem("commentaire", value.target.value);
              }}
              rows="5"
            ></textarea>
          </Form.Group>
        </div>
      </SweetAlert>
    );
  };

  function changeEtat(id, etat) {
    var note = localStorage.getItem("commentaire");
    if (note !== "") {
      setTestBtn(true);
      dispatch(evalUpdateEtat({ id, etat, note, idUser })).then((e1) => {
        getEval();
        switch (etat) {
          case 2:
            notify(1, "Acceptation avec succès");
            hideAlert();
            break;
          case 3:
            notify(1, "Annulation avec succès");
            hideAlert();
            break;
          default:
            break;
        }
      });
    } else {
      notify(2, "Écrire une note");
    }
  }

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    getEval();
  }, [getEval]);

  return (
    <>
      {!testBtn ? alert : ""}
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <>
              <Card>
                <Card>
                  <Card.Header className="background-white">
                    <Col md="12">
                      <Button
                        id="returnBtn"
                        className=""
                        type="button"
                        variant="info"
                        onClick={(event) => {
                          navigate.goBack();
                        }}
                        style={{ float: "right" }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-list"></i>
                        </span>
                        Retour à la liste
                      </Button>
                    </Col>
                    <h3 className="eval-title" style={{ textAlign: "center" }}>
                      Détails Evaluation Trimestrielle
                    </h3>
                  </Card.Header>
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong> Rep : {rep}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Card>
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong>{date}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong> SCORE : {score + " " + "%"}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                {Object.entries(data).map(([key, val]) => {
                  return (
                    <Col md="12">
                      <Card>
                        <Card.Header className="background-detail">
                          <Card.Title className="title-detail" as="h4">
                            <strong>{val[0].grille.axes.designation}</strong>
                            <strong style={{ marginLeft: "20px" }}>
                              SCORE :
                              <b style={{ marginLeft: "5px" }}>
                                {val[0].grille.score}
                              </b>
                            </strong>
                          </Card.Title>
                        </Card.Header>
                        <Card.Body>
                          {val.map((v, k) => {
                            return (
                              <div key={k}>
                                <b className="title-grille">
                                  {v.grille.grilles.designation}
                                </b>

                                <ul className="ul-detaileval">
                                  {v.grille.kpis !== null ? (
                                    <li className="li-detaileval">
                                      {v.grille.kpi == 1
                                        ? "I" +
                                          ":" +
                                          " " +
                                          v.grille.kpis.designation
                                        : v.grille.kpi == 2
                                        ? "S" +
                                          ":" +
                                          " " +
                                          v.grille.kpis.designation
                                        : "E" +
                                          ":" +
                                          " " +
                                          v.grille.kpis.designation}
                                    </li>
                                  ) : (
                                    <li className="li-detaileval">
                                      {v.grille.kpi == 1
                                        ? "I"
                                        : v.grille.kpi == 2
                                        ? "S"
                                        : "E"}
                                    </li>
                                  )}{" "}
                                </ul>
                                {v.grille.remarque !== null ? (
                                  <p>
                                    <b>Remarque : </b> {v.grille.remarque}
                                  </p>
                                ) : (
                                  ""
                                )}
                                <hr></hr>
                              </div>
                            );
                          })}
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
                {idRole === 3 ? (
                  etat === 1 ? (
                    <Row>
                      <Col md="6">
                        <Button
                          onClick={(event) => {
                            confirmMessage(id, 2);
                          }}
                          variant="info"
                          className="accept-btn"
                          size="md"
                        >
                          Approuver
                          <i className={"fa fa-check"} />
                        </Button>
                      </Col>
                      <Col md="6">
                        <Button
                          onClick={(event) => {
                            confirmMessage(id, 3);
                          }}
                          variant="danger"
                          size="md"
                        >
                          Annuler
                          <i className={"fa fa-times"} />
                        </Button>
                      </Col>
                    </Row>
                  ) : etat == 2 ? (
                    <div className="etat-vad">
                      <b> Evaluation trimestrielle lu et approuvée </b>
                    </div>
                  ) : (
                    <div className="etat-vad">
                      <b> Evaluation trimestrielle annulée</b>
                    </div>
                  )
                ) : (
                  ""
                )}
              </Card>
            </>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default DetailEval;
