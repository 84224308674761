import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { fetchAnnee } from "../../../Redux/anneeReduce";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { verification } from "../../../Redux/usersReduce";
import { anneeChangeEtat } from "../../../Redux/anneeReduce";
import { toast, ToastContainer } from "react-toastify";
import MaterialReactTable from "material-react-table";
import { useMemo } from "react";

// core components
function ListAnnee() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [entities, setEntities] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const columns = useMemo(
    () => [
      //column definitions...
      {
        header: "Année",
        accessorKey: "annee",
      },
      {
        accessorKey: "id",
        header: "Actions",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                navigate.push("/annee/update/" + cell.row.original.id);
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-edit" />
            </Button>
          </div>
        ),
      },
      //end
    ],
    []
  );
  function ajouter() {
    navigate.push("/ajouterAnnee");
  }
  const getAnnee = useCallback(
    async (titre) => {
      var annee = await dispatch(fetchAnnee());
      setEntities(annee.payload);
      setLoader(false);
    },
    [dispatch]
  );
  function changeEtat(id, e) {
    dispatch(anneeChangeEtat(id)).then((e1) => {
      getAnnee();
      switch (e) {
        case 0:
          notify(1, "Activation avec succès");
          break;
        case 1:
          notify(1, "Désactivation avec succès");

          break;
        default:
          break;
      }
    });
  }
  //verif token
  const verifToken = useCallback(async () => {
    var response = await dispatch(verification());
    if (response.payload === false) {
      localStorage.clear();
      window.location.replace("/login");
    }
  }, [dispatch]);

  useEffect(() => {
    verifToken();
    getAnnee();
  }, [getAnnee, verifToken]);

  function ListTable({ list }) {
    return (
      <MaterialReactTable
        columns={columns}
        data={list}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }
  return (
    <>
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col md="12">
            <Button
              id="saveAdd"
              className="btn-wd  mr-1 float-left"
              type="button"
              variant="info"
              onClick={ajouter}
            >
              <span className="btn-label">
                <i className="fas fa-plus"></i>
              </span>
              Ajouter une année
            </Button>
          </Col>
          <Col md="12">
            <h4 className="title">Liste des années </h4>
            <Card>
              <Card.Body>
                <ListTable list={entities}></ListTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListAnnee;
