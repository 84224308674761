import { createAsyncThunk } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchAxe = createAsyncThunk("axe/fetchAxe", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "axe/allAxe", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },

  });
  const axe = await response.json();
  return axe;
});

export const axeAdded = createAsyncThunk("axe/addAxe", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "axe/addAxe", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const axe = await response.json();
    return axe;
  });

export const axeGetById = createAsyncThunk("axe/axeGetById", async (id1) => {
  const  id  = id1;
  const response = await fetch(Configuration.BACK_BASEURL + "axe/getAxe", {
    method: 'POST',
    headers: {
      'id':id,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const axe = await response.json();
  return axe;
});



export const axeChangeEtat = createAsyncThunk("axe/changeEtat",async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "axe/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const axe = await response.json();
    return axe;
  }
);



