import { Form, Button, Card, Container, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { planCoachingFlmGetById } from "../../../Redux/planCoachingFlmReduce";

// core components
function DetailPlanCoachingFlm({ obj }) {
  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [data, setData] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [date, setDate] = React.useState("");

  const getPlanCoaching = useCallback(async () => {
    var planCoaching = await dispatch(planCoachingFlmGetById(location.id));
    setData(planCoaching.payload.lignePlans);
    setUser(planCoaching.payload.userr);
    setDate(planCoaching.payload.plan.createdAt.split("T")[0]);
  }, [dispatch]);

  useEffect(() => {
    getPlanCoaching();
  }, [location.id, dispatch]);

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <>
              {" "}
              <Card>
                <Card>
                  <Card.Header className="background-white">
                    <Col md="12">
                      <Button
                        id="returnBtn"
                        className=""
                        type="button"
                        variant="info"
                        onClick={(event) => {
                          navigate.goBack();
                        }}
                        style={{ float: "right" }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-list"></i>
                        </span>
                        Retour à la liste
                      </Button>
                    </Col>
                    <h3 className="eval-title" style={{ textAlign: "center" }}>
                      Détails Plan De Coaching
                    </h3>
                  </Card.Header>
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong> FLM : {user.nom}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Card>
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong>{date}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Col md="18">
                  {data.map((val, key) => {
                    return (
                      <Col md="12">
                        <Card>
                          <Card.Header className="background-detail">
                            <Card.Title className="title-detail" as="h4">
                              {val.plan_coachings.designation}
                            </Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <h5>
                              <b>Commentaire : </b> {val.commentaire}
                            </h5>
                            <hr></hr>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Col>
              </Card>
            </>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default DetailPlanCoachingFlm;
