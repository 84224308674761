import React, { useEffect, useRef } from "react";
import Select from "react-select";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { fetchGouvernorat } from "../../../Redux/gouvernoratReduce";
import { fetchSpecialite } from "../../../Redux/specialiteReduce";
import {
  getPersonnelsBySpAndGv,
  personnelGetById,
} from "../../../Redux/personnelReduce";
import SignatureCanvas from "react-signature-canvas";
import {
  consentementAdded,
  consentementGetById,
  fetchConsentement,
} from "../../../Redux/consentementReduce";

function AjouterConsentement({ obj }) {
  var idUser = obj.user.id;
  var idRole = obj.user.id_role;

  const signatureRef = useRef();
  const anneeObj = JSON.parse(localStorage.getItem("annee"));
  var annee = anneeObj.label;
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  const [commentaire, setCommentaire] = React.useState("");
  const [code, setCode] = React.useState("");
  const [accept, setAccept] = React.useState(true);
  const [refused, setRefused] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [tel, setTel] = React.useState("");
  const [id, setId] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [locked, setLocked] = React.useState(false);
  const [signature, setSignature] = React.useState(false);
  const [codePersonnels, setCodePersonnels] = React.useState([]);
  const [type, setType] = React.useState({
    value: "",
    label: "Spécialité",
    isDisabled: true,
  });
  const [optionsType] = React.useState([
    {
      value: "",
      label: "Type",
      isDisabled: true,
    },
    {
      value: "1",
      label: "Pharmacien",
    },
    {
      value: "2",
      label: "Médecin",
    },
  ]);

  // specialite
  const [specialiteOptions, setSpecialiteOptions] = React.useState([
    {
      value: "",
      label: "Spécialité",
      isDisabled: true,
    },
  ]);
  const [specialiteSelect, setSpecialiteSelect] = React.useState({
    value: "",
    label: "Spécialité",
    isDisabled: true,
  });
  const [specialiteRequired] = React.useState(true);

  // gouvernorat
  const [gouvernoratOptions, setGouvernoratOptions] = React.useState([
    {
      value: "",
      label: "Gouvernorat",
      isDisabled: true,
    },
  ]);
  const [gouvernoratSelect, setGouvernoratSelect] = React.useState({
    value: "",
    label: "Gouvernorat",
    isDisabled: true,
  });
  const [gouvernoratRequired] = React.useState(true);

  const [optionsPersonnels, setOptionsPersonnels] = React.useState([
    {
      value: "",
      label: "Personnel",
      isDisabled: true,
    },
  ]);
  const [personnelSelect, setPersonnelSelect] = React.useState({
    value: "",
    label: "Personnel",
  });
  const [personnelRequired] = React.useState(true);
  const [testBtn, setTestBtn] = React.useState(false);
  const saveTableCalled = useRef(false);

  function submitForm() {
    if (saveTableCalled.current) return;
    saveTableCalled.current = true;
    var idSpec = type.value;
    var idGouv = gouvernoratSelect.value;
    var idPersonnel = personnelSelect.value;

    var gouvClass = document.querySelector("#gouvClass .react-select__control");
    gouvClass.style.borderColor = "#ccc";
    if (idGouv === "") {
      gouvClass.style.borderColor = "red";
      notify(2, "Choisir une gouvernorat");
    }

    var specClass = document.querySelector("#specClass .react-select__control");
    specClass.style.borderColor = "#ccc";
    if (idSpec === "") {
      specClass.style.borderColor = "red";
      notify(2, "Choisir une spécialité ");
    }

    var persClass = document.querySelector("#persClass .react-select__control");
    persClass.style.borderColor = "#ccc";
    if (idPersonnel === "") {
      persClass.style.borderColor = "red";
      notify(2, "Choisir un personnel ");
    }
    var signatureData = signatureRef.current.toDataURL();

    if (
      idPersonnel > 0 &&
      commentaire !== "" &&
      signatureRef.current.isEmpty() === false
    ) {
      if (!codePersonnels.includes(code)) {
        setTestBtn(true);
        dispatch(
          consentementAdded({
            idPersonnel,
            commentaire,
            signatureData,
            email,
            tel,
            accept,
            code,
            id,
            idUser,
          })
        );

        if (isNaN(location.id) === true) notify(1, "Insertion avec succès");
        else notify(1, "Modifié avec succès");
        setTimeout(async () => {
          if (idRole === 1) {
            suiviConsentement();
          } else {
            listConsentement();
          }
          saveTableCalled.current = false;
        }, 1500);
      } else {
        saveTableCalled.current = false;
        notify(2, "Personnel est déjà inséré");
      }
    } else {
      saveTableCalled.current = false;
      notify(2, "Vérifier vos données");
    }
  }

  async function getSpecialite(p) {
    var entities = [];
    var arrayOption = [];
    var specialitee = await dispatch(fetchSpecialite());
    entities = specialitee.payload;
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      if (e.id === p) {
        setSpecialiteSelect({ value: e.id, label: e.nom });
      }
    });
    setSpecialiteOptions(arrayOption);
  }

  async function getGouvernorat(p) {
    var entities = [];
    var arrayOption = [];
    var gv = await dispatch(fetchGouvernorat());
    entities = gv.payload;
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      if (e.id === p) {
        setGouvernoratSelect({ value: e.id, label: e.nom });
      }
    });
    setGouvernoratOptions(arrayOption);
  }

  async function getPersonnels(idSp, idGv) {
    var arrayOption = [];
    var users = await dispatch(getPersonnelsBySpAndGv({ idSp, idGv }));
    var entities = users.payload;
    arrayOption.push({ value: 0, label: "Personnel", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nomPrenom });
      if (
        e.type === idSp &&
        e.id_gouvernorat === idGv &&
        isNaN(location.id) === false
      ) {
        setPersonnelSelect({ value: e.id, label: e.nomPrenom });
      }
    });
    setOptionsPersonnels(arrayOption);
  }

  async function getCodeOneKey(idPers) {
    var personnel = await dispatch(personnelGetById(idPers));
    setCode(personnel.payload.code);
  }

  const handleClear = () => {
    signatureRef.current.clear();
  };

  const handleToggleLock = () => {
    if (locked) {
      signatureRef.current.on();
    } else {
      signatureRef.current.off();
    }
    setLocked(!locked);
  };

  async function getAllPersonnels() {
    var personnels = [];
    var consentement = await dispatch(fetchConsentement({ annee }));
    consentement.payload.consentements.forEach((e) => {
      personnels.push(e.code);
    });
    setCodePersonnels(personnels);
  }

  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (isNaN(location.id) === false) {
          var consentement = await dispatch(consentementGetById(location.id));
          var entities = consentement.payload;
          setCode(entities.code);
          setCommentaire(entities.commentaire);
          setTel(entities.tel);
          setAccept(entities.accept === 1 ? true : false);
          setRefused(entities.accept === 0 ? true : false);
          setEmail(entities.email);
          setSignature(entities.signature);
          setId(location.id);
          resolve(entities);
        } else {
          resolve(0);
        }
      }, 0);
    });

    promise.then((value) => {
      var id_personnel = 0;
      var id_specialite = 0;
      var id_gouvernorat = 0;
      if (value !== 0) {
        id_personnel = value.id_personnel;
        id_specialite = value.id_specialite;
        id_gouvernorat = value.id_gouvernorat;
      }
      getPersonnels(id_personnel, id_gouvernorat);
      getSpecialite(id_specialite);
      getGouvernorat(id_gouvernorat);
      getAllPersonnels();
    });
  }, [location.id, dispatch]);

  function listConsentement() {
    navigate.push("/listConsentement");
  }

  function suiviConsentement() {
    navigate.push("/suiviConsentement");
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={(event) => {
                    navigate.goBack();
                  }}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        {typeof location.id == "undefined"
                          ? "Ajouter consentement"
                          : "Modifier consentement"}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <p style={{ textAlign: "center" }}>
                          CONSENTEMENT Dans le cadre de l’amélioration continue
                          de sa communication avec ses partenaires, la société
                          OPALIA PHARMA SA sise à Zone industrielle Kalaat al
                          Andalous, Ariana 2022, vous sollicite afin d’obtenir
                          votre CONSENTEMENT pour nous permettre de rester en
                          contact avec vous à travers le courrier électronique
                          (e-mail), le service de messagerie SMS et tout autre
                          canal digital, dans le but de vous offrir nos services
                          et partager avec vous les dernières actualités
                          scientifiques en rapport avec nos produits et les
                          pathologies qui vous intéressent, et ce, pour une
                          période de 6 mois renouvelable. On vous spécifie que
                          vous avez le droit de revenir, à tout moment, sur
                          l'acceptation ou le refus de l’utilisation de vos
                          données mentionnées ci-dessus. L’utilisation de vos
                          données se fait à travers un traitement automatisé
                          avec un niveau de sécurité informatique élevé. La
                          société s’engage à traiter les dites données
                          conformément à la législation en vigueur (la loi
                          organique n° 63 du 24 Juillet 2004) relative à la
                          protection des données personnelles.
                        </p>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="specClass">
                            <label> Spécialité * </label>
                            <Select
                              placeholder="Spécialité"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={type}
                              onChange={(value) => {
                                setType(value);
                                getPersonnels(
                                  value.value,
                                  gouvernoratSelect.value
                                );
                              }}
                              options={optionsType}
                            />
                          </Form.Group>
                          {specialiteRequired ? null : (
                            <div className="error">
                              Spécialité est obligatoire.
                            </div>
                          )}
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group id="gouvClass">
                            <label> Gouvernorat * </label>
                            <Select
                              placeholder="Brick"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={gouvernoratSelect}
                              onChange={(value) => {
                                setGouvernoratSelect(value);
                                getPersonnels(type.value, value.value);
                              }}
                              options={gouvernoratOptions}
                            />
                          </Form.Group>
                          {gouvernoratRequired ? null : (
                            <div className="error">
                              Gouvernorat est obligatoire.
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="persClass">
                            <label> Personnel * </label>
                            <Select
                              placeholder="Personnel"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={personnelSelect}
                              onChange={(value) => {
                                setPersonnelSelect(value);
                                getCodeOneKey(value.value);
                              }}
                              options={optionsPersonnels}
                            />
                          </Form.Group>
                          {personnelRequired ? null : (
                            <div className="error">
                              Personnel est obligatoire.
                            </div>
                          )}
                        </Col>

                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Code One Key * </label>
                            <Form.Control
                              defaultValue={code}
                              placeholder="Code One key"
                              type="text"
                              readOnly
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="2" sm="2" xs="12">
                          <Form.Check className="form-check-radio">
                            <Form.Check.Label>
                              <Form.Check.Input
                                checked={accept}
                                defaultValue="1"
                                type="radio"
                                onClick={(value) => {
                                  setAccept(value.target.checked);
                                  setRefused(false);
                                  setIsDisabled(false);
                                }}
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span> Accepté
                            </Form.Check.Label>
                          </Form.Check>
                        </Col>{" "}
                        <Col md="2" sm="2" xs="12">
                          <Form.Check className="form-check-radio">
                            <Form.Check.Label>
                              <Form.Check.Input
                                checked={refused}
                                defaultValue="1"
                                type="radio"
                                onClick={(value) => {
                                  setRefused(value.target.checked);
                                  setAccept(false);
                                  setIsDisabled(true);
                                }}
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span> Refusé
                            </Form.Check.Label>
                          </Form.Check>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>E-mail* </label>
                            <Form.Control
                              defaultValue={email}
                              placeholder="E-mail"
                              name="Email"
                              id="emailId"
                              className="required"
                              type="text"
                              disabled={isDisabled}
                              onChange={(value) => {
                                setEmail(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Téléphone </label>
                            <Form.Control
                              id="telId"
                              defaultValue={tel}
                              placeholder="Téléphone"
                              type="number"
                              disabled={isDisabled}
                              onChange={(value) => {
                                setTel(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pl-1" md="12">
                          <Form.Group className="input-comment">
                            <label>Commentaire* </label>
                            <textarea
                              value={commentaire}
                              onChange={(value) => {
                                setCommentaire(value.target.value);
                              }}
                              className="form-control text-area"
                              rows="5"
                            ></textarea>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pl-1" md="12">
                          <label>Signature </label>
                          <div
                            style={{
                              border: "1px solid #ccc",
                              padding: "10px",
                              borderRadius: "5px",
                            }}
                          >
                            <SignatureCanvas
                              ref={signatureRef}
                              canvasProps={{
                                width: 1200,
                                height: 200,
                                className: "signature-canvas",
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pl-1" md="3">
                          <Button
                            style={{ marginTop: "30px" }}
                            className="btn"
                            type="button"
                            variant="danger"
                            onClick={handleClear}
                          >
                            Effacer la signature
                          </Button>
                        </Col>
                        <Col className="pl-1" md="3">
                          <Button
                            style={{ marginTop: "30px" }}
                            className="btn"
                            type="button"
                            variant="warning"
                            onClick={handleToggleLock}
                          >
                            {locked
                              ? "Déverrouiller la signature"
                              : "Vérrouiller la signature"}
                          </Button>
                        </Col>
                      </Row>
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          onClick={submitForm}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default AjouterConsentement;
