import { Button, Card, Container, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import MaterialReactTable from "material-react-table";
import { userGetById } from "../../../Redux/usersReduce";
import { fetchPlanCoachingFlm } from "../../../Redux/planCoachingFlmReduce";

// core components
function ListPlanCoachingFlm({ obj }) {
  var idRole = obj.user.id_role;
  var idUser = obj.user.id;

  var date = new Date();
  var jour = date.getDate();
  var mois = date.getMonth() + 1;
  var anneee = date.getFullYear();
  var today = anneee + "-" + mois + "-" + jour;

  const anneeObj = JSON.parse(localStorage.getItem("annee"));
  var annee = anneeObj.label;

  const location = useParams();
  const dispatch = useDispatch();
  var navigate = useHistory();
  var id = location.id;

  const [entities, setEntities] = React.useState([]);
  const [testAdd, setTestAdd] = React.useState(true);
  const [user, setUser] = React.useState({});
  const [loader, setLoader] = React.useState(true);

  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  const columns = useMemo(
    () => [
      //column definitions...
      {
        header: "Utilisateur",
        accessorKey: "nom",
        //  Cell: ({ cell }) => cell.row.original.users.nom,
      },
      {
        header: "FLM",
        accessorKey: "flm",
        // Cell: ({ cell }) => cell.row.original.reps.nom,
      },
      {
        header: "Date d'inserstion",
        accessorKey: "date",
      },
      {
        header: "Trimestre",
        accessorKey: "trimester",
      },

      {
        header: "Détails",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                navigate.push(
                  "/utilisateur/detailPlanCoachingFlm/" + cell.row.original.id
                );
              }}
              variant="warning"
              size="sm"
            >
              <i className="fa fa-eye" />
            </Button>
          </div>
        ),
      },

      {
        accessorKey: "id",
        header: "Actions",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            {/* <Button
              onClick={(ev) => {
                confirmMessage(cell.row.original.id);
              }}
              variant="danger"
              size="sm"
              className="text-danger btn-link delete"
            >
              <i className="fa fa-trash" />
            </Button> */}
            <Button
              onClick={() => {
                navigate.push(
                  "/planCoachingFlm/update/" + cell.row.original.id
                );
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-edit" />
            </Button>
          </div>
        ),
      },
      //end
    ],
    []
  );

  const columnsFlm = useMemo(
    () => [
      //column definitions...
      {
        header: "Utilisateur",
        accessorKey: "nom",
        //  Cell: ({ cell }) => cell.row.original.users.nom,
      },
      {
        header: "FLM",
        accessorKey: "flm",
        // Cell: ({ cell }) => cell.row.original.reps.nom,
      },
      {
        header: "Date d'inserstion",
        accessorKey: "date",
      },
      {
        header: "Trimestre",
        accessorKey: "trimester",
      },

      {
        header: "Détails",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                navigate.push(
                  "/utilisateur/detailPlanCoachingFlm/" + cell.row.original.id
                );
              }}
              variant="warning"
              size="sm"
            >
              <i className="fa fa-eye" />
            </Button>
          </div>
        ),
      },

      //end
    ],
    []
  );

  const getPlanCoaching = useCallback(async () => {
    var user = await dispatch(userGetById(id));
    setUser(user.payload.header);

    var date = new Date();
    var mois = String(date.getMonth() + 1).padStart(2, "0");

    var trimestre1 = ["01", "02", "03", "04"];
    var trimestre2 = ["05", "06", "07", "08"];
    var trimestre3 = ["09", "10", "11", "12"];

    var trimestre = null;
    if (trimestre1.includes(mois.toString())) {
      trimestre = 1;
    }
    if (trimestre2.includes(mois.toString())) {
      trimestre = 2;
    }
    if (trimestre3.includes(mois.toString())) {
      trimestre = 3;
    }

    var testPlan = true;
    var arrayPlans = [];
    var plan = await dispatch(
      fetchPlanCoachingFlm({ annee, idUser, id, idRole })
    );
    var res = await plan.payload.plans;
    var allCoach = await plan.payload.allPlans;
    allCoach.forEach((element) => {
      if (element.trimester == trimestre && element.flms.id == id) {
        testPlan = false;
      }
    });

    res.forEach((element) => {
      if (element.flms.id == id) {
        arrayPlans.push({
          id: element.id,
          nom: element.users.nom,
          flm: element.flms.nom,
          idFlm: element.flms.id,
          trimester: element.trimester,
          etat: element.etat,
          date: element.createdAt.split("T")[0],
        });
      }
    });
    setTestAdd(testPlan);
    setEntities(arrayPlans);
    setLoader(false);
  }, [dispatch]);

  useEffect(() => {
    getPlanCoaching();
  }, []);

  function ListTable({ list }) {
    return (
      <MaterialReactTable
        columns={idRole == 4 ? columns : columnsFlm}
        data={list}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <Card>
          <Card.Header className="background-white">
            <Col md="12">
              <Button
                id="returnBtn"
                className=""
                type="button"
                variant="info"
                onClick={(event) => {
                  navigate.goBack();
                }}
                style={{ float: "right" }}
              >
                <span className="btn-label">
                  <i className="fas fa-list"></i>
                </span>
                Retour à la liste
              </Button>
            </Col>
            <Card.Title className="background-white" as="h3">
              <div style={{ display: "flex" }}>
                <strong> FLM : {user.nom}</strong>
              </div>
            </Card.Title>
          </Card.Header>{" "}
          <Card.Header className="background-white">
            <Card.Title className="background-white" as="h3">
              <div style={{ display: "flex" }}>
                <strong>{today}</strong>
              </div>
            </Card.Title>
          </Card.Header>
          <br clear="all"></br>
          {idRole === 4 ? (
            <Button
              className="btn-val green-button"
              type="button"
              onClick={() => {
                if (testAdd) {
                  navigate.push("/utilisateur/ajouterPlanCoachingFlm/" + id);
                } else {
                  notify(
                    2,
                    "Plan de coaching déjà insérée au cours de cette trimestre"
                  );
                }
              }}
            >
              Plan de coaching
            </Button>
          ) : (
            ""
          )}
          <Col md="12">
            <h4 className="eval-title">Plan de coaching</h4>
            <Card.Body>
              <ListTable list={entities}></ListTable>
            </Card.Body>
          </Col>
        </Card>
      </Container>
    </>
  );
}

export default ListPlanCoachingFlm;
