import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchIframe = createAsyncThunk("iframe/fetchIframe", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "iframe/allIframe", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),
  });
  const iframe = await response.json();
  return iframe;
});

export const fetchAllIframe = createAsyncThunk("iframe/fetchAllIframe", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "iframe/getAllIframe", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),
  });
  const iframe = await response.json();
  return iframe;
});

export const iframeAdded = createAsyncThunk("iframe/addIframe", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "iframe/addIframe", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const iframe = await response.json();
    return iframe;
  });

  export const iframeChangeEtat = createAsyncThunk("iframe/changeEtat",async (id) => {
      const response = await fetch(
        Configuration.BACK_BASEURL + "iframe/changeEtat/" + id,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      );
      const iframe = await response.json();
      return iframe;
    }
  );

  
export const iframeGetById = createAsyncThunk("iframe/iframeGetById", async (id1) => {
  const  id  = id1;
  const response = await fetch(Configuration.BACK_BASEURL + "iframe/getIframe", {
    method: 'POST',
    headers: {
      'id':id,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const iframe = await response.json();
  return iframe;
});



export const deleteIframe = createAsyncThunk("iframe/deleteIframe", async (id) => {
  const response = await fetch(Configuration.BACK_BASEURL + "iframe/deleteIframe/"+id, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const iframe = await response.json();
  return iframe;
});