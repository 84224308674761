import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchReference = createAsyncThunk("reference/fetchReference", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "reference/allReference", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },

  });
  const reference = await response.json();
  return reference;
});

export const referenceAdded = createAsyncThunk("reference/addReference", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "reference/addReference", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const reference = await response.json();
    return reference;
  });

export const referenceGetById = createAsyncThunk("reference/referenceGetById", async (id1) => {
  const  id  = id1;
  const response = await fetch(Configuration.BACK_BASEURL + "reference/getReference", {
    method: 'POST',
    headers: {
      'id':id,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const reference = await response.json();
  return reference;
});



export const referenceChangeEtat = createAsyncThunk(
  "reference/changeEtat",
  async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "reference/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const reference = await response.json();
    return reference;
  }
);



