import { Button, Card, Container, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getImsData } from "../../../Redux/imsDataReduce";

// core components
function DetailsImsData() {
  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [data, setData] = React.useState([]);
  const [type, setType] = React.useState("");
  const [typeIms, setTypeIms] = React.useState("");

  var id = location.id;

  const getData = useCallback(async () => {
    var ims = await dispatch(getImsData(id));
    var entities = ims.payload.data;
    setData(entities);
    setType(ims.payload.entetee.type);
    var typee = "";
    if (ims.payload.entetee.type == 1) {
      typee = "Ventes IMS";
    } else if (ims.payload.entetee.type == 2) {
      typee = "Deploiement";
    } else if (ims.payload.entetee.type == 3) {
      typee = "Produit-Line";
    } else {
      typee = "Line Données régionales";
    }
    setTypeIms(typee);
  }, [dispatch, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container className="container-details">
            <Card>
              <Card.Header className="background-white">
                <Col md="12">
                  <Button
                    className="btn-wd btn-outline mr-1 float-left"
                    type="button"
                    variant="info"
                    onClick={(event) => {
                      navigate.goBack();
                    }}
                    style={{ float: "right" }}
                  >
                    <span className="btn-label">
                      <i className="fas fa-list"></i>
                    </span>
                    Retour à la liste
                  </Button>
                </Col>
                <br clear="all"></br>
                <Card.Title
                  className="background-white"
                  as="h3"
                  style={{ textAlign: "center" }}
                >
                  {"Détails IMS Data - Type : " + typeIms}
                </Card.Title>
              </Card.Header>
              <Card>
                <Card.Body>
                  <div className="table-responsive">
                    <table
                      className={`table table-striped ${
                        type == 1 ? "small-table" : ""
                      }`}
                    >
                      <thead>
                        {type == 1 ? (
                          <tr>
                            <th> Cycle </th>
                            <th> TypeMesure </th>
                            <th> BRICKS </th>
                            <th> MARKET </th>
                            <th> Mois </th>
                            <th> Trimestre </th>
                            <th> YTD </th>
                            <th> MAT </th>
                            <th> AnneeMois </th>
                            <th> Product </th>
                            <th> Mesure </th>
                            <th> MesureN_1</th>
                            <th> MesureMarche </th>
                            <th> MesureN_1Marche </th>
                            <th> MesureNatProduit </th>
                            <th> MesureN_1NatProduit </th>
                            <th> MesureNatMarche </th>
                            <th> MesureN_1NatMarche </th>
                          </tr>
                        ) : type == 2 ? (
                          <tr>
                            <th> Brick </th>
                            <th> Rep </th>
                          </tr>
                        ) : type == 3 ? (
                          <tr>
                            <th> Line </th>
                            <th> Produit </th>
                          </tr>
                        ) : type == 4 ? (
                          <tr>
                            <th> Line </th>
                            <th> Territoire </th>
                            <th> LineHybride </th>
                          </tr>
                        ) : (
                          ""
                        )}
                      </thead>
                      <tbody>
                        {data.map((val, key) => {
                          return (
                            <tr key={key}>
                              {type == 1 ? (
                                <>
                                  <td>{val.cycles.designation}</td>
                                  <td>{val.type_mesure}</td>
                                  <td>{val.bricks.nom}</td>
                                  <td>{val.marches.designation}</td>
                                  <td>{val.mois}</td>
                                  <td>{val.trimestre}</td>
                                  <td>{val.ytd}</td>
                                  <td>{val.mat}</td>
                                  <td>{val.annee_mois}</td>
                                  <td>{val.produits.nom}</td>
                                  <td>{val.mesure}</td>
                                  <td>{val.mesuren_1}</td>
                                  <td>{val.mesure_marche}</td>
                                  <td>{val.mesuren_1marche}</td>
                                  <td>{val.mesure_nat_produit}</td>
                                  <td>{val.mesuren_1natproduit}</td>
                                  <td>{val.mesure_nat_marche}</td>
                                  <td>{val.mesuren_1natmarche}</td>
                                </>
                              ) : type == 2 ? (
                                <>
                                  <td>{val.bricks.nom}</td>
                                  <td>{val.users.nom}</td>
                                </>
                              ) : type == 3 ? (
                                <>
                                  <td>{val.lines.nom}</td>
                                  <td>{val.produits.nom}</td>
                                </>
                              ) : type == 4 ? (
                                <>
                                  <td>{val.line}</td>
                                  <td>{val.territoire}</td>
                                  <td>{val.line_hybride}</td>
                                </>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Card>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default DetailsImsData;
