import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchProduitConcurent = createAsyncThunk("produitConcurent/fetchProduitConcurent", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "produitConcurent/allProduitConcurent", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
  const produitConcurent = await response.json();
  return produitConcurent;
});

export const produitConcurentAdded = createAsyncThunk("produitConcurent/addProduitConcurent", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "produitConcurent/addProduitConcurent", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(action),
  });
  const produitConcurent = await response.json();
  return produitConcurent;
});

export const produitConcurentChangeEtat = createAsyncThunk(
  "produitConcurent/changeEtat",
  async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "produitConcurent/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const produitConcurent = await response.json();
    return produitConcurent;
  }
);

export const produitConcurentGetById = createAsyncThunk("produitConcurent/produitConcurentGetById", async (id1) => {
    const  id  = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "produitConcurent/getProduitConcurent", {
      method: 'POST',
      headers: {
        'id':id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const produitConcurent = await response.json();
    return produitConcurent;
  });
  