import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import MaterialReactTable from "material-react-table";
import {
  deleteEvaluationVad,
  fetchEvaluationVad,
} from "../../../Redux/evaluationVadReduce";
import { fetchAllPlan } from "../../../Redux/planReduce";
import { userGetById } from "../../../Redux/usersReduce";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
function Evaluation({ obj }) {
  var idRole = obj.user.id_role;
  var idUser = obj.user.id;

  var date = new Date();
  var jour = date.getDate();
  var mois = date.getMonth() + 1;
  var anneee = date.getFullYear();
  var today = anneee + "-" + mois + "-" + jour;
  const anneeObj = JSON.parse(localStorage.getItem("annee"));
  var annee = anneeObj.label;
  const location = useParams();
  const dispatch = useDispatch();
  var navigate = useHistory();
  var id = location.id;

  const [entitiesVad, setEntitiesVad] = React.useState([]);
  const [entitiesVadEnCours, setEntitiesVadEnCours] = React.useState([]);
  const [entitiesVadValides, setEntitiesVadValides] = React.useState([]);
  const [entitiesVadAnnules, setEntitiesVadAnnules] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [testVadAdd, setTestVadAdd] = React.useState(true);
  const [user, setUser] = React.useState({});
  const [loader, setLoader] = React.useState(true);

  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  const columnsVad = useMemo(
    () => [
      //column definitions...
      {
        header: "Utilisateur",
        accessorKey: "nom",
      },
      {
        header: "Rep",
        accessorKey: "rep",
      },
      {
        header: "Date d'inserstion",
        accessorKey: "date",
      },
      {
        header: "Détails",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                if (
                  cell.row.original.etat === 1 ||
                  cell.row.original.etat === 2
                ) {
                  navigate.push(
                    "/utilisateur/detailEvalVad/" + cell.row.original.id
                  );
                } else {
                  navigate.push(
                    "/evaluationVad/update/" + cell.row.original.id
                  );
                }
              }}
              variant="warning"
              size="sm"
            >
              <i className="fa fa-eye" />
            </Button>
          </div>
        ),
      },
      {
        header: "Notes",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              className="btn btn-info"
              onClick={() => {
                getCommentaire(cell.row.original.notes);
              }}
              size="sm"
              style={{ marginLeft: "10px" }}
            >
              Lire <i className="fa fa-comment" />
            </Button>
          </div>
        ),
      },
      {
        header: "Plan d'actions",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            {cell.row.original.idPlan == 0 ? (
              <Button
                onClick={() => {
                  navigate.push(
                    "/utilisateur/addPlan/" +
                      cell.row.original.id +
                      "/" +
                      cell.row.original.idRep
                  );
                }}
                variant="info"
                size="sm"
              >
                <i className="fa fa-plus" />
              </Button>
            ) : (
              <Button
                onClick={() => {
                  navigate.push("/plan/detailPlan/" + cell.row.original.idPlan);
                }}
                variant="info"
                size="sm"
              >
                <i className="fa fa-eye" />
              </Button>
            )}
          </div>
        ),
      },
      {
        header: "État",
        accessorKey: "etat",
        Cell: ({ cell, row }) =>
          cell.row.original.etat === 0
            ? "En attente de validation par le superviseur"
            : cell.row.original.etat === 1
            ? "En attente d'approbation par le délégué"
            : cell.row.original.etat === 2
            ? "Approuvée"
            : "Annulée",
      },
      {
        accessorKey: "id",
        header: "Actions",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            {cell.row.original.etat !== 2 ? (
              <Button
                onClick={(ev) => {
                  confirmMessage(cell.row.original.id);
                }}
                variant="danger"
                size="sm"
                className="text-danger btn-link delete"
              >
                <i className="fa fa-trash" />
              </Button>
            ) : (
              ""
            )}
            {cell.row.original.etat === 0 || cell.row.original.etat === 3 ? (
              <Button
                onClick={() => {
                  navigate.push(
                    "/evaluationVad/update/" + cell.row.original.id
                  );
                }}
                variant="warning"
                size="sm"
                className="text-warning btn-link edit"
              >
                <i className="fa fa-edit" />
              </Button>
            ) : (
              ""
            )}
          </div>
        ),
      },
      //end
    ],
    []
  );
  const columnsVadRep = useMemo(
    () => [
      //column definitions...
      {
        header: "Utilisateur",
        accessorKey: "nom",
      },
      {
        header: "Rep",
        accessorKey: "rep",
      },
      {
        header: "Date d'inserstion",
        accessorKey: "date",
      },
      {
        header: "Détails",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                navigate.push(
                  "/utilisateur/detailEvalVad/" + cell.row.original.id
                );
              }}
              variant="warning"
              size="sm"
            >
              <i className="fa fa-eye" />
            </Button>
          </div>
        ),
      },
      {
        header: "Notes",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              className="btn btn-info"
              onClick={() => {
                getCommentaire(cell.row.original.notes);
              }}
              size="sm"
              style={{ marginLeft: "10px" }}
            >
              Lire <i className="fa fa-comment" />
            </Button>
          </div>
        ),
      },

      //end
    ],
    []
  );
  const columnsVadAdmin = useMemo(
    () => [
      //column definitions...
      {
        header: "Utilisateur",
        accessorKey: "nom",
      },
      {
        header: "Rep",
        accessorKey: "rep",
      },
      {
        header: "Date d'inserstion",
        accessorKey: "date",
      },
      {
        header: "Détails",
        accessorKey: "details",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                navigate.push(
                  "/utilisateur/detailEvalVad/" + cell.row.original.id
                );
              }}
              variant="warning"
              size="sm"
            >
              <i className="fa fa-eye" />
            </Button>
          </div>
        ),
      },
      {
        header: "Notes",
        accessorKey: "notes",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              className="btn btn-info"
              onClick={() => {
                getCommentaire(cell.row.original.notes);
              }}
              size="sm"
              style={{ marginLeft: "10px" }}
            >
              Lire <i className="fa fa-comment" />
            </Button>
          </div>
        ),
      },
      {
        accessorKey: "id",
        header: "Actions",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                navigate.push(
                  "/evaluationVad/updateDate/" + cell.row.original.id
                );
              }}
              variant="warning"
              size="sm"
              className="btn-wd  mr-1 float-left"
              style={{ cursor: "pointer", fontSize: 14 }}
            >
              Modifier Date
            </Button>
          </div>
        ),
      },

      //end
    ],
    []
  );

  const getEvaluation = useCallback(async () => {
    var user = await dispatch(userGetById(id));
    setUser(user.payload.header);

    var plans = await dispatch(fetchAllPlan({ annee }));
    var plansArray = plans.payload;
    var tabVads = [];
    plansArray.forEach((element) => {
      tabVads.push({ idVad: element.id_evaluationvad, idPlan: element.id });
    });

    var date = new Date();
    var mois = String(date.getMonth() + 1).padStart(2, "0");
    var jour = String(date.getDate()).padStart(2, "0");
    var anneee = date.getFullYear();
    var today = anneee + "-" + mois + "-" + jour;

    var test = true;
    var arrayVads = [];
    var arrayVadsEnCours = [];
    var arrayVadsValides = [];
    var arrayVadsAnnules = [];

    var evaluationVadd = await dispatch(
      fetchEvaluationVad({ annee, idUser, idRole, id })
    );
    var resVad = await evaluationVadd.payload.evaluationVads;
    var allVads = await evaluationVadd.payload.allEvaluationVads;

    allVads.forEach((e) => {
      if (e.createdAt !== null) {
        if (e.createdAt.split("T")[0] == today && e.reps.id == id) {
          test = false;
        }
      }
    });

    resVad.forEach((element) => {
      if (element.reps.id == id && (element.etat === 1 || element.etat === 0)) {
        var findPlan = tabVads.find((p) => p.idVad === element.id);
        arrayVads.push({
          id: element.id,
          nom: element.users.nom,
          rep: element.reps.nom,
          date: element.createdAt.split("T")[0],
          idRep: element.reps.id,
          notes: element.notes,
          etat: element.etat,
          idPlan: findPlan ? findPlan.idPlan : "0",
        });
      }
      if (element.reps.id == id && element.etat === 1) {
        var findPlan = tabVads.find((p) => p.idVad === element.id);
        arrayVadsEnCours.push({
          id: element.id,
          nom: element.users.nom,
          rep: element.reps.nom,
          date: element.createdAt.split("T")[0],
          idRep: element.reps.id,
          notes: element.notes,
          etat: element.etat,
          idPlan: findPlan ? findPlan.idPlan : "0",
        });
      }
      if (element.reps.id == id && element.etat === 2) {
        var findPlan = tabVads.find((p) => p.idVad === element.id);
        arrayVadsValides.push({
          id: element.id,
          nom: element.users.nom,
          rep: element.reps.nom,
          date: element.createdAt.split("T")[0],
          idRep: element.reps.id,
          notes: element.notes,
          etat: element.etat,
          idPlan: findPlan ? findPlan.idPlan : "0",
        });
      }
      if (element.reps.id == id && element.etat === 3) {
        var findPlan = tabVads.find((p) => p.idVad === element.id);
        arrayVadsAnnules.push({
          id: element.id,
          nom: element.users.nom,
          rep: element.reps.nom,
          date: element.createdAt.split("T")[0],
          idRep: element.reps.id,
          notes: element.notes,
          etat: element.etat,
          idPlan: findPlan ? findPlan.idPlan : "0",
        });
      }
    });

    setEntitiesVad(arrayVads);
    setEntitiesVadEnCours(arrayVadsEnCours);
    setEntitiesVadValides(arrayVadsValides);
    setEntitiesVadAnnules(arrayVadsAnnules);
    setTestVadAdd(test);
    setLoader(false);
  }, [dispatch]);

  const confirmMessage = (id) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={"Êtes-vous sûr de supprimer cette Evaluation VAD ?"}
        onConfirm={() => {
          deleteEvalVad(id);
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      ></SweetAlert>
    );
  };
  function deleteEvalVad(id) {
    dispatch(deleteEvaluationVad(id));
    notify(1, "Evaluation VAD supprimée avec succès");
    hideAlert();
    getEvaluation();
  }
  const hideAlert = () => {
    setAlert(null);
  };

  const getCommentaire = useCallback(async (tableNotes) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={"Les commentaires d'evaluation VAD"}
        onConfirm={() => hideAlert()}
        cancelBtnBsStyle="danger"
      >
        {tableNotes.length > 0 ? (
          <div className="panel-body">
            {tableNotes.map((val, key) => {
              var date = new Date(val.createdAt)
                .toISOString()
                .slice(0, 16)
                .replace("T", " à ");
              return (
                <ul>
                  <span className="discutions">
                    <li className="discutions-li">
                      <i className="nc-icon nc-stre-right"></i>
                      <b>{val.users.nom}:</b> {val.note}
                      <span className="discutions-date"> {date}</span>
                    </li>
                  </span>
                </ul>
              );
            })}
          </div>
        ) : (
          "aucun note trouvé"
        )}
      </SweetAlert>
    );
  }, []);

  useEffect(() => {
    getEvaluation();
  }, []);

  function ListTableVad({ listVad }) {
    return (
      <MaterialReactTable
        columns={idRole == 2 ? columnsVad : columnsVadRep}
        data={listVad}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }
  function ListTableVadEnCours({ listVadEnCours }) {
    return (
      <MaterialReactTable
        columns={
          idRole == 2
            ? columnsVad
            : idRole == 1
            ? columnsVadAdmin
            : columnsVadRep
        }
        data={listVadEnCours}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }
  function ListTableVadValide({ listVadValide }) {
    return (
      <MaterialReactTable
        columns={
          idRole == 2
            ? columnsVad
            : idRole == 1
            ? columnsVadAdmin
            : columnsVadRep
        }
        data={listVadValide}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }
  function ListTableVadAnnule({ listVadAnnule }) {
    return (
      <MaterialReactTable
        columns={idRole == 2 ? columnsVad : columnsVadRep}
        data={listVadAnnule}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }

  return (
    <>
      {alert}
      <Container fluid>
        <ToastContainer />
        <Card>
          <Card.Header className="background-white">
            <Col md="12">
              <Button
                id="returnBtn"
                className=""
                type="button"
                variant="info"
                onClick={(event) => {
                  navigate.goBack();
                }}
                style={{ float: "right" }}
              >
                <span className="btn-label">
                  <i className="fas fa-list"></i>
                </span>
                Retour à la liste
              </Button>
            </Col>
            <br clear="all"></br>
            <Card.Title className="background-white" as="h3">
              <strong> Rep : {user.nom}</strong>
            </Card.Title>
          </Card.Header>
          <Card.Header className="background-white">
            <Card.Title className="background-white" as="h3">
              <strong>{today}</strong>
            </Card.Title>
          </Card.Header>
          <br clear="all"></br>
          {idRole === 2 ? (
            <Row>
              <div className="clearfix"></div>
              <br></br>
              <Button
                className="btn-val green-button"
                type="button"
                onClick={() => {
                  if (testVadAdd) {
                    navigate.push("/utilisateur/vad/" + id);
                  } else {
                    notify(2, "Évaluation VAD déjà insérée aujourd'hui ");
                  }
                }}
              >
                VAD
              </Button>
              <div clear></div>{" "}
            </Row>
          ) : (
            ""
          )}
          {idRole === 2 ? (
            <Col md="12">
              <h4 className="eval-title">Evaluations VAD en cours</h4>
              <Card.Body>
                <ListTableVad listVad={entitiesVad}></ListTableVad>
              </Card.Body>
            </Col>
          ) : (
            ""
          )}
          {idRole === 3 || idRole === 1 || idRole === 4 ? (
            <Col md="12">
              <h4 className="eval-title">Evaluations VAD en cours</h4>
              <Card.Body>
                <ListTableVadEnCours
                  listVadEnCours={entitiesVadEnCours}
                ></ListTableVadEnCours>
              </Card.Body>
            </Col>
          ) : (
            ""
          )}
          <Col md="12">
            <h4 className="eval-title">Evaluations VAD approuvées</h4>
            <Card.Body>
              <ListTableVadValide
                listVadValide={entitiesVadValides}
              ></ListTableVadValide>
            </Card.Body>
          </Col>
          <Col md="12">
            <h4 className="eval-title">Evaluations VAD annulées</h4>
            <Card.Body>
              <ListTableVadAnnule
                listVadAnnule={entitiesVadAnnules}
              ></ListTableVadAnnule>
            </Card.Body>
          </Col>
        </Card>
      </Container>
    </>
  );
}

export default Evaluation;
