import { Form, Button, Row, Card, Container, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getVads } from "../../../Redux/vadReduce";
import {
  evaluationVadAdded,
  evaluationVadGetById,
  evaluationVadSended,
} from "../../../Redux/evaluationVadReduce";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
function Vad({ obj }) {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [data, setData] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [max, setMax] = React.useState(0);
  const [nbVisits, setNbVisits] = React.useState(0);
  const [alert, setAlert] = React.useState(null);
  const [commentaire, setCommentaire] = React.useState("");
  const [insertDate, setInsertDate] = React.useState("");
  const [id, setId] = React.useState(0);
  const [idRepp, setIdRepp] = React.useState(0);
  const [testBtn, setTestBtn] = React.useState(false);
  const saveTableCalled = useRef(false);

  var idRep = location.idRep;
  var idUser = obj.user.id;

  const getVad = useCallback(
    async (idRep) => {
      var vads = await dispatch(getVads({ idRep }));
      setData(vads.payload.vadsAarray);
      setUser(vads.payload.userr);
    },
    [dispatch]
  );

  const handleCheckBox = (key, index, k, isChecked) => {
    var newData = JSON.parse(JSON.stringify(data));

    if (isChecked) {
      newData[key].lignes[index].checks.push(k + 1);
    } else {
      var checksLigne = newData[key].lignes[index].checks.map(Number);
      var indexKey = checksLigne.indexOf(k + 1);
      newData[key].lignes[index].checks.splice(indexKey, 1);
    }
    var keyArray = [];
    var nombreVisits = 0;
    newData.forEach((entry) => {
      entry.lignes.forEach((ligne) => {
        var checksArray = ligne.checks.filter((element) => element !== "");
        nombreVisits = nombreVisits + checksArray.length;
        ligne.checks.forEach((check) => {
          keyArray.push(check);
        });
      });
    });
    newData.forEach((e) => {
      e.lignes.forEach((element) => {
        var totalLigne = element.checks.filter((element) => element !== "");
        var total = totalLigne.length;
        if (Math.max(...keyArray) > 0) {
          var pourcentage = (total / Math.max(...keyArray)) * 100;
          var roundPourc = Math.round(pourcentage);
        } else {
          var roundPourc = 0;
        }
        element.total_visit = total;
        element.pourcentage_visit = roundPourc;
      });
    });
    setMax(keyArray.length === 0 ? 0 : Math.max(...keyArray));
    setNbVisits(nombreVisits);
    setData(newData);
  };

  function submitForm(data) {
    if (saveTableCalled.current) return;
    saveTableCalled.current = true;

    var trimestre1 = ["01", "02", "03", "04"];
    var trimestre2 = ["05", "06", "07", "08"];
    var trimestre3 = ["09", "10", "11", "12"];

    var date = new Date();
    var mois = String(date.getMonth() + 1).padStart(2, "0");

    if (trimestre1.includes(mois.toString())) {
      var trimestre = 1;
    }

    if (trimestre2.includes(mois.toString())) {
      var trimestre = 2;
    }
    if (trimestre3.includes(mois.toString())) {
      var trimestre = 3;
    }
    var somme = ((nbVisits / max) * 5).toFixed(2);
    if (insertDate !== "") {
      setTestBtn(true);
      dispatch(
        evaluationVadAdded({
          data,
          id,
          idRep,
          idUser,
          commentaire,
          max,
          somme,
          trimestre,
          insertDate,
        })
      );
      if (isNaN(location.id) === true) notify(1, "Insertion avec succès");
      else notify(1, "Modifié avec succès");
      setTimeout(async () => {
        saveTableCalled.current = false;
        navigate.goBack();
      }, 1500);
    } else {
      notify(2, "Vérifier vos données");
    }
  }

  const confirmMessage = () => {
    localStorage.setItem("commentaire", "");
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={"Êtes-vous sûr d'envoyer cette evaluation VAD "}
        onConfirm={() => sendVad()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
        <div>
          <Form.Group className="input-comment">
            <label>Note</label>
            <textarea
              className="form-control"
              onChange={(value) => {
                localStorage.setItem("commentaire", value.target.value);
              }}
              rows="5"
            ></textarea>
          </Form.Group>
        </div>
      </SweetAlert>
    );
  };

  function sendVad() {
    if (saveTableCalled.current) return;
    saveTableCalled.current = true;

    var trimestre1 = ["01", "02", "03", "04"];
    var trimestre2 = ["05", "06", "07", "08"];
    var trimestre3 = ["09", "10", "11", "12"];

    var date = new Date();
    var mois = String(date.getMonth() + 1).padStart(2, "0");

    if (trimestre1.includes(mois.toString())) {
      var trimestre = 1;
    }

    if (trimestre2.includes(mois.toString())) {
      var trimestre = 2;
    }
    if (trimestre3.includes(mois.toString())) {
      var trimestre = 3;
    }
    var note = localStorage.getItem("commentaire");
    var somme = ((nbVisits / max) * 5).toFixed(2);
    if (note !== "" && insertDate !== "") {
      setTestBtn(true);
      dispatch(
        evaluationVadSended({
          data,
          id,
          idRep,
          idRepp,
          idUser,
          commentaire,
          max,
          somme,
          note,
          trimestre,
          insertDate,
        })
      );
      if (isNaN(location.id) === true) notify(1, "Envoie avec succès");
      else notify(1, "Envoie avec succès");
      setTimeout(async () => {
        saveTableCalled.current = false;
        navigate.goBack();
      }, 1500);
    } else {
      saveTableCalled.current = false;
      notify(2, "Écrire une note");
    }
  }
  const scrollTable = {
    overflowX: "auto",
  };

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (isNaN(location.id) === false) {
          var vadd = await dispatch(evaluationVadGetById(location.id));
          setCommentaire(vadd.payload.evaluationn.commentaire);
          var lignes = vadd.payload.lignes;
          setUser(vadd.payload.evaluationn.reps);
          setIdRepp(vadd.payload.evaluationn.reps.id);
          setId(location.id);
          setMax(vadd.payload.evaluationn.max_nbvisit);

          var nbs = 0;
          vadd.payload.vadsAarray.forEach((entry) => {
            entry.lignes.forEach((ligne) => {
              if (ligne.checks[0] !== "") nbs = nbs + ligne.checks.length;
            });
          });
          setNbVisits(nbs);

          resolve(lignes);
          setData(vadd.payload.vadsAarray);
          setInsertDate(vadd.payload.evaluationn.createdAt.split("T")[0]);
        } else {
          getVad(idRep);
          resolve(0);
        }
      }, 0);
    });
    promise.then((value) => {});
  }, [location.id, dispatch]);

  return (
    <>
      {!testBtn ? alert : ""}
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <>
              <Card>
                <Card>
                  <Card.Header className="background-white">
                    <Col md="12">
                      <Button
                        id="returnBtn"
                        className=""
                        type="button"
                        variant="info"
                        onClick={(event) => {
                          navigate.goBack();
                        }}
                        style={{ float: "right" }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-list"></i>
                        </span>
                        Retour à la liste
                      </Button>
                    </Col>
                    <br clear="all"></br>
                    <Card.Title className="background-white" as="h3">
                      <strong> Rep : {user.nom}</strong>
                    </Card.Title>
                  </Card.Header>
                  {/* <Row>
                    
                  </Row> */}
                </Card>

                <div className="div-date">
                  <Col md="6">
                    <Form.Group>
                      <label>Date d'inserstion * </label>
                      <Form.Control
                        value={insertDate}
                        type="date"
                        onChange={(value) => {
                          setInsertDate(value.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </div>
                <br></br>
                <Col md="18">
                  <table className="table-hover table table-prod">
                    <thead>
                      <tr>
                        <th style={{ width: "15%" }}>
                          NOMBRE DE VISITES OBSERVÉES
                        </th>
                        <th style={{ width: "5%" }}>{max}</th>
                        <th>
                          {nbVisits > 0
                            ? ((nbVisits / max) * 5).toFixed(2)
                            : "0"}
                        </th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                  <Card>
                    {data.map((e, key) => {
                      return (
                        <div key={key} style={scrollTable}>
                          <table className="table-hover table table-prod">
                            <thead>
                              <tr>
                                <th>{e.vad.designation}</th>
                                <th>%</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                                <th>7</th>
                                <th>8</th>
                                <th>9</th>
                                <th>10</th>
                                <th>11</th>
                                <th>12</th>
                                <th>13</th>
                                <th>14</th>
                                <th>15</th>
                                <th>TOTAL</th>
                              </tr>
                            </thead>
                            <tbody>
                              {e.lignes.map((v, rowIndex) => {
                                var checkTab = v.checks.map(Number);

                                return (
                                  <tr key={rowIndex}>
                                    <td>{v.designation}</td>
                                    <td>
                                      {v.pourcentage_visit === undefined
                                        ? 0 + "" + "%"
                                        : v.pourcentage_visit + "" + "%"}
                                    </td>
                                    {[...Array(15).keys()].map((e, k) => (
                                      <td key={k}>
                                        <Form.Check className="form-check-box">
                                          <Form.Check.Label>
                                            <Form.Check.Input
                                              checked={checkTab.includes(k + 1)}
                                              defaultValue="1"
                                              type="checkbox"
                                              onClick={(event) => {
                                                handleCheckBox(
                                                  key,
                                                  rowIndex,
                                                  k,
                                                  event.target.checked
                                                );
                                              }}
                                            ></Form.Check.Input>
                                            <span className="form-check-sign"></span>{" "}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      </td>
                                    ))}
                                    <td>
                                      {v.total_visit == undefined ||
                                      v.total_visit == ""
                                        ? 0
                                        : v.total_visit}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                    {}
                    <table className="table-hover table table-prod">
                      <thead>
                        <tr>
                          <th colSpan="14">Commentaire</th>
                          <th>{nbVisits}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={"20"}>
                            <Form.Group>
                              <textarea
                                value={commentaire}
                                onChange={(value) => {
                                  setCommentaire(value.target.value);
                                }}
                                className="form-control text-area"
                                rows="5"
                              ></textarea>
                            </Form.Group>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                  <br clear="all"></br>
                  {!testBtn ? (
                    <Button
                      className="btn-fill pull-right"
                      type="button"
                      variant="info"
                      onClick={() => submitForm(data)}
                    >
                      Enregistrer
                    </Button>
                  ) : (
                    ""
                  )}
                  <br clear="all"></br> <br clear="all"></br>
                  <Button
                    className="btn-fill pull-right send-tn"
                    type="button"
                    variant="success"
                    onClick={() => confirmMessage()}
                  >
                    Envoyer
                  </Button>
                </Col>
              </Card>
            </>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default Vad;
