import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import MaterialReactTable from "material-react-table";
import { useHistory } from "react-router";
import {
  fetchPersonnel,
  personnelChangeEtat,
} from "../../../Redux/personnelReduce";
import * as XLSX from "xlsx";

// core components
function ListPersonnel() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [entities, setEntities] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const columns = useMemo(
    () => [
      //column definitions...
      {
        header: "Nom et prénom",
        accessorKey: "nomPrenom",
      },
      {
        header: "Adresse",
        accessorKey: "adresse",
      },
      {
        header: "Code",
        accessorKey: "code",
      },

      {
        accessorKey: "id",
        header: "Actions",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                navigate.push("/personnel/update/" + cell.row.original.id);
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-edit" />
            </Button>
            <Button
              onClick={(event) => {
                changeEtat(cell.row.original.id, cell.row.original.etat);
              }}
              variant="danger"
              size="sm"
              className={
                cell.row.original.etat === 1
                  ? "text-success btn-link delete"
                  : "text-danger btn-link delete"
              }
            >
              <i
                className={
                  cell.row.original.etat === 1 ? "fa fa-check" : "fa fa-times"
                }
              />
            </Button>
          </div>
        ),
      },
      //end
    ],
    []
  );
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  function changeEtat(id, e) {
    dispatch(personnelChangeEtat(id)).then((e1) => {
      getPersonnel();
      switch (e) {
        case 0:
          notify(1, "Activation avec succès");
          break;
        case 1:
          notify(1, "Désactivation avec succès");

          break;
        default:
          break;
      }
    });
  }

  function ajouter() {
    navigate.push("ajouterPersonnel");
  }

  const getPersonnel = useCallback(async () => {
    var pers = await dispatch(fetchPersonnel());
    var res = await pers.payload;
    setEntities(res);
    setLoader(false);
  }, [dispatch]);

  useEffect(() => {
    getPersonnel();
  }, []);

  const exportToExcel = (data) => {
    const dataArray = data.map((item) => [
      item.nomPrenom,
      item.adressse == null ? "" : item.adressse,
      item.specialites !== null ? item.specialites.nom : "",
      item.gouvernorats !== null ? item.gouvernorats.nom : "",
      item.bricks !== null ? item.bricks.nom : "",
      item.typepersonnels !== null ? item.typepersonnels.nom : "",
      item.categories !== null ? item.categories.nom : "",
      item.code,
    ]);

    const ws = XLSX.utils.aoa_to_sheet([
      [
        "Personnel",
        "Adresse",
        "Spécialité",
        "Gouvernorat",
        "Brick",
        "Type",
        "Catégorie",
        "Code",
      ],
      ...dataArray,
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "personnel.xlsx");
  };

  function ListTable({ list }) {
    return (
      <div>
        <MaterialReactTable
          columns={columns}
          data={list}
          enableColumnActions={true}
          enableColumnFilters={true}
          enablePagination={true}
          enableSorting={true}
          enableBottomToolbar={true}
          enableTopToolbar={true}
          muiTableBodyRowProps={{ hover: false }}
          state={{ isLoading: loader }}
        />
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col md="12">
            <Button
              id="saveAdd"
              className="btn-wd  mr-1 float-left"
              type="button"
              variant="info"
              onClick={ajouter}
            >
              <span className="btn-label">
                <i className="fas fa-plus"></i>
              </span>
              Ajouter un personnel
            </Button>
            {entities.length > 0 ? (
              <Col md="12" className="pdfExcel">
                <span>
                  <Button
                    variant="success"
                    style={{ float: "right" }}
                    onClick={() => exportToExcel(entities)}
                  >
                    Exporter<i className="fas fa-file-excel"></i>
                  </Button>
                </span>
              </Col>
            ) : (
              ""
            )}
          </Col>

          <Col md="12">
            <h4 className="title">Liste des personnels</h4>
            <Card className="card-header">
              <Card.Body>
                <ListTable list={entities}></ListTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListPersonnel;
