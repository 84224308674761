import { createAsyncThunk } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");


  export const fetchTypepersonnel = createAsyncThunk("typepersonnel/fetchTypepersonnel", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "typepersonnel/allTypepersonnel", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action),
    });
    const type = await response.json();
    return type;
  });