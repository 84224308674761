import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import MaterialReactTable from "material-react-table";
import { useHistory } from "react-router";
import {
  consentementChangeEtat,
  consentementGetById,
  deleteConsentement,
  fetchConsentement,
} from "../../../Redux/consentementReduce";
import { jsPDF } from "jspdf";
import myImage from "../../../assets/img/opalia.png";
import SweetAlert from "react-bootstrap-sweetalert";
import * as XLSX from "xlsx";

// core components
function SuiviConsentement() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [entities, setEntities] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [entitiesCons, setEntitiesCons] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [loader, setLoader] = React.useState(true);

  const anneeObj = JSON.parse(localStorage.getItem("annee"));
  var annee = anneeObj.label;

  const columns = useMemo(
    () => [
      //column definitions...
      {
        header: "Utilisateur",
        accessorKey: "users.nom",
        Cell: ({ cell }) => cell.row.original.users.nom,
      },

      {
        header: "Personnel",
        accessorKey: "personnels.nomPrenom",
        Cell: ({ cell }) => cell.row.original.personnels.nomPrenom,
      },
      {
        header: "Spécialité",
        accessorKey: "personnels.specialites.nom",
        Cell: ({ cell }) => cell.row.original.personnels.specialites.nom,
      },
      {
        header: "Gouvernorat",
        accessorKey: "personnels.gouvernorats.nom",
        Cell: ({ cell }) => cell.row.original.personnels.gouvernorats.nom,
      },

      {
        header: "PDF",
        accessorKey: "pdf",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                getPdf(cell.row.original.id);
              }}
              variant="warning"
              size="sm"
            >
              <i className="fa fa-file" />
            </Button>
          </div>
        ),
      },
      {
        accessorKey: "id",
        header: "Actions",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={(ev) => {
                confirmMessage(cell.row.original.id);
              }}
              variant="danger"
              size="sm"
              className="text-danger btn-link delete"
            >
              <i className="fa fa-trash" />
            </Button>
            <Button
              onClick={(event) => {
                changeEtat(cell.row.original.id, cell.row.original.etat);
              }}
              variant="danger"
              size="sm"
              className={
                cell.row.original.etat === 1
                  ? "text-success btn-link delete"
                  : "text-danger btn-link delete"
              }
            >
              <i
                className={
                  cell.row.original.etat === 1 ? "fa fa-check" : "fa fa-times"
                }
              />
            </Button>
          </div>
        ),
      },
      //end
    ],
    []
  );

  const columnsCons = useMemo(
    () => [
      //column definitions...

      {
        header: "Utilisateur",
        accessorKey: "nom",
      },
      {
        header: "Année",
        accessorKey: "date",
      },
      {
        header: "Total consentements",
        accessorKey: "nb",
      },

      //end
    ],
    []
  );
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  function changeEtat(id, e) {
    dispatch(consentementChangeEtat(id)).then((e1) => {
      getConsentement();
      switch (e) {
        case 0:
          notify(1, "Activation avec succès");
          break;
        case 1:
          notify(1, "Désactivation avec succès");

          break;
        default:
          break;
      }
    });
  }

  const confirmMessage = (id) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={"Êtes-vous sûr de supprimer ce consentement ?"}
        onConfirm={() => {
          deleteCons(id);
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      ></SweetAlert>
    );
  };

  function deleteCons(id) {
    dispatch(deleteConsentement(id));
    notify(1, "Consentement supprimée avec succès");
    hideAlert();
    setTimeout(async () => {
      window.location.reload();
    }, 1500);
  }
  const hideAlert = () => {
    setAlert(null);
  };

  function getPdf(id) {
    dispatch(consentementGetById(id)).then((res) => {
      var consentement = res.payload;
      downloadPDF(consentement);
    });
  }

  const downloadPDF = (consentement) => {
    const pdf = new jsPDF();

    pdf.addImage(myImage, "PNG", 1, 1, 45, 15);

    // Page 1 Content
    pdf.setTextColor(255, 0, 0);
    pdf.setFontSize(18);
    pdf.setFont("Helvetica", "bold");
    pdf.setFontSize(12);
    const x = pdf.internal.pageSize.width / 2;
    const y = 25;
    pdf.text("CONSENTEMENT", x, y, "center");
    pdf.setFont("Helvetica", "normal");
    pdf.setTextColor(0, 0, 0);

    const consentText = `
    Dans le cadre de l’amélioration continue de sa communication avec ses partenaires, la société OPALIA
    PHARMA SA sise à Zone industrielle Kalaat al Andalous, Ariana 2022, vous sollicite afin d’obtenir votre
    CONSENTEMENT pour nous permettre de rester en contact avec vous à travers le courrier électronique 
    (e-mail), le service de messagerie SMS et tout autre canal digital, dans le but de vous offrir nos services
    et partager avec vous les dernières actualités scientifiques en rapport avec nos produits et les pathologies
    qui vous intéressent, et ce, pour une période de 6 mois renouvelable.
    On vous spécifie que vous avez le droit de revenir, à tout moment, sur l'acceptation ou le refus de
    l’utilisation de vos données mentionnées ci-dessus.
    L’utilisation de vos données se fait à travers un traitement automatisé avec un niveau de sécurité
    informatique élevé.
    La société s’engage à traiter les dites données conformément à la législation en vigueur (la loi
    organique n° 63 du 24 Juillet 2004) relative à la protection des données personnelles.
    `;

    pdf.text(consentText, 2, 30);

    if (consentement.accept === 0) {
      pdf.setFillColor(255, 0, 0);
      pdf.rect(10, 120, 5, 5, "F");
      pdf.text("Refusé", 25, 125);
    } else {
      pdf.rect(10, 120, 5, 5);
      pdf.text("Refusé", 25, 125);
    }

    if (consentement.accept === 1) {
      pdf.setFillColor(255, 0, 0);
      pdf.rect(10, 110, 5, 5, "F");
      pdf.text("Accepté", 25, 115);
    } else {
      pdf.rect(10, 110, 5, 5);
      pdf.text("Accepté", 25, 115);
    }

    pdf.setTextColor(255, 0, 0);
    pdf.setFontSize(15);
    pdf.setFont("Helvetica", "bold");
    pdf.text("Signature :", 10, 140);

    const signatureImage = consentement.signature;
    pdf.addImage(signatureImage, "PNG", 30, 145, 120, 100);

    // Page 2 Content
    pdf.addPage();

    pdf.addImage(myImage, "PNG", 1, 1, 45, 15);

    pdf.setTextColor(255, 0, 0);
    pdf.setFont("Helvetica", "bold");
    pdf.text("Nom et prénom :", 10, 25);
    pdf.setTextColor(0, 0, 0);
    pdf.setFont("Helvetica", "normal");
    pdf.text(consentement.personnels.nomPrenom, 55, 25);

    pdf.setTextColor(255, 0, 0);
    pdf.setFont("Helvetica", "bold");
    pdf.text("E-mail :  ", 10, 35);
    pdf.setTextColor(0, 0, 0);
    pdf.setFont("Helvetica", "normal");
    pdf.text(consentement.email, 48, 35);

    pdf.setTextColor(255, 0, 0);
    pdf.setFont("Helvetica", "bold");
    pdf.text("Téléphone :", 10, 45);
    pdf.setTextColor(0, 0, 0);
    pdf.setFont("Helvetica", "normal");
    pdf.text(consentement.tel, 48, 45);

    pdf.setTextColor(255, 0, 0);
    pdf.setFont("Helvetica", "bold");
    pdf.text("Code oneKey :", 10, 55);
    pdf.setTextColor(0, 0, 0);
    pdf.setFont("Helvetica", "normal");
    pdf.text(consentement.code, 48, 55);

    pdf.setTextColor(255, 0, 0);
    pdf.setFont("Helvetica", "bold");
    pdf.text("Commentaire :", 10, 65);
    pdf.setTextColor(0, 0, 0);
    pdf.setFont("Helvetica", "normal");
    pdf.text(consentement.commentaire, 48, 65);

    var filename = `consentement_${consentement.createdAt}_${consentement.code}.pdf`;

    pdf.save(filename);
  };

  function ajouter() {
    navigate.push("ajouterConsentement");
  }

  const getConsentement = useCallback(async () => {
    var consArray = [];
    var cons = await dispatch(fetchConsentement({ annee }));
    var res = await cons.payload.consentements;
    setEntities(res);
    res.forEach((element) => {
      consArray.push({
        user: element.users.nom,
        personnel: element.personnels.nomPrenom,
        specialite: element.personnels.specialites.nom,
        code: element.code,
        etat: element.accept == 1 ? "Accepté" : "Refusé",
        email: element.email,
        tel: element.tel,
        comment: element.commentaire,
        date: element.createdAt,
      });
    });
    setData(consArray);
    var result = await cons.payload.consentementArray;
    setEntitiesCons(result);
    setLoader(false);
  }, [dispatch]);

  const exportToExcel = (data) => {
    const dataArray = data.map((item) => [
      item.user,
      item.personnel,
      item.specialite,
      item.code,
      item.etat,
      item.email,
      item.tel,
      item.comment,
      item.date,
    ]);

    const ws = XLSX.utils.aoa_to_sheet([
      [
        "Nom Utilisateur",
        "Nom Personnel",
        "Spécialité",
        "Code OneKey",
        "Etat Consentement",
        "E-mail",
        "Téléphone",
        "Commentaire",
        "Date Insertion",
      ],
      ...dataArray,
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "consentement.xlsx");
  };

  useEffect(() => {
    getConsentement();
  }, []);

  function ListTable({ list }) {
    return (
      <MaterialReactTable
        columns={columns}
        data={list}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }

  function ListTable2({ listCons }) {
    return (
      <MaterialReactTable
        columns={columnsCons}
        data={listCons}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }

  return (
    <>
      {alert}

      <Container fluid>
        <ToastContainer />
        <Row>
          <Col md="12">
            <Button
              id="saveAdd"
              className="btn-wd  mr-1 float-left"
              type="button"
              variant="info"
              onClick={ajouter}
            >
              <span className="btn-label">
                <i className="fas fa-plus"></i>
              </span>
              Ajouter un consentement
            </Button>

            {data.length > 0 ? (
              <Col md="12" className="pdfExcel">
                <span>
                  <Button
                    variant="success"
                    style={{ float: "right" }}
                    onClick={() => exportToExcel(data)}
                  >
                    Exporter<i className="fas fa-file-excel"></i>
                  </Button>
                </span>
              </Col>
            ) : (
              ""
            )}
            <Col md="12" className="hidden"></Col>
          </Col>

          <Col md="12">
            <h4 className="title">Liste des consentements</h4>
            <Card className="card-header">
              <Card.Body>
                <ListTable list={entities}></ListTable>
              </Card.Body>
            </Card>
            <h4 className="title">Suivi consentements</h4>

            <Card className="card-header">
              <Card.Body>
                <ListTable2 listCons={entitiesCons}></ListTable2>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SuiviConsentement;
