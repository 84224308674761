import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { userGetById } from "../../../Redux/usersReduce";

// core components
function CoachingFlm({ obj }) {
  var date = new Date();
  var jour = date.getDate();
  var mois = date.getMonth() + 1;
  var anneee = date.getFullYear();
  var today = anneee + "-" + mois + "-" + jour;

  const location = useParams();
  var navigate = useHistory();
  const dispatch = useDispatch();

  const [user, setUser] = React.useState("");

  if (Object.keys(location).length === 0) {
    var id = obj.user.id;
  } else {
    var id = location.id;
  }

  const getUser = React.useCallback(async () => {
    var userr = await dispatch(userGetById(id));
    setUser(userr.payload.header.nom);
  }, [dispatch]);

  React.useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <Row>
          <Card>
            <Card.Header className="background-white">
              <Col md="12">
                <Button
                  id="returnBtn"
                  className=""
                  type="button"
                  variant="info"
                  onClick={(event) => {
                    navigate.goBack();
                  }}
                  style={{ float: "right" }}
                >
                  <span className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
              <Card.Title className="background-white" as="h3">
                <strong className="strong-user"> FLM : {user}</strong>
              </Card.Title>
            </Card.Header>
            <Card.Header className="background-white">
              <Card.Title className="background-white" as="h3">
                <strong>{today}</strong>
              </Card.Title>
            </Card.Header>
          </Card>
          <Card className="card-header">
            <Card.Body>
              <Row className="row-coaching">
                <Col md="6">
                  <Button
                    className="btn btn-dark"
                    type="button"
                    variant="dark"
                    onClick={() => {
                      navigate.push("/utilisateur/listPlanCoachingFlm/" + id);
                      localStorage.setItem("path", "/coachingFlm");
                    }}
                  >
                    Plan de coaching
                  </Button>
                </Col>
                <Col md="6">
                  <Button
                    className="btn-fill font-size"
                    type="button"
                    variant="danger"
                    onClick={() => {
                      navigate.push("/utilisateur/listCoachDuCoach/" + id);
                      localStorage.setItem("path", "/coachingFlm");
                    }}
                  >
                    Coach du coach
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </>
  );
}

export default CoachingFlm;
