import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchConsentement = createAsyncThunk("consentement/fetchConsentement", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "consentement/allConsentement", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),
  });
  const consentement = await response.json();
  return consentement;
});

export const getConsentementByIdUser = createAsyncThunk("consentement/getConsentementByIdUser", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "consentement/getConsentementByUser", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),

  });
  const consentement = await response.json();
  return consentement;
});

export const consentementAdded = createAsyncThunk("consentement/addConsentement", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "consentement/addConsentement", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const consentement = await response.json();
    return consentement;
  });

  export const consentementGetById = createAsyncThunk("consentement/personnelGetById", async (id1) => {
    const  id  = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "consentement/getConsentement", {
      method: 'POST',
      headers: {
        'id':id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const consentement = await response.json();
    return consentement;
  });


  export const consentementChangeEtat = createAsyncThunk("consentement/changeEtat",async (id) => {
      const response = await fetch(
        Configuration.BACK_BASEURL + "consentement/changeEtat/" + id,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      );
      const consentement = await response.json();
      return consentement;
    }
  );

  export const deleteConsentement = createAsyncThunk("consentement/deleteConsentement", async (id) => {
    const response = await fetch(Configuration.BACK_BASEURL + "consentement/deleteConsentement/"+id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const consentement = await response.json();
    return consentement;
  });