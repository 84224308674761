import { Form, Button, Card, Container, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getCoachs } from "../../../Redux/coachReduce";
import {
  coachFlmAdded,
  coachFlmGetById,
  sendCoachFlm,
} from "../../../Redux/coachFlmReduce";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
function CoachDuCoach({ obj }) {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [data, setData] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [score, setScore] = React.useState(0);
  const [alert, setAlert] = React.useState(null);
  const [id, setId] = React.useState(0);
  const [idFlmm, setIdFlmm] = React.useState(0);
  const [testBtn, setTestBtn] = React.useState(false);
  const saveTableCalled = useRef(false);

  var idFlm = location.idFlm;
  var idUser = obj.user.id;

  const dayyy = new Date();

  const jour = dayyy.getDate();
  const mois = dayyy.getMonth() + 1;
  const annee = dayyy.getFullYear();

  const insertDate = `${mois}/${jour}/${annee}`;

  const getCoach = useCallback(async () => {
    var data = await dispatch(getCoachs({ idFlm }));
    setData(data.payload.coachss);
    setUser(data.payload.userr);
  }, [dispatch]);

  const handleRadioClick = (key, k) => {
    var newData = JSON.parse(JSON.stringify(data));
    newData[key].lignes.forEach((ligne) => {
      ligne.etat = 0;
    });
    newData[key].lignes[k].etat = 1;

    var sommeScore = 0;
    var sommePoids = 0;
    newData.forEach((element) => {
      var poids = element.poids;
      sommePoids += poids;
      var findResponse = element.lignes.find((ligne) => ligne.etat === 1);
      if (findResponse) {
        var attribut = findResponse.attribut == 1 ? 1 : 0;
        var scoree = poids * attribut;
        sommeScore = sommeScore + scoree;
      }
    });

    setScore((sommeScore / sommePoids) * 100);
    setData(newData);
  };

  function submitForm() {
    if (saveTableCalled.current) return;
    saveTableCalled.current = true;

    var trimestre1 = ["01", "02", "03", "04"];
    var trimestre2 = ["05", "06", "07", "08"];
    var trimestre3 = ["09", "10", "11", "12"];

    var date = new Date();
    var mois = String(date.getMonth() + 1).padStart(2, "0");

    if (trimestre1.includes(mois.toString())) {
      var trimestre = 1;
    }

    if (trimestre2.includes(mois.toString())) {
      var trimestre = 2;
    }
    if (trimestre3.includes(mois.toString())) {
      var trimestre = 3;
    }

    var arrayLignes = [];
    data.forEach((element) => {
      var ligneObj = element.lignes.find((ligne) => ligne.etat === 1);
      if (ligneObj) {
        arrayLignes.push(1);
      } else {
        arrayLignes.push(0);
      }
    });

    if (arrayLignes.includes(0) === false) {
      setTestBtn(true);
      dispatch(
        coachFlmAdded({ data, id, idUser, idFlm, trimestre, score, mois })
      );
      if (isNaN(location.id) === true) notify(1, "Insertion avec succès");
      else notify(1, "Modifié avec succès");
      setTimeout(async () => {
        saveTableCalled.current = false;
        navigate.goBack();
      }, 1500);
    } else {
      saveTableCalled.current = false;
      notify(2, "Vérifier vos données");
    }
  }

  function sendCoach() {
    if (saveTableCalled.current) return;
    saveTableCalled.current = true;

    var note = localStorage.getItem("commentaire");
    var trimestre1 = ["01", "02", "03", "04"];
    var trimestre2 = ["05", "06", "07", "08"];
    var trimestre3 = ["09", "10", "11", "12"];

    var date = new Date();
    var mois = String(date.getMonth() + 1).padStart(2, "0");

    if (trimestre1.includes(mois.toString())) {
      var trimestre = 1;
    }

    if (trimestre2.includes(mois.toString())) {
      var trimestre = 2;
    }
    if (trimestre3.includes(mois.toString())) {
      var trimestre = 3;
    }

    var arrayLignes = [];
    data.forEach((element) => {
      var ligneObj = element.lignes.find((ligne) => ligne.etat === 1);
      if (ligneObj) {
        arrayLignes.push(1);
      } else {
        arrayLignes.push(0);
      }
    });

    if (arrayLignes.includes(0) === false) {
      if (note !== "") {
        setTestBtn(true);
        dispatch(
          sendCoachFlm({
            data,
            id,
            idUser,
            idFlm,
            trimestre,
            score,
            note,
            mois,
            idFlmm,
          })
        );
        notify(1, "Envoie avec succès");
        setTimeout(async () => {
          saveTableCalled.current = false;
          navigate.goBack();
        }, 1500);
      } else {
        saveTableCalled.current = false;
        notify(2, "Écrire une note");
      }
    } else {
      saveTableCalled.current = false;
      notify(2, "Vérifier vos données");
    }
  }

  const confirmMessage = () => {
    localStorage.setItem("commentaire", "");
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={"Êtes-vous sûr d'envoyer ce coach du coach "}
        onConfirm={() => sendCoach()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
        <div>
          <Form.Group className="input-comment">
            <label>Note</label>
            <textarea
              className="form-control"
              onChange={(value) => {
                localStorage.setItem("commentaire", value.target.value);
              }}
              rows="5"
            ></textarea>
          </Form.Group>
        </div>
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (isNaN(location.id) === false) {
          var evaluation = await dispatch(coachFlmGetById(location.id));
          setData(evaluation.payload.coachArray);
          setUser(evaluation.payload.userr);
          setIdFlmm(evaluation.payload.userr.id);
          setScore(evaluation.payload.coachFlm.score);
          setId(location.id);
        } else {
          resolve(0);
          getCoach();
        }
      }, 0);
    });
    promise.then((value) => {});
  }, [location.id, dispatch]);

  return (
    <>
      {!testBtn ? alert : ""}
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <>
              <Card>
                <Card>
                  <Card.Header className="background-white">
                    <Col md="12">
                      <Button
                        id="returnBtn"
                        className=""
                        type="button"
                        variant="info"
                        onClick={(event) => {
                          navigate.goBack();
                        }}
                        style={{ float: "right" }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-list"></i>
                        </span>
                        Retour à la liste
                      </Button>
                    </Col>
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong> FLM : {user.nom}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Card>
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong>{insertDate}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Col md="18">
                  {data.map((val, key) => {
                    return (
                      <Col md="12">
                        <Card>
                          <Card.Header>
                            <Card.Title as="h4">{val.designation}</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            {val.lignes.map((v, k) => {
                              var attribut = v.attribut == 1 ? "1" : "0";
                              var designation = attribut + "-" + v.designation;
                              return (
                                <div key={k}>
                                  <Form.Check className="form-check-radio">
                                    <Form.Check.Label>
                                      <Form.Check.Input
                                        checked={
                                          parseInt(v.etat) === 1 ? true : false
                                        }
                                        defaultValue="1"
                                        type="radio"
                                        onClick={() => handleRadioClick(key, k)}
                                      ></Form.Check.Input>
                                      <span className="form-check-sign"></span>
                                      {designation}
                                    </Form.Check.Label>
                                  </Form.Check>
                                </div>
                              );
                            })}
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong> SCORE : {score + " " + "%"}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                  <br clear="all"></br>
                  {!testBtn && (
                    <Button
                      className="btn-fill pull-right"
                      type="button"
                      variant="info"
                      onClick={submitForm}
                    >
                      Enregistrer
                    </Button>
                  )}
                  <br clear="all"></br> <br clear="all"></br>
                  <Button
                    className="btn-fill pull-right send-tn"
                    type="button"
                    variant="success"
                    onClick={() => confirmMessage()}
                  >
                    Envoyer
                  </Button>
                </Col>
              </Card>
            </>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default CoachDuCoach;
