import React, { useEffect } from "react";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  lineBusinessAdded,
  lineBusinessGetById,
} from "../../../Redux/lineBusinessReduce";

function AjouterLineBusiness() {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  const [nom, setNom] = React.useState("");
  const [testBtn, setTestBtn] = React.useState(false);
  const [id, setId] = React.useState(0);

  function submitForm() {
    if (nom !== "") {
      setTestBtn(true);
      dispatch(lineBusinessAdded({ nom, id }));
      if (isNaN(location.id) === true) notify(1, "Insertion avec succès");
      else notify(1, "Modifié avec succès");
      setTimeout(async () => {
        listLinebus();
      }, 1500);
    } else {
      notify(2, "Vérifier vos données");
    }
  }

  useEffect(() => {
    async function getLineBusiness() {
      if (isNaN(location.id) === false) {
        var role = await dispatch(lineBusinessGetById(location.id));
        var entities = role.payload;
        setNom(entities.nom);
        setId(location.id);
      }
    }
    getLineBusiness();
  }, [location.id, dispatch]);

  function listLinebus() {
    navigate.push("/listLineBusiness");
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={listLinebus}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        {typeof location.id == "undefined"
                          ? "Ajouter line"
                          : "Modifier line"}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Nom * </label>
                            <Form.Control
                              defaultValue={nom}
                              placeholder="Nom"
                              type="text"
                              onChange={(value) => {
                                setNom(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          id="saveAdd"
                          onClick={submitForm}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default AjouterLineBusiness;
