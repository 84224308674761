import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchPlan = createAsyncThunk("plan/fetchPlan", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "plan/allPlan", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),

  });
  const plan = await response.json();
  return plan;
});

export const fetchAllPlan = createAsyncThunk("plan/fetchAllPlan", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "plan/getAllPlan", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),

  });
  const plan = await response.json();
  return plan;
});

export const getPlanByIdUser = createAsyncThunk("plan/getPlanByIdUser", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "plan/getPlanByUser", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),

  });
  const plan = await response.json();
  return plan;
});


export const planAdded = createAsyncThunk("plan/addPlan", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "plan/addPlan", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const plan = await response.json();
    return plan;
  });

  export const planGetById = createAsyncThunk("plan/planGetById", async (id1) => {
    const  id  = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "plan/getPlan", {
      method: 'POST',
      headers: {
        'id':id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const plan = await response.json();
    return plan;
  });

  export const deleteplan = createAsyncThunk("plan/deleteplan", async (id) => {
    const response = await fetch(Configuration.BACK_BASEURL + "plan/deletePlan/"+id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const plan = await response.json();
    return plan;
  });