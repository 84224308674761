import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import {
  fetchUsers,
  fetchUsersByLine,
  userChangeEtat,
} from "../../../Redux/usersReduce";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import { toast, ToastContainer } from "react-toastify";

// core components
function ListUser({ obj }) {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const navigate = useHistory();
  var idRole = obj.user.id_role;
  var idUser = obj.user.id;
  const dispatch = useDispatch();
  const [entities, setEntities] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [columns] = React.useState([
    //column definitions...
    {
      header: "Nom et prénom",
      accessorKey: "users.nom",
      Cell: ({ cell }) => cell.row.original.users.nom,
    },

    {
      header: "Login",
      accessorKey: "users.login",
      Cell: ({ cell }) => cell.row.original.users.login,
    },
    {
      header: "Rôle ",
      accessorKey: "users.roles.nom",
      Cell: ({ cell }) => cell.row.original.users.roles.nom,
    },
    // {
    //   header: "E-mail",
    //   accessorKey: "email",
    // },
    {
      header: "Lines",
      accessorKey: "lignes",
    },
    // {
    //   header: "Line business",
    //   accessorKey: "linesBusiness",
    // },

    {
      header: "Coaching",
      accessorKey: "coaching",
      Cell: ({ cell }) => (
        <div className="actions-right block_action">
          {cell.row.original.id_role == 3 ? (
            <Button
              onClick={() => {
                navigate.push(
                  "/utilisateur/coaching/" + cell.row.original.users.id
                );
                localStorage.setItem("path", "/utilisateurListe");
              }}
              className="btn-coach"
              size="sm"
              type="button"
            >
              <i className="fas fa-plus"></i>{" "}
            </Button>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      header: "IMS",
      accessorKey: "ims",
      Cell: ({ cell }) => (
        <div className="actions-right block_action">
          {cell.row.original.id_role == 3 ? (
            <Button
              onClick={() => {
                navigate.push("/utilisateur/ims/" + cell.row.original.users.id);
                localStorage.setItem("path", "/utilisateurListe");
              }}
              variant="success"
              size="sm"
              type="button"
            >
              <i className="fas fa-eye"></i>{" "}
            </Button>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      accessorKey: "users.id",
      header: "Actions",
      Cell: ({ cell, row }) => (
        <div className="actions-right block_action">
          {idRole === 1 ? (
            <Button
              onClick={() => {
                navigate.push(
                  "/utilisateur/update/" + cell.row.original.users.id
                );
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-edit" />
            </Button>
          ) : (
            ""
          )}
          {idRole === 1 ? (
            <Button
              onClick={(event) => {
                changeEtat(
                  cell.row.original.users.id,
                  cell.row.original.users.etat
                );
              }}
              variant="danger"
              size="sm"
              className={
                cell.row.original.users.etat === 1
                  ? "text-success btn-link delete"
                  : "text-danger btn-link delete"
              }
            >
              <i
                className={
                  cell.row.original.users.etat === 1
                    ? "fa fa-check"
                    : "fa fa-times"
                }
              />
            </Button>
          ) : (
            ""
          )}
        </div>
      ),
    },
    //end
  ]);
  const [columnsFfm] = React.useState([
    //column definitions...
    {
      header: "Nom et prénom",
      accessorKey: "nom",
      Cell: ({ cell }) => cell.row.original.nom,
    },

    {
      header: "Login",
      accessorKey: "login",
      Cell: ({ cell }) => cell.row.original.login,
    },
    {
      header: "Rôle ",
      accessorKey: "roles.nom",
      Cell: ({ cell }) => cell.row.original.roles.nom,
    },

    {
      header: "Lines",
      accessorKey: "lineBusines",
      Cell: ({ cell }) =>
        cell.row.original.lineBusines !== null
          ? cell.row.original.lineBusines
          : "",
    },
    {
      header: "Lines IMS",
      accessorKey: "lines",
      Cell: ({ cell }) =>
        cell.row.original.lines !== null ? cell.row.original.lines : "",
    },

    {
      header: "Coaching",
      accessorKey: "coaching",
      Cell: ({ cell }) => (
        <div className="actions-right block_action">
          {cell.row.original.id_role == 3 || cell.row.original.id_role == 2 ? (
            <Button
              onClick={() => {
                if (cell.row.original.id_role === 3) {
                  navigate.push(
                    "/utilisateur/coaching/" + cell.row.original.id
                  );
                } else {
                  navigate.push(
                    "/utilisateur/coachingFlm/" + cell.row.original.id
                  );
                }
                localStorage.setItem("path", "/utilisateurListe");
              }}
              className={
                cell.row.original.id_role == 3 ? `btn-coach` : `btn-coachFlm`
              }
              size="sm"
              type="button"
            >
              <i className="fas fa-plus"></i>{" "}
            </Button>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      header: "IMS",
      accessorKey: "ims",
      Cell: ({ cell }) => (
        <div className="actions-right block_action">
          {cell.row.original.id_role == 3 ? (
            <Button
              onClick={() => {
                navigate.push("/utilisateur/ims/" + cell.row.original.id);
                localStorage.setItem("path", "/utilisateurListe");
              }}
              variant="success"
              size="sm"
              type="button"
            >
              <i className="fas fa-eye"></i>{" "}
            </Button>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      accessorKey: "id",
      header: "Actions",
      Cell: ({ cell, row }) => (
        <div className="actions-right block_action">
          {idRole === 1 ? (
            <Button
              onClick={() => {
                navigate.push("/utilisateur/update/" + cell.row.original.id);
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-edit" />
            </Button>
          ) : (
            ""
          )}
          {idRole === 1 ? (
            <Button
              onClick={(event) => {
                changeEtat(cell.row.original.id, cell.row.original.etat);
              }}
              variant="danger"
              size="sm"
              className={
                cell.row.original.etat === 1
                  ? "text-success btn-link delete"
                  : "text-danger btn-link delete"
              }
            >
              <i
                className={
                  cell.row.original.etat === 1 ? "fa fa-check" : "fa fa-times"
                }
              />
            </Button>
          ) : (
            ""
          )}
        </div>
      ),
    },
    //end
  ]);
  function ajouter() {
    navigate.push("/ajouterUtilisateur");
  }

  const getUser = useCallback(async () => {
    var array = [];
    if (idRole === 2 || idRole === 4) {
      var response = await dispatch(fetchUsersByLine({ idUser, idRole }));
    } else {
      var response = await dispatch(fetchUsers());
    }
    response.payload.users.forEach((element) => {
      array.push(element);
    });
    setEntities(array);
    setLoader(false);
  }, [dispatch]);

  function changeEtat(id, e) {
    dispatch(userChangeEtat(id)).then((e1) => {
      getUser();
      switch (e) {
        case 0:
          notify(1, "Activation avec succès");
          break;
        case 1:
          notify(1, "Désactivation avec succès");
          break;
        default:
          break;
      }
    });
  }

  useEffect(() => {
    getUser();
  }, [getUser]);

  function ListTable({ list }) {
    return (
      <MaterialReactTable
        columns={
          idRole == 4 || idRole == 5 || idRole == 1 || idRole == 2
            ? columnsFfm
            : columns
        }
        data={list}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
        // initialState={{ showColumnFilters: true }}
      />
    );
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col md="12">
            {idRole == 1 ? (
              <Button
                id="saveAdd"
                className="btn-wd  mr-1 float-left"
                type="button"
                variant="info"
                onClick={ajouter}
              >
                <span className="btn-label">
                  <i className="fas fa-plus"></i>
                </span>
                Ajouter un utilisateur
              </Button>
            ) : (
              ""
            )}
          </Col>
          <Col md="12">
            <h4 className="title">Liste des utilisateurs</h4>
            <Card className="card-header">
              <Card.Body>
                <ListTable list={entities}></ListTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListUser;
