import { Form, Button, Card, Container, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getPlanCoachings } from "../../../Redux/planCoachingReduce";
import {
  planCoachingFlmAdded,
  planCoachingFlmGetById,
} from "../../../Redux/planCoachingFlmReduce";

// core components
function AjouterPlanCoachingFlm({ obj }) {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [data, setData] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [testBtn, setTestBtn] = React.useState(false);
  const saveTableCalled = useRef(false);
  const [id, setId] = React.useState(0);

  var idFlm = location.idFlm;
  var idUser = obj.user.id;

  const dayyy = new Date();

  const jour = dayyy.getDate();
  const mois = dayyy.getMonth() + 1;
  const annee = dayyy.getFullYear();

  const insertDate = `${mois}/${jour}/${annee}`;

  const getPlanCoaching = useCallback(async () => {
    var data = await dispatch(getPlanCoachings({ idFlm }));
    setData(data.payload.plans);
    setUser(data.payload.userr);
  }, [dispatch]);

  const addNote = (commentaire, key) => {
    var newData = JSON.parse(JSON.stringify(data));
    newData[key].commentaire = commentaire;
    setData(newData);
  };

  function submitForm() {
    if (saveTableCalled.current) return;
    saveTableCalled.current = true;

    var trimestre1 = ["01", "02", "03", "04"];
    var trimestre2 = ["05", "06", "07", "08"];
    var trimestre3 = ["09", "10", "11", "12"];

    var date = new Date();
    var mois = String(date.getMonth() + 1).padStart(2, "0");

    if (trimestre1.includes(mois.toString())) {
      var trimestre = 1;
    }

    if (trimestre2.includes(mois.toString())) {
      var trimestre = 2;
    }
    if (trimestre3.includes(mois.toString())) {
      var trimestre = 3;
    }

    var testComment = true;
    data.forEach((element) => {
      if (element.commentaire === "" || element.commentaire === undefined)
        testComment = false;
    });

    if (testComment) {
      setTestBtn(true);
      dispatch(planCoachingFlmAdded({ data, id, idUser, idFlm, trimestre }));
      if (isNaN(location.id) === true) notify(1, "Insertion avec succès");
      else notify(1, "Modifié avec succès");
      setTimeout(async () => {
        saveTableCalled.current = false;
        navigate.goBack();
      }, 1500);
    } else {
      saveTableCalled.current = false;
      notify(2, "Vérifier vos données");
    }
  }

  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (isNaN(location.id) === false) {
          var planCoaching = await dispatch(
            planCoachingFlmGetById(location.id)
          );
          setData(planCoaching.payload.lignePlans);
          setUser(planCoaching.payload.userr);
          setId(location.id);
        } else {
          resolve(0);
          getPlanCoaching();
        }
      }, 0);
    });
    promise.then((value) => {});
  }, [location.id, dispatch]);

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <>
              <Card>
                <Card>
                  <Card.Header className="background-white">
                    <Col md="12">
                      <Button
                        id="returnBtn"
                        className=""
                        type="button"
                        variant="info"
                        onClick={(event) => {
                          navigate.goBack();
                        }}
                        style={{ float: "right" }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-list"></i>
                        </span>
                        Retour à la liste
                      </Button>
                    </Col>
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong> FLM : {user.nom}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Card>
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong>{insertDate}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Col md="18">
                  {data.map((val, key) => {
                    return (
                      <Col md="12">
                        <Card>
                          <Card.Header>
                            <Card.Title as="h4">
                              {isNaN(location.id) === false
                                ? val.plan_coachings.designation
                                : val.designation}
                            </Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Form.Group className="input-comment">
                              <label>Commentaire</label>
                              <textarea
                                onChange={(value) => {
                                  addNote(value.target.value, key);
                                }}
                                value={val.commentaire}
                                className="form-control text-area"
                                rows="5"
                              ></textarea>
                            </Form.Group>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}

                  {!testBtn ? (
                    <Button
                      className="btn-fill pull-right"
                      type="button"
                      variant="info"
                      onClick={submitForm}
                    >
                      Enregistrer
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Card>
            </>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default AjouterPlanCoachingFlm;
