import { createAsyncThunk } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");


  export const fetchCategorie = createAsyncThunk("categorie/fetchCategorie", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "categorie/allCategorie", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action),
    });
    const categorie = await response.json();
    return categorie;
  });