import React, { useEffect } from "react";
import Select from "react-select";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import { coachAdded, coachGetById } from "../../../Redux/coachReduce";

function AjouterCoach() {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  const [designation, setDesignation] = React.useState("");
  const [poids, setPoids] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [testBtn, setTestBtn] = React.useState(false);

  const [data, setData] = React.useState([]);

  const [optionAttribut] = React.useState([
    {
      value: "",
      label: "Attribut",
      isDisabled: true,
    },
    {
      value: "1",
      label: "Vrai",
    },
    {
      value: "2",
      label: "Faux",
    },
  ]);

  const [id, setId] = React.useState(0);

  function submitForm() {
    if (designation !== "" && poids !== "" && data.length > 0) {
      setTestBtn(true);
      dispatch(coachAdded({ designation, poids, data, id }));
      if (isNaN(location.id) === true) notify(1, "Insertion avec succès");
      else notify(1, "Modifié avec succès");
      setTimeout(async () => {
        listCoach();
      }, 1500);
    } else {
      notify(2, "Vérifier vos données");
    }
  }

  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (isNaN(location.id) === false) {
          var coach = await dispatch(coachGetById(location.id));
          var entities = coach.payload.coachs;
          setDesignation(entities.designation);
          setPoids(entities.poids);
          setData(coach.payload.lignes);
          setId(location.id);
          resolve(entities);
        } else {
          resolve(0);
        }
      }, 0);
    });

    promise.then((value) => {});
  }, [location.id, dispatch]);

  function listCoach() {
    navigate.push("/listCoach");
  }

  function AjoutLigne(event) {
    var list = [];
    if (data.length > 0) list = [...data];
    list[list.length] = {
      id: null,
      kpi: null,
      designation: null,
    };
    setData(list);
  }

  const hideAlert = () => {
    setAlert(null);
  };

  const confirmMessage = (id) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes-vous sûr de supprimer cette ligne?"
        onConfirm={() => deleteLigne(id)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      ></SweetAlert>
    );
  };
  function deleteLigne(id) {
    var list = [...data];
    if (data.length > 1) {
      list.splice(parseInt(id), 1);
      setData(list);
      notify(1, "Ligne supprimée avec succès");
    } else {
      notify(2, "Action impossible à effectuer");
    }
    hideAlert();
  }

  return (
    <>
      {alert}
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={listCoach}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        {typeof location.id == "undefined"
                          ? "Ajouter coach du coach"
                          : "Modifier coach du coach"}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Désignation * </label>
                            <Form.Control
                              value={designation}
                              placeholder="Désignation "
                              type="text"
                              onChange={(value) => {
                                setDesignation(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Poids * </label>
                            <Form.Control
                              value={poids}
                              placeholder="Poids"
                              type="number"
                              onChange={(value) => {
                                setPoids(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="scroll">
                        {" "}
                        <table className="table-hover table table-prod">
                          <thead>
                            <tr>
                              <th>Désignation</th>
                              <th>Attribut</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>
                                    <Form.Group>
                                      <label>Désignation </label>
                                      <Form.Control
                                        value={val.designation}
                                        placeholder="Désignation"
                                        type="text"
                                        onChange={(value) => {
                                          var list = [...data];
                                          list[key] = {
                                            ...list[key],
                                            designation: value.target.value,
                                          };
                                          setData(list);
                                        }}
                                      ></Form.Control>
                                    </Form.Group>
                                  </td>{" "}
                                  <td>
                                    <Form.Group>
                                      <label>Attribut* </label>
                                      <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={optionAttribut.find(
                                          (option) =>
                                            option.value == val.attribut
                                        )}
                                        onChange={(value) => {
                                          var list = [...data];
                                          list[key] = {
                                            ...list[key],
                                            attribut: value.value,
                                          };
                                          setData(list);
                                        }}
                                        options={optionAttribut}
                                      />
                                    </Form.Group>
                                  </td>{" "}
                                  <td>
                                    <div className="actions-right block_action">
                                      <Button
                                        onClick={(ev) => {
                                          confirmMessage(key);
                                        }}
                                        variant="danger"
                                        size="sm"
                                        className="text-danger btn-link delete"
                                      >
                                        <i className="fa fa-trash" />
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <Button
                          className="btn-fill pull-left"
                          type="button"
                          variant="info"
                          nom="redac"
                          onClick={(ev) => AjoutLigne()}
                        >
                          Ajouter ligne
                        </Button>
                      </div>
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          id="saveAdd"
                          onClick={submitForm}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default AjouterCoach;
