import { Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { fetchIframe } from "../../../Redux/iframeReduce";

// core components
function Dashboard({ obj }) {
  const dispatch = useDispatch();
  const [entities, setEntities] = React.useState([]);
  var idRole = obj.user.id_role;
  var idUser = obj.user.id;

  const getIframe = useCallback(async () => {
    var iframes = await dispatch(fetchIframe({ idRole, idUser }));
    var res = await iframes.payload.iframes;
    setEntities(res);
  }, [dispatch]);

  useEffect(() => {
    getIframe();
  }, []);

  return (
    <>
      <Container fluid>
        <ToastContainer />
        {entities.length > 0 ? (
          <Row>
            {entities.map((item, index) => {
              return (
                <Col md="12">
                  <Card className="card-header">
                    <Card.Body>
                      <iframe
                        key={index}
                        title={`Transition${index + 1}`}
                        width="100%"
                        height="800px"
                        src={`${item.lien}#toolbar=0`}
                      ></iframe>
                    </Card.Body>
                  </Card>
                  <br></br>
                  <br></br>
                  <br></br>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Col md="12">
            <Card>
              <Card.Body>
                <div className="text-center">Aucune iframe trouvée</div>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Container>
    </>
  );
}

export default Dashboard;
