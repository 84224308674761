import { createAsyncThunk } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");


export const fetchCoachFlm = createAsyncThunk("coachFlm/fetchCoachFlm", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "coachFlm/allCoachFlm", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),
  });
  const coachFlm = await response.json();
  return coachFlm;
});


export const coachFlmAdded = createAsyncThunk("coachFlm/addCoachFlm", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "coachFlm/addCoachFlm", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const coachFlm = await response.json();
    return coachFlm;
  });

  export const sendCoachFlm = createAsyncThunk("coachFlm/sendCoachFlm", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "coachFlm/sendCoachFlm", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const coachFlm = await response.json();
    return coachFlm;
  });


  export const coachFlmGetById = createAsyncThunk("coachFlm/coachFlmGetById", async (id1) => {
    const  id  = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "coachFlm/getCoachFlm", {
      method: 'POST',
      headers: {
        'id':id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const coachFlm = await response.json();
    return coachFlm;
  });

  export const coachFlmUpdateEtat = createAsyncThunk("coachFlm/updateEtat", async (action) => {
    var id = action.id ; 
    const response = await fetch(Configuration.BACK_BASEURL + "coachFlm/updateEtat/"+id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action)
    });
    const coachFlm = await response.json();
    return coachFlm;
  });
  

