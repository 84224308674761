import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");


export const evaluationAdded = createAsyncThunk("evaluation/addEvaluation", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "evaluation/addEvaluation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const evaluation = await response.json();
    return evaluation;
  });

  export const fetchEvaluation = createAsyncThunk("evaluation/fetchEvaluation", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "evaluation/allEvaluation", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action),
    });
    const evaluation = await response.json();
    return evaluation;
  });


  export const evaluationGetById = createAsyncThunk("evaluation/evaluationGetById", async (id1) => {
    const  id  = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "evaluation/getEvaluation", {
      method: 'POST',
      headers: {
        'id':id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const evaluation = await response.json();
    return evaluation;
  });


  export const evaluationSended = createAsyncThunk("evaluation/sendEvaluation", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "evaluation/sendEvaluation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const evaluation = await response.json();
    return evaluation;
  });
  

  export const evalUpdateEtat = createAsyncThunk("evaluation/updateEtat", async (action) => {
    var id = action.id ; 
    const response = await fetch(Configuration.BACK_BASEURL + "evaluation/updateEtat/"+id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action)
    });
    const evaluation = await response.json();
    return evaluation;
  });
  