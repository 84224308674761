import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchGouvernorat = createAsyncThunk("gouvernorat/fetchGouvernorat", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "gouvernorat/allGouvernorat", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },

  });
  const gouvernorat = await response.json();
  return gouvernorat;
});

export const gouvernoratAdded = createAsyncThunk("gouvernorat/addGouvernorat", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "gouvernorat/addGouvernorat", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const gouvernorat = await response.json();
    return gouvernorat;
  });

export const gouvernoratGetById = createAsyncThunk("gouvernorat/gouvernoratGetById", async (id1) => {
  const  id  = id1;
  const response = await fetch(Configuration.BACK_BASEURL + "gouvernorat/getGouvernorat", {
    method: 'POST',
    headers: {
      'id':id,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const gouvernorat = await response.json();
  return gouvernorat;
});

export const gouvernoratDeleted = createAsyncThunk("gouvernorat/deleteGouvernorat", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "gouvernorat/deleteGouvernorat/"+action.id, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  });
  const gouvernorat = await response.json();
  return gouvernorat;
});

export const gouvernoratChangeEtat = createAsyncThunk(
  "gouvernorat/changeEtat",
  async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "gouvernorat/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const gouvernorat = await response.json();
    return gouvernorat;
  }
);



