import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchSpecialite = createAsyncThunk("specialite/fetchSpecialite", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "specialite/allSpecialite", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },

  });
  const specialite = await response.json();
  return specialite;
});

export const specialiteAdded = createAsyncThunk("specialite/addSpecialite", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "specialite/addSpecialite", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const specialite = await response.json();
    return specialite;
  });

export const specialiteGetById = createAsyncThunk("specialite/specialiteGetById", async (id1) => {
  const  id  = id1;
  const response = await fetch(Configuration.BACK_BASEURL + "specialite/getSpecialite", {
    method: 'POST',
    headers: {
      'id':id,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const specialite = await response.json();
  return specialite;
});

export const specialiteDeleted = createAsyncThunk("specialite/deleteSpecialite", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "specialite/deleteSpecialite/"+action.id, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  });
  const specialite = await response.json();
  return specialite;
});

export const specialiteChangeEtat = createAsyncThunk(
  "specialite/changeEtat",
  async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "specialite/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const specialite = await response.json();
    return specialite;
  }
);



