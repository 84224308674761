import React from "react";
import { Link, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
// react-bootstrap components
import { Collapse, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";

function Sidebar({ routes, background, users }) {
  var pathStorage = localStorage.getItem("path");

  const { entities } = useSelector((state) => state.settings);
  let location = useLocation();
  const [state, setState] = React.useState({});
  /* const { entities } = useSelector((state) => state.settings); */
  const { rootBase } = useSelector((state) => state.rootBase);
  var routes = rootBase ? rootBase[0] : [];
  var token = localStorage.getItem("x-access-token");
  var decoded = jwt_decode(token);
  var id_role = users.user.id_role;
  React.useEffect(() => {
    /* setState(getCollapseStates(routes)); */
  }, []);
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true;
      }
    }
    return false;
  };
  function LogOut(e) {
    e.preventDefault();
    localStorage.clear();
    window.location.replace("/login");
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    let links = routes.map((prop, key) => {
      var st = {};
      if (prop.role.includes(id_role) || prop.role.includes(20)) {
        if (prop.redirect) {
          return null;
        }
        if (prop.collapse) {
          st[prop["state"]] = !state[prop.state];
          return (
            <Nav.Item
              className={getCollapseInitialState(prop.views) ? "active" : ""}
              as="li"
              key={key}
            >
              <Nav.Link
                className={state[prop.state] ? "collapsed" : ""}
                data-toggle="collapse"
                onClick={(e) => {
                  e.preventDefault();
                  setState({ ...state, ...st });
                }}
                aria-expanded={state[prop.state]}
              >
                <i className={prop.icon}></i>
                <p>
                  {prop.name} <b className="caret"></b>
                </p>
              </Nav.Link>
              <Collapse in={state[prop.state]}>
                <div>
                  <Nav as="ul">{createLinks(prop.views)}</Nav>
                </div>
              </Collapse>
            </Nav.Item>
          );
        }
        return (
          <Nav.Item
            className={activeRoute(prop.path, prop.pathChild)}
            key={key}
            as="li"
          >
            <Nav.Link className={prop.className} to={prop.path} as={Link}>
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <p> {prop.name}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
            </Nav.Link>
          </Nav.Item>
        );
      } else {
        return null;
      }
    });
    if (id_role !== 1) {
      links = links.concat([
        <Nav.Item key="logout" as="li">
          <Nav.Link onClick={LogOut}>
            <i className="fas fa-power-off"></i>
            <p>Déconnecter</p>
          </Nav.Link>
        </Nav.Item>,
      ]);
    } else {
      if (routes.length > 20) {
        links = links.concat([
          <Nav.Item key="logout" as="li">
            <Nav.Link onClick={LogOut}>
              <i className="fas fa-power-off"></i>
              <p>Déconnecter</p>
            </Nav.Link>
          </Nav.Item>,
        ]);
      }
    }
    return links;
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (path, pathChild) => {
    var pathss = pathChild.split("/")[1];
    var childs = pathss.split(",");
    return location.pathname === path ||
      (childs.includes(location.pathname.split("/")[2]) && path === pathStorage)
      ? "active"
      : "";
  };

  function getTitle(routeName, name) {
    if (location.pathname.toUpperCase() === routeName.toUpperCase()) {
      if (routeName !== "/telechargerFichier/:id/:idBl") {
        localStorage.removeItem("file");
        localStorage.removeItem("returnList");
      }
      if (routeName !== "/ajouterProduit") {
        localStorage.removeItem("nomProd");
        localStorage.removeItem("idProd");
      }
      document.title = name;
      //return location.pathname === routeName ? routeName : "";
    }
  }
  return (
    <>
      <div className="sidebar" data-color={background}>
        <div className="sidebar-wrapper">
          <div className="logo">
            <div className="bglogo">
              {entities.length > 0 ? (
                <img
                  src={require("../../assets/img/" + entities[0].logo)}
                  alt="medicacom"
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <Nav as="ul">{createLinks(routes)}</Nav>
          <br></br>
          {/* <Nav as="ul">{LogOut}</Nav>
          <Nav.Item className="logout-link-text">
            <Nav.Link onClick={LogOut}>
              <div style={{ display: "flex" }}>
                <i class="fas fa-power-off"></i>
                <p className="logout-link-text"> Déconnecter</p>
              </div>
            </Nav.Link>
          </Nav.Item> */}
        </div>
      </div>
    </>
  );
}

Sidebar.defaultProps = {
  image: "",
  background: "black",
  routes: [],
};

export default Sidebar;
