import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchBrick = createAsyncThunk("brick/fetchBrick", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "brick/allBrick", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },

  });
  const brick = await response.json();
  return brick;
});

export const brickAdded = createAsyncThunk("brick/addBrick", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "brick/addBrick", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const brick = await response.json();
    return brick;
  });

export const brickGetById = createAsyncThunk("brick/brickGetById", async (id1) => {
  const  id  = id1;
  const response = await fetch(Configuration.BACK_BASEURL + "brick/getBrick", {
    method: 'POST',
    headers: {
      'id':id,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const brick = await response.json();
  return brick;
});

export const brickDeleted = createAsyncThunk("brick/deleteBrick", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "brick/deleteBrick/"+action.id, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  });
  const rootBase = await response.json();
  return rootBase;
});

export const brickChangeEtat = createAsyncThunk(
  "brick/changeEtat",
  async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "brick/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const brick = await response.json();
    return brick;
  }
);



