import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchVad = createAsyncThunk("vad/fetchVad", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "vad/allVad", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },

  });
  const vad = await response.json();
  return vad;
});

export const vadAdded = createAsyncThunk("vad/addVad", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "vad/addVad", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const vad = await response.json();
    return vad;
  });

export const vadGetById = createAsyncThunk("vad/vadGetById", async (id1) => {
  const  id  = id1;
  const response = await fetch(Configuration.BACK_BASEURL + "vad/getVad", {
    method: 'POST',
    headers: {
      'id':id,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const vad = await response.json();
  return vad;
});


export const getVads = createAsyncThunk("vad/getVads", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "vad/getVads", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),
  
  });
  const vad = await response.json();
  return vad;
});


export const vadChangeEtat = createAsyncThunk(
  "vad/changeEtat",
  async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "vad/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const vad = await response.json();
    return vad;
  }
);



