import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import MaterialReactTable from "material-react-table";
import { useHistory } from "react-router";
import { deleteplan, fetchPlan } from "../../../Redux/planReduce";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
function ListPlan() {
  const anneeObj = JSON.parse(localStorage.getItem("annee"));
  var annee = anneeObj.label;
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [entities, setEntities] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const columns = useMemo(
    () => [
      //column definitions...
      {
        header: "Rep",
        accessorKey: "reps.nom",
        Cell: ({ cell }) => cell.row.original.reps.nom,
      },
      {
        header: "Nom",
        accessorKey: "nom",
      },
      {
        header: "Titre",
        accessorKey: "titre",
      },
      {
        header: "Date Limite",
        accessorKey: "date",
      },
      {
        header: "Avancement",
        accessorKey: "avancement",
      },
      {
        header: "Référence",
        accessorKey: "references.designation",
        Cell: ({ cell }) => cell.row.original.references.designation,
      },
      {
        header: "Détails",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                navigate.push("/plan/detailPlan/" + cell.row.original.id);
              }}
              variant="warning"
              size="sm"
            >
              <i className="fa fa-eye" />
            </Button>
          </div>
        ),
      },
      {
        accessorKey: "id",
        header: "Actions",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            {cell.row.original.avancement !== "100%" ? (
              <Button
                onClick={() => {
                  navigate.push("/plan/update/" + cell.row.original.id);
                }}
                variant="warning"
                size="sm"
              >
                <span className="btn-label"> {"Modifier"}</span>
              </Button>
            ) : (
              ""
            )}
            {cell.row.original.avancement == "0%" ? (
              <Button
                onClick={(ev) => {
                  confirmMessage(cell.row.original.id);
                }}
                variant="danger"
                size="sm"
                style={{ marginLeft: "5px" }}
              >
                <span className="btn-label"> {"Supprimer"}</span>
              </Button>
            ) : (
              ""
            )}
          </div>
        ),
      },
      //end
    ],
    []
  );
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  const confirmMessage = (id) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={"Êtes-vous sûr de supprimer ce plan ?"}
        onConfirm={() => {
          deletePlan(id);
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      ></SweetAlert>
    );
  };
  function deletePlan(id) {
    dispatch(deleteplan(id));
    notify(1, "Plan supprimée avec succès");
    hideAlert();
    setTimeout(async () => {
      window.location.reload();
    }, 1500);
  }
  const hideAlert = () => {
    setAlert(null);
  };

  const getPlan = useCallback(async () => {
    var plans = await dispatch(fetchPlan({ annee }));
    var res = await plans.payload;
    setEntities(res);
  }, [dispatch]);

  useEffect(() => {
    getPlan();
  }, []);

  function ListTable({ list }) {
    return (
      <MaterialReactTable
        columns={columns}
        data={list}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
      />
    );
  }

  return (
    <>
      {alert}
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col md="12">
            <Button
              id="returnBtn"
              className=""
              type="button"
              variant="info"
              onClick={(event) => {
                navigate.goBack();
              }}
              style={{ float: "right" }}
            >
              <span className="btn-label">
                <i className="fas fa-list"></i>
              </span>
              Retour à la liste
            </Button>
          </Col>
          <Col md="12">
            <h4 className="title">Liste des plans</h4>
            <Card className="card-header">
              <Card.Body>
                <ListTable list={entities}></ListTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListPlan;
