import { createAsyncThunk } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchCycle = createAsyncThunk("cycle/fetchCycle", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "cycle/allCycle", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },

  });
  const cycle = await response.json();
  return cycle;
});

export const cycleAdded = createAsyncThunk("cycle/addCycle", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "cycle/addCycle", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const cycle = await response.json();
    return cycle;
  });

export const cycleGetById = createAsyncThunk("cycle/cycleGetById", async (id1) => {
  const  id  = id1;
  const response = await fetch(Configuration.BACK_BASEURL + "cycle/getCycle", {
    method: 'POST',
    headers: {
      'id':id,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const cycle = await response.json();
  return cycle;
});



export const cycleChangeEtat = createAsyncThunk("cycle/changeEtat", async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "cycle/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const cycle = await response.json();
    return cycle;
  }
);



