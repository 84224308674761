import {  Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { planGetById } from "../../../Redux/planReduce";

// core components
function DetailPlan({ obj }) {
  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();

  const [nom, setNom] = React.useState("");
  const [rep, setRep] = React.useState("");
  const [reference, setReference] = React.useState("");
  const [titre, setTitre] = React.useState("");
  const [date, setDate] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [avancement, setAvancement] = React.useState("");

  var id = location.id;

  const getPlan = useCallback(async () => {
    var plan = await dispatch(planGetById(id));
    setNom(plan.payload.nom);
    setTitre(plan.payload.titre);
    setDate(plan.payload.date);
    setDescription(plan.payload.description);
    setAvancement(plan.payload.avancement);
    setRep(plan.payload.reps.nom);
    setReference(plan.payload.references.designation);
  }, [dispatch]);

  useEffect(() => {
    getPlan();
  }, [getPlan]);

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <>
              {" "}
              <Card>
                <Card>
                  <Card.Header className="background-white">
                    <Col md="12">
                      <Button
                        className=""
                        id="returnBtn"
                        type="button"
                        variant="info"
                        onClick={(event) => {
                          navigate.goBack();
                        }}
                        style={{ float: "right" }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-list"></i>
                        </span>
                        Retour à la liste
                      </Button>
                    </Col>
                    <br clear="all"></br>
                    <Card.Title
                      className="background-white"
                      as="h3"
                      style={{ textAlign: "center" }}
                    >
                      Détails plan d'action
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Card>
                  <Card.Body>
                    <h5>
                      <b>Rep : </b> {rep}
                    </h5>
                    <h5>
                      <b>Nom : </b> {nom}
                    </h5>
                    <h5>
                      <b>Titre : </b> {titre}
                    </h5>
                    <h5>
                      <b>Date limite : </b> {date}
                    </h5>
                    <h5>
                      <b>Description : </b>{" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    </h5>
                    <h5>
                      <b>Avancement : </b> {avancement}
                    </h5>
                    <h5>
                      <b>Référence : </b> {reference}
                    </h5>
                  </Card.Body>
                </Card>

                <Row></Row>
              </Card>
            </>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default DetailPlan;
