import React from "react";
import { Navbar, Container, Nav, Dropdown, Button, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { fetchAnnee, anneeAdded } from "../../Redux/anneeReduce";
import Select from "react-select";
import {
  getNotification,
  updateNotifaction,
} from "../../Redux/notificationReduce";
function Header({ users, onlineStatus }) {
  var navigate = useHistory();
  const dispatch = useDispatch();
  const [annee, setAnnee] = React.useState();
  const [optionsAnnee, setOptionsAnnee] = React.useState([
    {
      value: "",
      label: "Annee",
      isDisabled: true,
    },
  ]);
  const [notification, setNotification] = React.useState([]);
  const [nb, setNb] = React.useState(0);
  var nom = onlineStatus === false ? users.user.nom : users.user.nom;
  var idUser = onlineStatus === false ? users.user.id : users.user.id;
  var idRole = onlineStatus === false ? users.user.id_role : users.user.id_role;

  function setData(notif) {
    var array = [];
    array.push(
      <Dropdown.Item
        className="enteteDropDown"
        href="#"
        key={"entet" + notif.length}
      >
        {notif.length} notification(s)
      </Dropdown.Item>
    );
    var arrayDiv = [];
    notif.forEach((element) => {
      if (idRole == 3) {
        arrayDiv.push(
          <Dropdown.Item
            className={element.lu === 0 ? "non lu" : ""}
            href="#"
            key={"notif" + element.id}
            onClick={() => {
              changeEtat(
                element.id,
                element.id_rep,
                element.id_flm,
                element.id_ffm,
                element.type
              );
            }}
          >
            {element.text}
          </Dropdown.Item>
        );
      } else if (idRole == 2  || idRole === 1) {
        arrayDiv.push(
          <Dropdown.Item
            className={element.lu === 0 ? "non lu" : ""}
            href="#"
            key={"notif" + element.id}
            onClick={() => {
              changeEtat(
                element.id,
                element.id_rep,
                element.id_flm,
                element.id_ffm,
                element.type
              );
            }}
          >
            {element.text}
          </Dropdown.Item>
        );
      } else if (idRole == 4) {
        arrayDiv.push(
          <Dropdown.Item
            className={element.lu === 0 ? "non lu" : ""}
            href="#"
            key={"notif" + element.id}
            onClick={() => {
              changeEtat(
                element.id,
                element.id_rep,
                element.id_flm,
                element.id_ffm,
                element.type
              );
            }}
          >
            {element.text}
          </Dropdown.Item>
        );
      }
    });
    array.push(
      <div key="onScroll" className={arrayDiv.length > 7 ? "onScroll" : ""}>
        {arrayDiv}
      </div>
    );
    if (notif.length === 0) {
      array.push(
        <Dropdown.Item href="#" key={0}>
          Aucune notification trouvée
        </Dropdown.Item>
      );
    }
    array.push(
      <Dropdown.Item
        className="footerDropDown"
        href="#"
        key={"footer" + array.length}
        onClick={(event) => {
          changeEtat(0, -1, -1, -1);
        }}
      >
        Lire toutes les notifications
      </Dropdown.Item>
    );

    setNb(notif.length);
    setNotification(array);
  }
  const changeEtat = React.useCallback(
    async (id, idRep, idFlm, idFfm, type) => {
      console.log(id);
      var res = await dispatch(
        updateNotifaction({
          id: id,
          idRole: idRole,
        })
      );
      console.log(res);
      if (idRole == 3) {
        if (type === 1) {
          window.location.replace("/utilisateur/evaluationSems/" + idRep);
        } else if (type == 2) {
          window.location.replace("/utilisateur/evaluation/" + idRep);
        }
      } else if (idRole == 4) {
        if (type === 44) {
          window.location.replace("/utilisateur/listCoachDuCoach/" + idFlm);
        } else if (type == 2) {
        }
      } else if (idRole === 2 || idRole === 1) {
        if (type === 11) {
          window.location.replace("/utilisateur/evaluationSems/" + idRep);
        } else if (type == 22) {
          window.location.replace("/utilisateur/evaluation/" + idRep);
        } else if (type == 3) {
          window.location.replace("/utilisateur/listPlanCoachingFlm/" + idFlm);
        } else if (type == 4) {
          window.location.replace("/utilisateur/listCoachDuCoach/" + idFlm);
        }
      }
      getNotif();
    },
    [dispatch]
  );

  const getNotif = React.useCallback(async () => {
    dispatch(getNotification({ idRole })).then((val) => {
      var dataNot = val.payload === undefined ? [] : val.payload;
      setData(dataNot);
    });
  }, [dispatch]);

  function LogOut(e) {
    e.preventDefault();
    localStorage.clear();
    window.location.replace("/login");
  }
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    return "";
  };
  const getAnnes = React.useCallback(async () => {
    var year = await dispatch(fetchAnnee());
    var arrayOption = [];
    year.payload.forEach((element) => {
      arrayOption.push({
        value: element.annee,
        label: element.annee,
        selected: element.selected,
        id: element.id,
      });
      if (element.selected === 1) {
        setAnnee({ value: element.annee, label: element.annee });
        localStorage.setItem(
          "annee",
          JSON.stringify({ value: element.id, label: element.annee })
        );
      }
    });

    setOptionsAnnee(arrayOption);
  }, [dispatch]);

  React.useEffect(() => {
    getNotif();
    getAnnes();
  }, [getAnnes]);

  function updateAnnee(value) {
    dispatch(anneeAdded({ annee: value.annee, id: value.id, selected: 1 }));
    localStorage.setItem("annee", value.value);
    window.location.reload();
  }

  return (
    <Navbar bg="light" expand="lg" className={"admin"}>
      <Container fluid>
        <div className="display-none d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {getBrandText()}
          </Navbar.Brand>
        </div>
        <div className="navbar-wrapper">
          <div className="navbar-minimize">
            <Button
              className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
              variant="dark"
              onClick={() => document.body.classList.toggle("sidebar-mini")}
            >
              <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
              <i className="fas fa-bars visible-on-sidebar-mini"></i>
            </Button>
            <Button
              className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
              variant="dark"
              onClick={() =>
                document.documentElement.classList.toggle("nav-open")
              }
            >
              <i className="fas fa-list"></i>
              <i className="fas fa-bars visible-on-sidebar-mini"></i>
            </Button>
          </div>
          {/* <Navbar.Brand
            href="#"
            onClick={(e) => e.preventDefault()}
          ></Navbar.Brand> */}
        </div>
        <Col md="3" className="annee-width">
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            name="singleSelect"
            value={annee}
            onChange={(value) => {
              setAnnee(value);
              updateAnnee(value);
              localStorage.setItem(
                "annee",
                JSON.stringify({ value: value.id, label: value.value })
              );
              window.location.reload();
            }}
            options={optionsAnnee}
            placeholder="Annee"
          />
        </Col>
        <Navbar.Collapse id="basic-navbar-nav" style={{ justifyContent:"flex-end" }}>
          <Nav className="ml-auto" navbar>
            {/* <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon">Account</span>
              </Nav.Link>
            </Nav.Item> */}
            <Dropdown as={Nav.Item} className="dropdown-notification">
              <Dropdown.Toggle as={Nav.Link} id="dropdown-1" variant="default">
                <i className="fas fa-bell" style={{ fontSize: "20px" }}></i>
                <span className="notification">{nb}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="noScroll"
                style={{ width: "170px", textAlign: "center" }}
              >
                {notification}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                aria-expanded={false}
                aria-haspopup={true}
                as={Nav.Link}
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                variant="default"
                className="m-0"
              >
                <span className="no-icon">{nom}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                <Dropdown.Item
                  href="#"
                  onClick={(e) => navigate.push("/Settings")}
                  style={{ display: "none" }}
                >
                  <i className="fas fa-cog"></i>
                  Paramètres
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={(e) => navigate.push("/profile")}
                >
                  <i className="fas fa-user"></i>
                  Mon profil
                </Dropdown.Item>
                {!onlineStatus ? (
                  <Dropdown.Item href="#" onClick={LogOut}>
                    <i className="nc-icon nc-button-power"></i>
                    Déconnecter
                  </Dropdown.Item>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
