import { createAsyncThunk} from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchLineBusiness = createAsyncThunk("lineBusiness/fetchLineBusiness", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "lineBusiness/allLineBusiness", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
  const lineBusiness = await response.json();
  return lineBusiness;
});

export const lineBusinessAdded = createAsyncThunk("lineBusiness/addLineBusiness", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "lineBusiness/addLineBusiness", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(action),
  });
  const lineBusiness = await response.json();
  return lineBusiness;
});

export const lineBusinessChangeEtat = createAsyncThunk(
  "lineBusiness/changeEtat",
  async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "lineBusiness/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const lineBusiness = await response.json();
    return lineBusiness;
  }
);

export const lineBusinessGetById = createAsyncThunk("lineBusiness/lineBusinessGetById", async (id1) => {
    const  id  = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "lineBusiness/getLineBusiness", {
      method: 'POST',
      headers: {
        'id':id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const lineBusiness = await response.json();
    return lineBusiness;
  });
  