import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchGrille = createAsyncThunk("grille/fetchGrille", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "grille/allGrille", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },

  });
  const grille = await response.json();
  return grille;
});

export const grilleAdded = createAsyncThunk("grille/addGrille", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "grille/addGrille", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const grille = await response.json();
    return grille;
  });

export const grilleGetById = createAsyncThunk("grille/grilleGetById", async (id1) => {
  const  id  = id1;
  const response = await fetch(Configuration.BACK_BASEURL + "grille/getGrille", {
    method: 'POST',
    headers: {
      'id':id,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const grille = await response.json();
  return grille;
});


export const getGrilles = createAsyncThunk("grille/getGrilles", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "grille/getGrilles", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),
  
  });
  const grille = await response.json();
  return grille;
});


export const grilleChangeEtat = createAsyncThunk(
  "grille/changeEtat",
  async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "grille/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const grille = await response.json();
    return grille;
  }
);



