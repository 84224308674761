import { Button, Card, Container, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import MaterialReactTable from "material-react-table";
import { fetchEvaluation } from "../../../Redux/evaluationReduce";
import { userGetById } from "../../../Redux/usersReduce";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
function EvaluationSemestrielle({ obj }) {
  var idRole = obj.user.id_role;
  var idUser = obj.user.id;

  var date = new Date();
  var jour = date.getDate();
  var mois = date.getMonth() + 1;
  var anneee = date.getFullYear();
  var today = anneee + "-" + mois + "-" + jour;

  const anneeObj = JSON.parse(localStorage.getItem("annee"));
  var annee = anneeObj.label;

  const location = useParams();
  const dispatch = useDispatch();
  var navigate = useHistory();
  var id = location.id;

  const [entities, setEntities] = React.useState([]);
  const [entitiesEnCours, setEntitiesEnCours] = React.useState([]);
  const [entitiesValides, setEntitiesValides] = React.useState([]);
  const [entitiesAnnules, setEntitiesAnnules] = React.useState([]);
  const [testAdd, setTestAdd] = React.useState(true);
  const [testVad, setTestVad] = React.useState(0);
  const [user, setUser] = React.useState({});
  const [alert, setAlert] = React.useState(null);
  const [loader, setLoader] = React.useState(true);

  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  const columns = useMemo(
    () => [
      //column definitions...
      {
        header: "Utilisateur",
        accessorKey: "nom",
        //  Cell: ({ cell }) => cell.row.original.users.nom,
      },
      {
        header: "Rep",
        accessorKey: "rep",
        // Cell: ({ cell }) => cell.row.original.reps.nom,
      },
      {
        header: "Date d'inserstion",
        accessorKey: "date",
      },
      {
        header: "Trimestre",
        accessorKey: "trimester",
      },

      {
        header: "Détails",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                if (
                  cell.row.original.etat === 1 ||
                  cell.row.original.etat === 2
                ) {
                  navigate.push(
                    "/utilisateur/detailEval/" + cell.row.original.id
                  );
                } else {
                  navigate.push(
                    "/evaluationTrimes/update/" + cell.row.original.id
                  );
                }
              }}
              variant="warning"
              size="sm"
            >
              <i className="fa fa-eye" />
            </Button>
          </div>
        ),
      },
      {
        header: "Notes",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              className="btn btn-info"
              onClick={() => {
                getCommentaire(cell.row.original.notes);
              }}
              size="sm"
              style={{ marginLeft: "10px" }}
            >
              Lire <i className="fa fa-comment" />
            </Button>
          </div>
        ),
      },
      {
        header: "État",
        accessorKey: "etat",
        Cell: ({ cell, row }) =>
          cell.row.original.etat === 0
            ? "En attente de validation par le superviseur"
            : cell.row.original.etat === 1
            ? "En attente d'approbation par le délégué"
            : cell.row.original.etat === 2
            ? "Approuvée"
            : "Annulée",
      },
      {
        accessorKey: "id",
        header: "Actions",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            {/* <Button
              onClick={(ev) => {
                confirmMessage(cell.row.original.id);
              }}
              variant="danger"
              size="sm"
              className="text-danger btn-link delete"
            >
              <i className="fa fa-trash" />
            </Button> */}
            {cell.row.original.etat === 0 || cell.row.original.etat === 3 ? (
              <Button
                onClick={() => {
                  navigate.push(
                    "/evaluationTrimes/update/" + cell.row.original.id
                  );
                }}
                variant="warning"
                size="sm"
                className="text-warning btn-link edit"
              >
                <i className="fa fa-edit" />
              </Button>
            ) : (
              ""
            )}
          </div>
        ),
      },
      //end
    ],
    []
  );
  const columnsRep = useMemo(
    () => [
      //column definitions...
      {
        header: "Utilisateur",
        accessorKey: "nom",
        //  Cell: ({ cell }) => cell.row.original.users.nom,
      },
      {
        header: "Rep",
        accessorKey: "rep",
        // Cell: ({ cell }) => cell.row.original.reps.nom,
      },
      {
        header: "Date d'inserstion",
        accessorKey: "date",
      },
      {
        header: "Trimestre",
        accessorKey: "trimester",
      },

      {
        header: "Détails",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                navigate.push(
                  "/utilisateur/detailEval/" + cell.row.original.id
                );
              }}
              variant="warning"
              size="sm"
            >
              <i className="fa fa-eye" />
            </Button>
          </div>
        ),
      },
      {
        header: "Notes",
        accessorKey: "id",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              className="btn btn-info"
              onClick={() => {
                getCommentaire(cell.row.original.notes);
              }}
              size="sm"
              style={{ marginLeft: "10px" }}
            >
              Lire <i className="fa fa-comment" />
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  const getEvaluation = useCallback(async () => {
    var user = await dispatch(userGetById(id));
    setUser(user.payload.header);

    var date = new Date();
    var mois = String(date.getMonth() + 1).padStart(2, "0");

    var trimestre1 = ["01", "02", "03", "04"];
    var trimestre2 = ["05", "06", "07", "08"];
    var trimestre3 = ["09", "10", "11", "12"];

    var trimestre = null;
    if (trimestre1.includes(mois.toString())) {
      trimestre = 1;
    }

    if (trimestre2.includes(mois.toString())) {
      trimestre = 2;
    }
    if (trimestre3.includes(mois.toString())) {
      trimestre = 3;
    }

    var testEval = true;
    var arrayEval = [];
    var arrayEvalEnCours = [];
    var arrayEvalValide = [];
    var arrayEvalAnnule = [];

    var evaluation = await dispatch(
      fetchEvaluation({ annee, idUser, idRole, id })
    );
    var res = await evaluation.payload.evaluations;
    var allEvaluations = await evaluation.payload.allEvaluation;
    var evaluationVad = await evaluation.payload.evaluationVads;
    allEvaluations.forEach((element) => {
      if (element.trimester == trimestre && element.reps.id == id) {
        testEval = false;
      }
    });
    setTestVad(evaluationVad);
    if (evaluationVad == 0) testEval = false;
    res.forEach((element) => {
      if (element.reps.id == id && (element.etat === 1 || element.etat === 0)) {
        arrayEval.push({
          id: element.id,
          nom: element.users.nom,
          rep: element.reps.nom,
          idRep: element.reps.id,
          trimester: element.trimester,
          etat: element.etat,
          notes: element.notes,
          date: element.createdAt.split("T")[0],
        });
      }
      if (element.reps.id == id && element.etat === 1) {
        arrayEvalEnCours.push({
          id: element.id,
          nom: element.users.nom,
          rep: element.reps.nom,
          idRep: element.reps.id,
          trimester: element.trimester,
          etat: element.etat,
          notes: element.notes,
          date: element.createdAt.split("T")[0],
        });
      }
      if (element.reps.id == id && element.etat === 2) {
        arrayEvalValide.push({
          id: element.id,
          nom: element.users.nom,
          rep: element.reps.nom,
          idRep: element.reps.id,
          trimester: element.trimester,
          etat: element.etat,
          notes: element.notes,
          date: element.createdAt.split("T")[0],
        });
      }
      if (element.reps.id == id && element.etat === 3) {
        arrayEvalAnnule.push({
          id: element.id,
          nom: element.users.nom,
          rep: element.reps.nom,
          idRep: element.reps.id,
          trimester: element.trimester,
          etat: element.etat,
          notes: element.notes,
          date: element.createdAt.split("T")[0],
        });
      }
    });
    setTestAdd(testEval);
    setEntities(arrayEval);
    setEntitiesEnCours(arrayEvalEnCours);
    setEntitiesValides(arrayEvalValide);
    setEntitiesAnnules(arrayEvalAnnule);
    setLoader(false);
  }, [dispatch]);

  const getCommentaire = useCallback(async (tableNotes) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={"Les commentaires d'evaluation trimestrielle "}
        onConfirm={() => hideAlert()}
        cancelBtnBsStyle="danger"
      >
        {tableNotes.length > 0 ? (
          <div className="panel-body">
            {tableNotes.map((val, key) => {
              var date = new Date(val.createdAt)
                .toISOString()
                .slice(0, 16)
                .replace("T", " à ");
              return (
                <ul>
                  <span className="discutions">
                    <li className="discutions-li">
                      <i className="nc-icon nc-stre-right"></i>
                      <b>{val.users.nom}:</b> {val.note}
                      <span className="discutions-date"> {date}</span>
                    </li>
                  </span>
                </ul>
              );
            })}
          </div>
        ) : (
          "aucun note trouvé"
        )}
      </SweetAlert>
    );
  }, []);

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    getEvaluation();
  }, []);

  function ListTable({ list }) {
    return (
      <MaterialReactTable
        columns={
          idRole === 3 || idRole === 4 || idRole === 1 ? columnsRep : columns
        }
        data={list}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }

  function ListTableVadEnCours({ listEnCours }) {
    return (
      <MaterialReactTable
        columns={
          idRole === 3 || idRole === 4 || idRole === 1 ? columnsRep : columns
        }
        data={listEnCours}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }

  function ListTableAnnules({ listAnnule }) {
    return (
      <MaterialReactTable
        columns={
          idRole === 3 || idRole === 4 || idRole === 1 ? columnsRep : columns
        }
        data={listAnnule}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }

  function ListTableValides({ listValide }) {
    return (
      <MaterialReactTable
        columns={
          idRole === 3 || idRole === 4 || idRole === 1 ? columnsRep : columns
        }
        data={listValide}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }

  return (
    <>
      {alert}
      <Container fluid>
        <ToastContainer />
        <Card>
          <Card.Header className="background-white">
            <Col md="12">
              <Button
                id="returnBtn"
                className=""
                type="button"
                variant="info"
                onClick={(event) => {
                  navigate.goBack();
                }}
                style={{ float: "right" }}
              >
                <span className="btn-label">
                  <i className="fas fa-list"></i>
                </span>
                Retour à la liste
              </Button>
            </Col>
            <Card.Title className="background-white" as="h3">
              <div style={{ display: "flex" }}>
                <strong> Rep : {user.nom}</strong>
              </div>
            </Card.Title>
          </Card.Header>{" "}
          <Card.Header className="background-white">
            <Card.Title className="background-white" as="h3">
              <div style={{ display: "flex" }}>
                <strong>{today}</strong>
              </div>
            </Card.Title>
          </Card.Header>
          <br clear="all"></br>
          {idRole === 2 ? (
            <Button
              className="btn-val green-button"
              type="button"
              onClick={() => {
                if (testAdd) {
                  navigate.push("/utilisateur/detailEvaluation/" + id);
                } else {
                  if (testVad == 0) {
                    notify(2, "Vous devez insérer une evaluation VAD");
                  } else
                    notify(
                      2,
                      "Évaluation trimestrielle déjà insérée au cours de cette trimestre"
                    );
                }
              }}
            >
              Evaluation Trimestrielle
            </Button>
          ) : (
            ""
          )}
          {idRole === 2 ? (
            <Col md="12">
              <h4 className="eval-title">
                Evaluations trimestrielles en cours
              </h4>
              <Card.Body>
                <ListTable list={entities}></ListTable>
              </Card.Body>
            </Col>
          ) : (
            ""
          )}
          {idRole === 3 || idRole === 1 || idRole === 4 ? (
            <Col md="12">
              <h4 className="eval-title">
                Evaluations trimestrielles en cours
              </h4>
              <Card.Body>
                <ListTableVadEnCours
                  listEnCours={entitiesEnCours}
                ></ListTableVadEnCours>
              </Card.Body>
            </Col>
          ) : (
            ""
          )}
          <Col md="12">
            <h4 className="eval-title">
              Evaluations trimestrielles approuvées{" "}
            </h4>
            <Card.Body>
              <ListTableValides listValide={entitiesValides}></ListTableValides>
            </Card.Body>
          </Col>
          <Col md="12">
            <h4 className="eval-title">Evaluations trimestrielle annulées</h4>
            <Card.Body>
              <ListTableAnnules listAnnule={entitiesAnnules}></ListTableAnnules>
            </Card.Body>
          </Col>
        </Card>
      </Container>
    </>
  );
}

export default EvaluationSemestrielle;
