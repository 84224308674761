import React, { useEffect, useRef } from "react";
import Select from "react-select";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { fetchReference } from "../../../Redux/referenceReduce";
import { evaluationVadGetById } from "../../../Redux/evaluationVadReduce";
import { Editor } from "@tinymce/tinymce-react";
import { planAdded } from "../../../Redux/planReduce";

function AjouterPlan({ obj }) {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  const [nom, setNom] = React.useState("");
  const [titre, setTitre] = React.useState("");
  const [date, setDate] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [referencesOptions, setReferencesOptions] = React.useState([
    {
      value: "0",
      label: "Référence",
      isDisabled: true,
    },
  ]);
  const [referenceSelect, setReferenceSelect] = React.useState({
    value: "0",
    label: "Référence",
    isDisabled: true,
  });
  const [referenceRequired] = React.useState(true);

  const [avancement, setAvancement] = React.useState({
    value: "0",
    label: "Avancement",
    isDisabled: true,
  });
  const [optionsAvancement] = React.useState([
    {
      value: "0",
      label: "Avancement",
      isDisabled: true,
    },
    {
      value: "1",
      label: "0%",
    },
    {
      value: "2",
      label: "20%",
    },
    {
      value: "3",
      label: "40%",
    },
    {
      value: "4",
      label: "60%",
    },
    {
      value: "5",
      label: "80%",
    },
    {
      value: "6",
      label: "100%",
    },
  ]);

  const [id, setId] = React.useState(0);
  const [testBtn, setTestBtn] = React.useState(false);
  const saveTableCalled = useRef(false);

  var idRep = location.idRep;
  var idUser = obj.user.id;

  function submitForm() {
    if (saveTableCalled.current) return;
    saveTableCalled.current = true;
    var reference = referenceSelect.value;
    var avancementLabel = avancement.label;
    var idVad = location.id;
    if (
      nom !== "" &&
      description !== "" &&
      titre !== "" &&
      date !== "" &&
      reference > 0 &&
      avancementLabel !== "Avancement"
    ) {
      setTestBtn(true);
      dispatch(
        planAdded({
          nom,
          id,
          description,
          titre,
          date,
          reference,
          avancementLabel,
          idVad,
          idRep,
          idUser,
        })
      );
      notify(1, "Insertion avec succès");
      setTimeout(async () => {
        saveTableCalled.current = false;
        navigate.goBack();
      }, 1500);
    } else {
      saveTableCalled.current = false;
      notify(2, "Vérifier vos données");
    }
  }

  async function getReference(p) {
    var entities = [];
    var arrayOption = [];
    var references = await dispatch(fetchReference());
    entities = references.payload;
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.designation });
      if (e.id === p) {
        setReferenceSelect({ value: e.id, label: e.designation });
      }
    });
    setReferencesOptions(arrayOption);
  }

  const getEval = React.useCallback(async () => {
    var res = await dispatch(evaluationVadGetById(location.id));
    var date = res.payload.evaluationn.createdAt.split("T")[0];
    setDate(date);
  }, [dispatch]);

  const handleChange = (newContent) => {
    setDescription(newContent);
  };

  useEffect(() => {
    getReference();
    getEval();
  }, [location.id, dispatch]);

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={(event) => {
                    navigate.goBack();
                  }}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">{"Ajouter Plan"}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Nom * </label>
                            <Form.Control
                              defaultValue={nom}
                              placeholder="Nom "
                              type="text"
                              onChange={(value) => {
                                setNom(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group id="axeClass">
                            <label> Référence * </label>
                            <Select
                              placeholder="Référence"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={referenceSelect}
                              onChange={(value) => {
                                setReferenceSelect(value);
                              }}
                              options={referencesOptions}
                            />
                          </Form.Group>
                          {referenceRequired ? null : (
                            <div className="error">
                              Référence est obligatoire.
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Titre * </label>
                            <Form.Control
                              defaultValue={titre}
                              placeholder="Titre "
                              type="text"
                              onChange={(value) => {
                                setTitre(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group id="axeClass">
                            <label> Avancement * </label>
                            <Select
                              placeholder="Référence"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={avancement}
                              onChange={(value) => {
                                setAvancement(value);
                              }}
                              options={optionsAvancement}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Date limite * </label>
                            <Form.Control
                              defaultValue={date}
                              placeholder="Date "
                              type="Date"
                              onChange={(value) => {
                                setDate(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="" md="12">
                          <Form.Group>
                            <label>Description* </label>
                            <Editor
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                  "advlist",
                                  "autolink",
                                  "lists",
                                  "link",
                                  "image",
                                  "charmap",
                                  "preview",
                                  "anchor",
                                  "searchreplace",
                                  "visualblocks",
                                  "fullscreen",
                                  "insertdatetime",
                                  "media",
                                  "table",
                                  "help",
                                  "wordcount",
                                ],
                                toolbar:
                                  "undo redo | casechange blocks | bold italic backcolor | link image media table | " +
                                  "alignleft aligncenter alignright alignjustify | " +
                                  "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                              }}
                              value={description}
                              onEditorChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          onClick={() => submitForm()}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default AjouterPlan;
