import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");


export const evaluationVadAdded = createAsyncThunk("evaluationVad/addEvaluationVad", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "evaluationVad/addEvaluationVad", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const evaluationVad = await response.json();
    return evaluationVad;
  });

  
export const evaluationVadSended = createAsyncThunk("evaluationVad/sendEvaluationVad", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "evaluationVad/sendEvaluationVad", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(action),
  });
  const evaluationVad = await response.json();
  return evaluationVad;
});


  export const fetchEvaluationVad = createAsyncThunk("evaluationVad/fetchEvaluationVad", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "evaluationVad/allEvaluationVad", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action),
    });
    const evaluationVad = await response.json();
    return evaluationVad;
  });


  export const evaluationVadGetById = createAsyncThunk("evaluationVad/evaluationVadGetById", async (id1) => {
    const  id  = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "evaluationVad/getEvaluationVad", {
      method: 'POST',
      headers: {
        'id':id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const evaluationVad = await response.json();
    return evaluationVad;
  });

  export const deleteEvaluationVad = createAsyncThunk("evaluationVad/deleteEvaluationVad", async (id) => {
    const response = await fetch(Configuration.BACK_BASEURL + "evaluationVad/deleteEvaluationVad/"+id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const evaluationVad = await response.json();
    return evaluationVad;
  });

  
export const vadUpdateEtat = createAsyncThunk("evaluationVad/updateEtat", async (action) => {
  var id = action.id ; 
  const response = await fetch(Configuration.BACK_BASEURL + "evaluationVad/updateEtat/"+id, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action)
  });
  const evaluationVad = await response.json();
  return evaluationVad;
});


export const vadUpdateDate = createAsyncThunk("evaluationVad/updateDate", async (action) => {
  var id = action.id ; 
  const response = await fetch(Configuration.BACK_BASEURL + "evaluationVad/updateDate/"+id, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action)
  });
  const evaluationVad = await response.json();
  return evaluationVad;
});

