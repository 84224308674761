import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");


export const fetchPlanCoachingFlm = createAsyncThunk("planCoachingFlm/fetchPlanCoachingFlm", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "planCoachingFlm/allPlanCoachingFlm", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action),
    });
    const planCoachingFlm = await response.json();
    return planCoachingFlm;
  });
  
  export const planCoachingFlmAdded = createAsyncThunk("planCoachingFlm/addPlanCoachingFlm", async (action) => {
      const response = await fetch(Configuration.BACK_BASEURL + "planCoachingFlm/addPlanCoachingFlm", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(action),
      });
      const planCoachingFlm = await response.json();
      return planCoachingFlm;
    });

    export const planCoachingFlmGetById = createAsyncThunk("planCoachingFlm/planCoachingFlmGetById", async (id1) => {
        const  id  = id1;
        const response = await fetch(Configuration.BACK_BASEURL + "planCoachingFlm/getPlanCoachingFlm", {
          method: 'POST',
          headers: {
            'id':id,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token':token
          },
        
        });
        const planCoachingFlm = await response.json();
        return planCoachingFlm;
      });
      