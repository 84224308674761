import { Button, Card, Container, Row, Col, Carousel } from "react-bootstrap";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { lineGetByIdUser } from "../../../Redux/lineReduce";
import { userGetById } from "../../../Redux/usersReduce";

// core components
function Ims() {
  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();

  var id = location.id;
  const [iframes, setIframes] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const getLine = React.useCallback(async () => {
    var iframesArray = [];
    var line = await dispatch(lineGetByIdUser(id));
    var linee = line.payload.lines;
    var user = await dispatch(userGetById(id));
    setUser(user.payload.header);
    linee.forEach((element) => {
      iframesArray.push(element.lines.iframe);
    });
    setIframes(iframesArray);
  }, [dispatch]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % iframes.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + iframes.length) % iframes.length
    );
  };

  React.useEffect(() => {
    getLine();
  }, [getLine]);

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col md="12">
            <Card>
              <Card.Header className="background-white">
                <Col md="12">
                  <Button
                    id="returnBtn"
                    className=""
                    type="button"
                    variant="info"
                    onClick={(event) => {
                      navigate.goBack();
                    }}
                    style={{ float: "right"}}
                  >
                    <span className="btn-label">
                      <i className="fas fa-list"></i>
                    </span>
                    Retour à la liste
                  </Button>
                </Col>
                <Card.Title className="background-white" as="h3">
                  <div style={{ display: "flex" }}>
                    <strong> Rep : {user.nom}</strong>
                  </div>
                </Card.Title>
                
              </Card.Header>
            </Card>
          </Col>

          <Col md="12">
            <Card>
              <Card.Body>
                <Carousel activeIndex={currentIndex} onSelect={() => {}}>
                  {iframes.map((item, index) => (
                    <Carousel.Item key={index}>
                      <iframe
                        title={`Transition${index + 1}`}
                        width="100%"
                        height="800px"
                        src={`${item}#toolbar=0`}
                      ></iframe>
                    </Carousel.Item>
                  ))}
                </Carousel>
                {iframes.length > 1 ? (
                  <div style={{ textAlign: "center" }}>
                    <Button size="sm" variant="danger" onClick={handlePrev}>
                      <i className="fas fa-chevron-left"></i> Précédent
                    </Button>{" "}
                    <Button size="sm" variant="primary" onClick={handleNext}>
                      Suivant
                      <i className="fas fa-chevron-right"></i>
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Ims;
