import React, { useState, useEffect } from "react";
import { loginFetch } from "../../../Redux/usersReduce";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendMail } from "../../../Redux/sendMailReduce";
import Configuration from "../../../configuration";
import useOfflineStatus from "../../../Offline";
import { useHistory } from "react-router-dom";

// react-bootstrap components
import { Button, Card, Form, Container, Col } from "react-bootstrap";

function LoginPage() {
  const url = Configuration.BACK_BASEURL;
  var stateLine = useOfflineStatus();
  const notifyErr = (msg) =>
    toast.error(
      <strong>
        <i className="fas fa-exclamation-circle"></i>
        {msg}
      </strong>
    );
  const notifySuc = (msg) =>
    toast.success(
      <strong>
        <i className="fas fa-exclamation-circle"></i>
        {msg}
      </strong>
    );
  const settings = useSelector((state) => state.settings.entities);

  if (settings.length > 0) {
    document.title = settings[0].name;
  }
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [cardClasses, setCardClasses] = useState("card-hidden");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });

  function loginChange(event) {
    setLogin(event.target.value);
  }
  function passwordChange(event) {
    setPassword(event.target.value);
  }
  function enterKeyPressed(event) {
    if (event.charCode === 13) {
      submitForm();
      return true;
    } else {
      return false;
    }
  }

  const handleForgotPasswordClick = () => {
    setForgotPassword(true);
  };
  const handleLoginClick = () => {
    setForgotPassword(false);
  };

  const submitForm = (event) => {
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        var root = await dispatch(
          loginFetch({ login: login, password: password })
        );
        var entities = root.payload;
        resolve(entities);
      }, 0);
    });

    promise.then((value) => {
      if (value.message !== true) {
        notifyErr(value.message);
      } else {
        localStorage.setItem("x-access-token", value.token);
        window.location.replace("/dashboard");
      }
    });
  };
  function sendEmail() {
    if (email !== "") {
      dispatch(sendMail({ url, email })).then((e1) => {
        if (e1.payload === false) {
          notifyErr("Vérifier votre email");
        } else {
          setTimeout(async () => {
            notifySuc(
              "Votre message a été bien envoyé. Merci pour votre visite !"
            );
            setForgotPassword(false);
            navigate.push("/login");
          }, 1800);
        }
      });
    } else {
      notifyErr("Vérifier vos données");
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="full-gray section-image" data-color="black">
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Form action="" className="form" method="" onSubmit={submitForm}>
                <Card className={"card-login " + cardClasses}>
                  {settings.length > 0 ? (
                    <img
                      src={require("../../../assets/img/" + settings[0].logo)}
                      alt="medicacom"
                    />
                  ) : (
                    ""
                  )}
                  <Card.Header>
                    <h3 className="header text-center">Coaching Tools</h3>
                    <br></br>
                  </Card.Header>
                  <Card.Body>
                    {!forgotPassword ? (
                      <>
                        <Form.Group className="margin-bottom">
                          <label>Login</label>
                          {/* <button onClick={notify}>Notify!</button> */}
                          <Form.Control
                            onKeyPress={enterKeyPressed}
                            placeholder="Login"
                            type="text"
                            onChange={loginChange}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group className="margin-bottom">
                          <label>Mot de passe</label>
                          <Form.Control
                            placeholder="Password"
                            onKeyPress={enterKeyPressed}
                            onChange={passwordChange}
                            type="password"
                          ></Form.Control>
                        </Form.Group>
                      </>
                    ) : (
                      <Form.Group className="margin-bottom">
                        <label>Email</label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        ></Form.Control>
                      </Form.Group>
                    )}
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <br></br>
                    {!forgotPassword ? (
                      <Button
                        className="btn-wd"
                        type="button"
                        variant="info"
                        onClick={submitForm}
                      >
                        Connexion
                      </Button>
                    ) : (
                      <div>
                        <Button
                          className="btn-wd"
                          type="button"
                          variant="info"
                          onClick={sendEmail}
                          style={{ backgroundColor: "blue", color: "white" }}
                        >
                          Envoyer
                        </Button>
                        <br /> <br />
                        <Button
                          className="btn-wd"
                          type="button"
                          variant="info"
                          onClick={handleLoginClick}
                          style={{ backgroundColor: "red", color: "white" }}
                        >
                          Annuler
                        </Button>
                      </div>
                    )}
                    <br clear="all"></br>
                    <br clear="all"></br>
                    {!stateLine ? (
                      !forgotPassword ? (
                        <Button
                          variant="link"
                          className="btn-oublie"
                          onClick={handleForgotPasswordClick}
                        >
                          <span className="spanLogin">
                            Mot de passe oublié /
                          </span>
                          <br></br>
                          <span className="spanLogin"> nouvelle connexion</span>
                        </Button>
                      ) : null
                    ) : (
                      ""
                    )}
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
