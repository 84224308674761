import React, { useEffect } from "react";
import Select from "react-select";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { grilleAdded, grilleGetById } from "../../../Redux/grilleReduce";
import { fetchAxe } from "../../../Redux/axeReduce";
import SweetAlert from "react-bootstrap-sweetalert";

function AjouterGrille() {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  const [designation, setDesignation] = React.useState("");
  const [alert, setAlert] = React.useState(null);

  // brick
  const [axesOptions, setAxesOptions] = React.useState([
    {
      value: "0",
      label: "Axe",
      isDisabled: true,
    },
  ]);
  const [axeSelect, setAxeSelect] = React.useState({
    value: "0",
    label: "Axe",
    isDisabled: true,
  });
  const [axeRequired] = React.useState(true);
  const [typeRequired] = React.useState(true);

  const [data, setData] = React.useState([]);

  const [optionsKpi] = React.useState([
    {
      value: "",
      label: "KPI",
      isDisabled: true,
    },
    {
      value: "1",
      label: "I",
    },
    {
      value: "2",
      label: "S",
    },
    {
      value: "3",
      label: "E",
    },
    {
      value: "4",
      label: "B",
    },
  ]);

  const [type, setType] = React.useState({
    value: "0",
    label: "Type",
    isDisabled: true,
  });
  const [optionType] = React.useState([
    {
      value: "0",
      label: "Type",
      isDisabled: true,
    },
    {
      value: "1",
      label: "Par choix",
    },
    {
      value: "2",
      label: "Champ texte",
    },
  ]);
  const [testBtn, setTestBtn] = React.useState(false);
  const [id, setId] = React.useState(0);

  function submitForm() {
    var idAxe = axeSelect.value;
    var typeId = type.value;
    var axeClass = document.querySelector("#axeClass .react-select__control");
    axeClass.style.borderColor = "#ccc";
    if (idAxe == 0) {
      axeClass.style.borderColor = "red";
      notify(2, "Choisir un axe");
    }

    var typeClass = document.querySelector("#typeClass .react-select__control");
    typeClass.style.borderColor = "#ccc";
    if (typeId == 0) {
      typeClass.style.borderColor = "red";
      notify(2, "Choisir un type");
    }

    if (designation !== "" && idAxe > 0 && typeId > 0) {
      setTestBtn(true);
      dispatch(grilleAdded({ designation, data, idAxe, typeId, id }));
      if (isNaN(location.id) === true) notify(1, "Insertion avec succès");
      else notify(1, "Modifié avec succès");
      setTimeout(async () => {
        listGrille();
      }, 1500);
    } else {
      notify(2, "Vérifier vos données");
    }
  }

  async function getAxe(p) {
    var entities = [];
    var arrayOption = [];
    var axesss = await dispatch(fetchAxe());
    entities = axesss.payload;
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.designation });
      if (e.id === p) {
        setAxeSelect({ value: e.id, label: e.designation });
      }
    });
    setAxesOptions(arrayOption);
  }

  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (isNaN(location.id) === false) {
          var grille = await dispatch(grilleGetById(location.id));
          var entities = grille.payload.grille;
          setDesignation(entities.designation);
          setData(grille.payload.kpiss);
          setId(location.id);
          resolve(entities);
        } else {
          resolve(0);
        }
      }, 0);
    });

    promise.then((value) => {
      var id_axe = 0;
      var type = 0;

      if (value !== 0) {
        id_axe = value.id_axe;
      }
      if (value !== 0) {
        type = value.type;
      }
      if (type === 1) {
        setType({
          value: "1",
          label: "Par choix",
        });
      } else {
        setType({
          value: "2",
          label: "Champ texte",
        });
      }
      getAxe(id_axe);
    });
  }, [location.id, dispatch]);

  function listGrille() {
    navigate.push("/listGrille");
  }

  function AjoutLigne(event) {
    var list = [];
    if (data.length > 0) list = [...data];
    list[list.length] = {
      id: null,
      kpi: null,
      designation: null,
    };
    setData(list);
  }

  const hideAlert = () => {
    setAlert(null);
  };

  const confirmMessage = (id) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes-vous sûr de supprimer cette ligne?"
        onConfirm={() => deleteLigne(id)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      ></SweetAlert>
    );
  };
  function deleteLigne(id) {
    var list = [...data];
    if (data.length > 1) {
      list.splice(parseInt(id), 1);
      setData(list);
      notify(1, "Ligne supprimée avec succès");
    } else {
      notify(2, "Action impossible à effectuer");
    }
    hideAlert();
  }

  return (
    <>
      {alert}
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={listGrille}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        {typeof location.id == "undefined"
                          ? "Ajouter grille"
                          : "Modifier grille"}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Désignation * </label>
                            <Form.Control
                              defaultValue={designation}
                              placeholder="Désignation "
                              type="text"
                              onChange={(value) => {
                                setDesignation(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col className="pl-1" md="6">
                          <Form.Group id="axeClass">
                            <label> Axe * </label>
                            <Select
                              placeholder="Brick"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={axeSelect}
                              onChange={(value) => {
                                setAxeSelect(value);
                              }}
                              options={axesOptions}
                            />
                          </Form.Group>
                          {axeRequired ? null : (
                            <div className="error">Axe est obligatoire.</div>
                          )}
                        </Col>

                        <Col className="pl-1" md="6">
                          <Form.Group id="typeClass">
                            <label> Type * </label>
                            <Select
                              placeholder="Type"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={type}
                              onChange={(value) => {
                                setType(value);
                              }}
                              options={optionType}
                            />
                          </Form.Group>
                          {typeRequired ? null : (
                            <div className="error">Type est obligatoire.</div>
                          )}
                        </Col>
                      </Row>
                      {type.value == 1 && (
                        <div className="scroll">
                          <table className="table-hover table table-prod">
                            <thead>
                              <tr>
                                <th>Désignation</th>
                                <th>kPI</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>
                                      <Form.Group>
                                        <label>Désignation </label>
                                        <Form.Control
                                          value={val.designation}
                                          placeholder="Désignation"
                                          type="text"
                                          onChange={(value) => {
                                            var list = [...data];
                                            list[key] = {
                                              ...list[key],
                                              designation: value.target.value,
                                            };
                                            setData(list);
                                          }}
                                        ></Form.Control>
                                      </Form.Group>
                                    </td>{" "}
                                    <td>
                                      <Form.Group>
                                        <label>kPI* </label>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={optionsKpi.find(
                                            (option) => option.value === val.kpi
                                          )}
                                          onChange={(value) => {
                                            var list = [...data];
                                            list[key] = {
                                              ...list[key],
                                              kpi: value.value,
                                            };
                                            setData(list);
                                          }}
                                          options={optionsKpi}
                                        />
                                      </Form.Group>
                                    </td>{" "}
                                    <td>
                                      <div className="actions-right block_action">
                                        <Button
                                          onClick={(ev) => {
                                            confirmMessage(key);
                                          }}
                                          variant="danger"
                                          size="sm"
                                          className="text-danger btn-link delete"
                                        >
                                          <i className="fa fa-trash" />
                                        </Button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <Button
                            className="btn-fill pull-left"
                            type="button"
                            variant="info"
                            nom="redac"
                            onClick={(ev) => AjoutLigne()}
                          >
                            Ajouter ligne
                          </Button>
                        </div>
                      )}
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          id="saveAdd"
                          onClick={submitForm}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default AjouterGrille;
