import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import MaterialReactTable from "material-react-table";
import { useHistory } from "react-router";
import { deleteIframe, fetchAllIframe } from "../../../Redux/iframeReduce";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
function ListIframe() {
  const anneeObj = JSON.parse(localStorage.getItem("annee"));
  var annee = anneeObj.label;
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [entities, setEntities] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const columns = useMemo(
    () => [
      //column definitions...
      {
        header: "Nom",
        accessorKey: "nom",
      },
      {
        header: "Line",
        accessorKey: "linebusines.nom",
        Cell: ({ cell }) =>
          cell.row.original.linebusines !== null ? cell.row.original.linebusines.nom : "",
      },
      {
        accessorKey: "id",
        header: "Actions",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              onClick={() => {
                navigate.push("/iframe/update/" + cell.row.original.id);
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-edit" />
            </Button>
            <Button
              onClick={(ev) => {
                confirmMessage(cell.row.original.id);
              }}
              variant="danger"
              size="sm"
              className="text-danger btn-link delete"
            >
              <i className="fa fa-trash" />
            </Button>
          </div>
        ),
      },
      //end
    ],
    []
  );
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  const confirmMessage = (id) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={"Êtes-vous sûr de supprimer cette Iframe ?"}
        onConfirm={() => {
          deleteCons(id);
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      ></SweetAlert>
    );
  };

  function deleteCons(id) {
    dispatch(deleteIframe(id));
    notify(1, "Iframe supprimée avec succès");
    hideAlert();
    setTimeout(async () => {
      window.location.reload();
    }, 1500);
  }
  const hideAlert = () => {
    setAlert(null);
  };

  function ajouter() {
    navigate.push("ajouterIframe");
  }

  const getIframe = useCallback(async () => {
    var lineee = await dispatch(fetchAllIframe({ annee }));
    var res = await lineee.payload;
    setEntities(res);
    setLoader(false);
  }, [dispatch]);

  useEffect(() => {
    getIframe();
  }, []);

  function ListTable({ list }) {
    return (
      <MaterialReactTable
        columns={columns}
        data={list}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }

  return (
    <>
      {alert}
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col md="12">
            <Button
              id="saveAdd"
              className="btn-wd  mr-1 float-left"
              type="button"
              variant="info"
              onClick={ajouter}
            >
              <span className="btn-label">
                <i className="fas fa-plus"></i>
              </span>
              Ajouter une iframe
            </Button>
          </Col>

          <Col md="12">
            <h4 className="title">Liste des iframes</h4>
            <Card className="card-header">
              <Card.Body>
                <ListTable list={entities}></ListTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListIframe;
