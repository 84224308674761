import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  coachFlmGetById,
  coachFlmUpdateEtat,
} from "../../../Redux/coachFlmReduce";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
function DetailCoachDuCoach({ obj }) {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  var idRole = obj.user.id_role;
  var idUser = obj.user.id;

  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [data, setData] = React.useState([]);
  const [flm, setFlm] = React.useState("");
  const [date, setDate] = React.useState("");
  const [score, setScore] = React.useState("");
  const [etat, setEtat] = React.useState(0);
  const [alert, setAlert] = React.useState(null);
  const [testBtn, setTestBtn] = React.useState(false);

  var id = location.id;

  const getCoachDuCoach = useCallback(async () => {
    var coach = await dispatch(coachFlmGetById(id));
    setData(coach.payload.coachArray);
    setFlm(coach.payload.coachFlm.flms.nom);
    setScore(coach.payload.coachFlm.score);
    const date = coach.payload.coachFlm.createdAt.split("T")[0];
    setDate(date);
    setEtat(coach.payload.coachFlm.etat);
  }, [dispatch]);

  const confirmMessage = (id, etat) => {
    localStorage.setItem("commentaire", "");
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={
          etat == 2
            ? "Êtes-vous sûr d'approuver ce coach du coach ?"
            : "Êtes-vous sûr d'annuler ce coach du coach ?"
        }
        onConfirm={() => changeEtat(id, etat)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
        <div>
          <Form.Group className="input-comment">
            <label>Note</label>
            <textarea
              className="form-control"
              onChange={(value) => {
                localStorage.setItem("commentaire", value.target.value);
              }}
              rows="5"
            ></textarea>
          </Form.Group>
        </div>
      </SweetAlert>
    );
  };

  function changeEtat(id, etat) {
    var note = localStorage.getItem("commentaire");
    if (note !== "") {
      setTestBtn(true);
      dispatch(coachFlmUpdateEtat({ id, etat, note, idUser })).then((e1) => {
        getCoachDuCoach();
        switch (etat) {
          case 2:
            notify(1, "Acceptation avec succès");
            hideAlert();
            break;
          case 3:
            notify(1, "Annulation avec succès");
            hideAlert();
            break;
          default:
            break;
        }
      });
    } else {
      notify(2, "Écrire une note");
    }
  }

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    getCoachDuCoach();
  }, [getCoachDuCoach]);

  return (
    <>
      {!testBtn ? alert : ""}
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <>
              <Card>
                <Card>
                  <Card.Header className="background-white">
                    <Col md="12">
                      <Button
                        id="returnBtn"
                        className=""
                        type="button"
                        variant="info"
                        onClick={(event) => {
                          navigate.goBack();
                        }}
                        style={{ float: "right" }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-list"></i>
                        </span>
                        Retour à la liste
                      </Button>
                    </Col>
                    <h3 className="eval-title" style={{ textAlign: "center" }}>
                      Détails Coach Du Coach
                    </h3>
                  </Card.Header>
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong> FLM : {flm}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Card>
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong>{date}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>{" "}
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong> SCORE : {score + " " + "%"}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                {data.map((val, key) => {
                  return (
                    <Col md="12">
                      <Card>
                        <Card.Header className="background-detail">
                          <Card.Title className="title-detail" as="h4">
                            {val.designation}
                          </Card.Title>
                        </Card.Header>
                        <Card.Body>
                          {val.lignes
                            .filter((v) => v.etat === 1)
                            .map((v, k) => {
                              var attr = v.attribut == 1 ? "1" : "0";
                              return (
                                <div key={k}>
                                  <b>{`==> ${attr} - ${v.designation}`}</b>
                                </div>
                              );
                            })}
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
                <hr></hr>

                {idRole === 2 ? (
                  etat === 1 ? (
                    <Row>
                      <Col md="6">
                        <Button
                          onClick={(event) => {
                            confirmMessage(id, 2);
                          }}
                          variant="info"
                          className="accept-btn"
                          size="md"
                        >
                          Approuver
                          <i className={"fa fa-check"} />
                        </Button>
                      </Col>
                      <Col md="6">
                        <Button
                          onClick={(event) => {
                            confirmMessage(id, 3);
                          }}
                          variant="danger"
                          size="md"
                        >
                          Annuler
                          <i className={"fa fa-times"} />
                        </Button>
                      </Col>
                    </Row>
                  ) : etat == 2 ? (
                    <div className="etat-vad">
                      <b> Coach Du Coach lu et approuvée </b>
                    </div>
                  ) : (
                    <div className="etat-vad">
                      <b> Coach Du Coach annulée</b>
                    </div>
                  )
                ) : (
                  ""
                )}
              </Card>
            </>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default DetailCoachDuCoach;
