import React, { useEffect } from "react";
import Select from "react-select";
import validator from "validator";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import {
  fetchUsers,
  getUsersByIdRole,
  userAdded,
  userGetById,
} from "../../../Redux/usersReduce";
import { fetchRole } from "../../../Redux/roleReduce";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { fetchLine } from "../../../Redux/lineReduce";
import { fetchLineBusiness } from "../../../Redux/lineBusinessReduce";
import { fetchBrick } from "../../../Redux/brickReduce";

function AjouterUser({ obj }) {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const navigate = useHistory();
  const location = useParams();
  //input
  const [nom, setNom] = React.useState("");
  const [tel, setTel] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [login, setLogin] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [codeTerritoire, setCodeTerritoire] = React.useState("");
  const [codeTerritoireArray, setCodeTerritoireArray] = React.useState([]);
  const [panelPublic, setPanelPublic] = React.useState("");
  const [panelPrive, setPanelPrive] = React.useState("");
  const [panelOfficine, setPanelOfficine] = React.useState("");
  const [idFfm, setIdffm] = React.useState(0);
  const [role, setRole] = React.useState(0);
  const [id, setId] = React.useState(0);
  //required
  const [nomRequired] = React.useState(true);
  const [emailRequired] = React.useState(true);
  const [loginRequired] = React.useState(true);
  const [passwordRequired] = React.useState(true);
  const [roleRequired] = React.useState(true);
  const [userRequired] = React.useState(true);
  const etat = 1;

  const [options, setOptions] = React.useState([
    {
      value: "",
      label: "Role",
      isDisabled: true,
    },
  ]);
  const [roleSelect, setRoleSelect] = React.useState({
    value: 0,
    label: "Role",
  });

  const [optionsUsers, setOptionsUsers] = React.useState([
    {
      value: "",
      label: "FFM",
      isDisabled: true,
    },
  ]);
  const [userSelect, setUserSelect] = React.useState({
    value: 0,
    label: "FFM",
  });

  const [lineOptions, setLineOptions] = React.useState([
    {
      value: "",
      label: "Line Ims",
      isDisabled: true,
    },
  ]);
  const [lines, setLines] = React.useState([]);

  const [lineBusinessOptions, setLineBusinessOptions] = React.useState([
    {
      value: "",
      label: "Line",
      isDisabled: true,
    },
  ]);
  const [linesBusiness, setLinesBusiness] = React.useState([]);

  const [bricksOptions, setBricksOptions] = React.useState([
    {
      value: "",
      label: "Brick",
      isDisabled: true,
    },
  ]);
  const [bricks, setBricks] = React.useState([]);

  const [testBtn, setTestBtn] = React.useState(false);

  async function submitForm(event) {
    var userId = userSelect.value;
    var ffm = userSelect.label;
    var roleLabel = roleSelect.label;

    var required = document.getElementsByClassName("required");
    var testPassword = true;
    for (var i = 0; i < required.length + 1; i++) {
      if (required[i] !== undefined) {
        document.getElementsByClassName("error")[i].innerHTML = "";
        required[i].style.borderColor = "#ccc";
        //condition required
        if (
          validator.isEmpty(required[i].value) &&
          required[i].name !== "Password"
        ) {
          required[i].style.borderColor = "red";
          document.getElementsByClassName("error")[i].innerHTML =
            required[i].name + " est obligatoire";
          notify(2, required[i].name + " doit etre non vide");
        }
        //condition email
        else if (
          required[i].name === "Email" &&
          !validator.isEmail(required[i].value)
        ) {
          notify(2, "E-mail invalide");
          document.getElementsByClassName("error")[i].innerHTML =
            "E-mail invalide";
        }
        //condition password
        else if (
          (required[i].name === "Password" && isNaN(location.id) === true) ||
          (required[i].name === "Password" &&
            !validator.isEmpty(required[i].value) &&
            isNaN(location.id) === false)
        ) {
          if (!validator.isLength(required[i].value, { min: 6, max: 20 })) {
            testPassword = false;
            notify(2, "Password doit etre minimum 6 charactére");
            document.getElementsByClassName("error")[i].innerHTML =
              "Password doit etre minimum 6 charactére";
          }
        }
      }
    }
    var roleClass = document.querySelector("#roleClass .react-select__control");
    roleClass.style.borderColor = "#ccc";
    if (role === 0) {
      roleClass.style.borderColor = "red";
      notify(2, "Choisir un role");
    }

    var userClass = document.querySelector("#userClass .react-select__control");
    if (userClass !== null) {
      userClass.style.borderColor = "#ccc";
      if (role == 2 && userId == 0) {
        userClass.style.borderColor = "red";
        notify(2, "Choisir un FFM");
      }
    }
    var testLine = true;
    var testLineBusiness = true;

    if (role !== 1) {
      testLine = !lines.some((line) => line.label == "No Line");
      testLineBusiness = !linesBusiness.some(
        (line) => line.label == "No Line Business"
      );
    }

    var lineClass = document.querySelector("#lineClass .react-select__control");
    lineClass.style.borderColor = "#ccc";

    var lineBusClass = document.querySelector(
      "#lineBusClass .react-select__control"
    );
    lineBusClass.style.borderColor = "#ccc";

    if (!testLine || lines.length == 0) {
      lineClass.style.borderColor = "red";
      notify(2, "Choisir Line Ims");
    }
    if (!testLineBusiness || linesBusiness.length == 0) {
      lineBusClass.style.borderColor = "red";
      notify(2, "Choisir Line Business");
    }

    console.log(userId);

    if (
      !validator.isEmpty(nom) &&
      validator.isEmail(email) &&
      !validator.isEmpty(login) &&
      testPassword === true &&
      codeTerritoire !== "" &&
      lines.length > 0 &&
      linesBusiness.length > 0 &&
      role > 0 &&
      ((role !== 2 && userId == 0) || (role == 2 && userId > 0))
    ) {
      if (codeTerritoireArray.includes(codeTerritoire) == false) {
        setTestBtn(true);
        dispatch(
          userAdded({
            nom,
            email,
            tel,
            login,
            password,
            lines,
            linesBusiness,
            codeTerritoire,
            panelPrive,
            panelOfficine,
            panelPublic,
            id,
            etat,
            role,
            roleLabel,
            userId,
            ffm,
            bricks,
          })
        ).then((data) => {
          if (data.payload === true) {
            if (isNaN(location.id) === true) notify(1, "Insertion avec succès");
            else notify(1, "Modifié avec succès");
            setTimeout(async () => {
              listeUser();
            }, 1500);
          } else {
            notify(2, "Problème de connexion");
          }
        });
      } else {
        notify(2, "Code déja exsite");
      }
    } else {
      notify(2, "Vérifier vos données");
    }
  }

  async function getRole(p) {
    var role = await dispatch(fetchRole());
    var entities = role.payload;
    var arrayOption = [];
    arrayOption.push({ value: 0, label: "Role" });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      if (e.id === p) {
        setRoleSelect({ value: e.id, label: e.nom });
      }
    });
    setOptions(arrayOption);
  }

  async function getLine(p) {
    var arrayOption = [];
    var linee = await dispatch(fetchLine());
    var entities = linee.payload;
    arrayOption.push({ value: 0, label: "Line", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
    });
    setLineOptions(arrayOption);
  }

  async function getLineBusiness(p) {
    var arrayOption = [];
    var linee = await dispatch(fetchLineBusiness());
    var entities = linee.payload;
    arrayOption.push({ value: 0, label: "Line Business", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
    });
    setLineBusinessOptions(arrayOption);
  }

  async function getBricks(p) {
    var arrayOption = [];
    var bricks = await dispatch(fetchBrick());
    var entities = bricks.payload;
    arrayOption.push({ value: 0, label: "Brick", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
    });
    setBricksOptions(arrayOption);
  }

  async function getUsersByRole(idRole, id) {
    var arrayOption = [];
    var users = await dispatch(getUsersByIdRole({ idRole }));
    var entities = users.payload;
    arrayOption.push({ value: 0, label: "FFM", isDisabled: true });
    entities.forEach((e) => {
      if (id == e.id) {
        setUserSelect({ value: e.id, label: e.nom });
      }
      arrayOption.push({ value: e.id, label: e.nom });
    });
    setOptionsUsers(arrayOption);
  }

  async function getUsers() {
    var codesArray = [];
    var users = await dispatch(fetchUsers());
    users.payload.users.forEach((e) => {
      codesArray.push(e.codeTerritoire);
    });
    setCodeTerritoireArray(codesArray);
  }

  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (isNaN(location.id) === false) {
          var user = await dispatch(userGetById(location.id));
          var entities = user.payload.header;
          setLines(user.payload.arrayOption);
          setLinesBusiness(user.payload.arrayLinesOption);
          setBricks(user.payload.arrayBrickOption);
          setNom(entities.nom);
          setEmail(entities.email);
          setLogin(entities.login);
          setTel(entities.tel);
          setRole(entities.id_role);
          setCodeTerritoire(entities.codeTerritoire);
          setPanelPublic(entities.panelPublic);
          setPanelPrive(entities.panelPrive);
          setPanelOfficine(entities.panelOfficine);
          setId(location.id);
          getUsersByRole(entities.id_role, entities.id_ffm);
          setIdffm(entities.id_ffm);
          resolve(entities);
        } else {
          getUsers();
          resolve(0);
        }
      }, 0);
    });

    promise.then((value) => {
      var roles = 0;
      if (value !== 0) {
        roles = value.id_role;
      }
      getRole(roles);
    });
    getLine();
    getLineBusiness();
    getBricks();
  }, [location.id, dispatch]);

  function listeUser() {
    navigate.push("/utilisateurListe");
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={listeUser}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        {typeof location.id == "undefined"
                          ? "Ajouter utilisateur"
                          : "Modifier utilisateur"}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Code territoire </label>
                            <Form.Control
                              defaultValue={codeTerritoire}
                              placeholder="Code territoire"
                              type="text"
                              onChange={(value) => {
                                setCodeTerritoire(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Nom et Prenom* </label>
                            <Form.Control
                              defaultValue={nom}
                              placeholder="Nom"
                              name="Nom"
                              className="required"
                              type="text"
                              onChange={(value) => {
                                setNom(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                          <div className="error"></div>
                          {nomRequired ? null : (
                            <label className="error">
                              Nom est obligatoire.
                            </label>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Login* </label>
                            <Form.Control
                              defaultValue={login}
                              placeholder="Login"
                              className="required"
                              name="Login"
                              type="text"
                              onChange={(value) => {
                                setLogin(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                          <div className="error"></div>
                          {loginRequired ? null : (
                            <label className="error">
                              Login est obligatoire.
                            </label>
                          )}
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Password* </label>
                            <Form.Control
                              defaultValue={password}
                              placeholder="Password"
                              className="required"
                              name="Password"
                              type="password"
                              onChange={(value) => {
                                setPassword(value.target.value);
                              }}
                            ></Form.Control>
                            <div className="error"></div>
                            {passwordRequired ? null : (
                              <label className="error">
                                Password est obligatoire.
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>E-mail* </label>
                            <Form.Control
                              defaultValue={email}
                              placeholder="E-mail"
                              name="Email"
                              className="required"
                              type="text"
                              onChange={(value) => {
                                setEmail(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                          <div className="error"></div>
                          {emailRequired ? null : (
                            <label className="error">
                              Email est obligatoire.
                            </label>
                          )}
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group id="roleClass">
                            <label>Role* </label>
                            <Select
                              placeholder="Role"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={roleSelect}
                              onChange={(value) => {
                                setRoleSelect(value);
                                setRole(value.value);
                                if (value.value == 2) {
                                  getUsersByRole(value.value, idFfm);
                                } else {
                                  setUserSelect({
                                    value: 0,
                                    label: "FFM",
                                  });
                                }
                              }}
                              options={options}
                            />
                          </Form.Group>
                          {roleRequired ? null : (
                            <div className="error">Role est obligatoire.</div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Téléphone </label>
                            <Form.Control
                              defaultValue={tel}
                              placeholder="Téléphone"
                              type="number"
                              onChange={(value) => {
                                setTel(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        {roleSelect.value == 2 ? (
                          <Col className="pl-1" md="6">
                            <Form.Group id="userClass">
                              <label>FFM </label>
                              <Select
                                placeholder="Utilisateur"
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={userSelect}
                                onChange={(value) => {
                                  setUserSelect(value);
                                }}
                                options={optionsUsers}
                              />
                            </Form.Group>
                            {userRequired ? null : (
                              <div className="error">FFM est obligatoire.</div>
                            )}
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="lineClass">
                            <label>Line Ims* </label>
                            <Select
                              isMulti
                              placeholder="Line Ims"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={lines}
                              onChange={(value) => {
                                setLines(value);
                              }}
                              options={lineOptions}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group id="lineBusClass">
                            <label>Line* </label>
                            <Select
                              isMulti
                              placeholder="Line"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={linesBusiness}
                              onChange={(value) => {
                                setLinesBusiness(value);
                              }}
                              options={lineBusinessOptions}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Panel Public </label>
                            <Form.Control
                              defaultValue={panelPublic}
                              placeholder="Panel Public"
                              type="text"
                              onChange={(value) => {
                                setPanelPublic(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Panel privé </label>
                            <Form.Control
                              defaultValue={panelPrive}
                              placeholder="Panel privé"
                              type="text"
                              onChange={(value) => {
                                setPanelPrive(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Panel officine </label>
                            <Form.Control
                              defaultValue={panelOfficine}
                              placeholder="Panel officine"
                              type="text"
                              onChange={(value) => {
                                setPanelOfficine(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        {/* <Col className="pl-1" md="6">
                          <Form.Group id="brickClass">
                            <label>Brick* </label>
                            <Select
                              isMulti
                              placeholder="Brick"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={bricks}
                              onChange={(value) => {
                                setBricks(value);
                              }}
                              options={bricksOptions}
                            />
                          </Form.Group>
                        </Col> */}
                      </Row>
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          id="saveAdd"
                          onClick={submitForm}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default AjouterUser;
