import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  evaluationVadGetById,
  vadUpdateEtat,
} from "../../../Redux/evaluationVadReduce";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
function DetailEvalVad({ obj }) {
  var idRole = obj.user.id_role;
  var idUser = obj.user.id;

  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [data, setData] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [date, setDate] = React.useState("");
  const [commentaire, setCommentaire] = React.useState("");
  const [nbVisits, setNbVisits] = React.useState(0);
  const [max, setMax] = React.useState(0);
  const [etat, setEtat] = React.useState(0);
  const [alert, setAlert] = React.useState(null);
  const [testBtn, setTestBtn] = React.useState(false);

  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  var id = location.id;

  const getEval = useCallback(async () => {
    var vadd = await dispatch(evaluationVadGetById(id));
    setData(vadd.payload.vadsAarray);
    var lignes = vadd.payload.lignes;
    var nbs = 0;
    lignes.forEach((ligne) => {
      if (ligne.checks[0] !== "") nbs = nbs + ligne.checks.length;
    });
    setNbVisits(nbs);
    setUser(vadd.payload.evaluationn.reps);
    setDate(vadd.payload.evaluationn.createdAt.split("T")[0]);
    setCommentaire(vadd.payload.evaluationn.commentaire);
    setMax(vadd.payload.evaluationn.max_nbvisit);
    setEtat(vadd.payload.evaluationn.etat);
  }, [dispatch]);

  const scrollTable = {
    overflowX: "auto",
  };

  const confirmMessage = (id, etat) => {
    localStorage.setItem("commentaire", "");
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={
          etat == 2
            ? "Êtes-vous sûr d'approuver cette Evaluation VAD ?"
            : "Êtes-vous sûr d'annuler cette Evaluation VAD"
        }
        onConfirm={() => changeEtat(id, etat)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
        <div>
          <Form.Group className="input-comment">
            <label>Note</label>
            <textarea
              className="form-control"
              onChange={(value) => {
                localStorage.setItem("commentaire", value.target.value);
              }}
              rows="5"
            ></textarea>
          </Form.Group>
        </div>
      </SweetAlert>
    );
  };

  function changeEtat(id, etat) {
    var note = localStorage.getItem("commentaire");
    if (note !== "") {
      setTestBtn(true);
      dispatch(vadUpdateEtat({ id, etat, note, idUser })).then((e1) => {
        getEval();
        switch (etat) {
          case 2:
            notify(1, "Acceptation avec succès");
            hideAlert();
            break;
          case 3:
            notify(1, "Annulation avec succès");
            hideAlert();
            break;
          default:
            break;
        }
      });
    } else {
      notify(2, "Écrire une note");
    }
  }

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    getEval();
  }, [getEval]);

  return (
    <>
      {!testBtn ? alert : ""}
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <>
              <Card>
                <Card>
                  <Card.Header className="background-white">
                    <Col md="12">
                      <Button
                        id="returnBtn"
                        className=""
                        type="button"
                        variant="info"
                        onClick={(event) => {
                          navigate.goBack();
                        }}
                        style={{ float: "right" }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-list"></i>
                        </span>
                        Retour à la liste
                      </Button>
                    </Col>
                    <h3 className="eval-title" style={{ textAlign: "center" }}>
                      Détails Evaluation VAD
                    </h3>
                  </Card.Header>
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong> Rep : {user.nom}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>{" "}
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong> {date}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Col md="18">
                  <table className="table-hover table table-prod">
                    <thead>
                      <tr>
                        <th style={{ width: "15%" }}>
                          NOMBRE DE VISITES OBSERVÉES
                        </th>
                        <th style={{ width: "5%" }}>{max}</th>
                        <th>
                          {nbVisits > 0
                            ? ((nbVisits / max) * 5).toFixed(2)
                            : "0"}
                        </th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                  <Card>
                    {data.map((e, key) => {
                      return (
                        <div key={key} style={scrollTable}>
                          <table className="table-hover table table-prod">
                            <thead>
                              <tr>
                                <th>{e.vad.designation}</th>
                                {/* <th>Total</th> */}
                                <th>%</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                                <th>7</th>
                                <th>8</th>
                                <th>9</th>
                                <th>10</th>
                                <th>11</th>
                                <th>12</th>
                                <th>13</th>
                                <th>14</th>
                                <th>15</th>
                                <th>TOTAL</th>
                              </tr>
                            </thead>
                            <tbody>
                              {e.lignes.map((v, rowIndex) => {
                                var checkTab = v.checks.map(Number);
                                return (
                                  <tr key={rowIndex}>
                                    <td>{v.designation}</td>
                                    <td>
                                      {v.pourcentage_visit === undefined
                                        ? 0 + "" + "%"
                                        : v.pourcentage_visit + "" + "%"}
                                    </td>
                                    {[...Array(15).keys()].map((e, k) => (
                                      <td key={k}>
                                        <Form.Check className="form-check-box">
                                          <Form.Check.Label>
                                            <Form.Check.Input
                                              checked={checkTab.includes(k + 1)}
                                              defaultValue="1"
                                              type="checkbox"
                                              disabled
                                            ></Form.Check.Input>
                                            <span className="form-check-sign"></span>{" "}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      </td>
                                    ))}
                                    <td>
                                      {v.total_visit == undefined ||
                                      v.total_visit == ""
                                        ? 0
                                        : v.total_visit}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                    {}
                    <table className="table-hover table table-prod">
                      <thead>
                        <tr>
                          <th colSpan="14">Commentaire</th>
                          <th>{nbVisits}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={"20"}>
                            {commentaire == ""
                              ? "Aucun commentaire"
                              : commentaire}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </Col>
                {idRole === 3 ? (
                  etat === 1 ? (
                    <Row>
                      <Col md="6">
                        <Button
                          onClick={(event) => {
                            confirmMessage(id, 2);
                          }}
                          variant="info"
                          className="accept-btn"
                          size="md"
                        >
                          Approuver
                          <i className={"fa fa-check"} />
                        </Button>
                      </Col>
                      <Col md="6">
                        <Button
                          onClick={(event) => {
                            confirmMessage(id, 3);
                          }}
                          variant="danger"
                          size="md"
                        >
                          Annuler
                          <i className={"fa fa-times"} />
                        </Button>
                      </Col>
                    </Row>
                  ) : etat == 2 ? (
                    <div className="etat-vad">
                      <b> Evaluation VAD lu et approuvée </b>
                    </div>
                  ) : (
                    <div className="etat-vad">
                      <b> Evaluation VAD annulée</b>
                    </div>
                  )
                ) : (
                  ""
                )}
              </Card>
            </>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default DetailEvalVad;
