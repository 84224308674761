import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getGrilles } from "../../../Redux/grilleReduce";
import {
  evaluationAdded,
  evaluationGetById,
  evaluationSended,
} from "../../../Redux/evaluationReduce";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
function DetailEvaluation({ obj }) {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [data, setData] = React.useState({});
  const [user, setUser] = React.useState({});
  const [scoreObject, setScoreObject] = React.useState({});
  const [alert, setAlert] = React.useState(null);
  const [id, setId] = React.useState(0);
  const [idRepp, setIdRepp] = React.useState(0);
  const [testBtn, setTestBtn] = React.useState(false);
  const saveTableCalled = useRef(false);

  var idRep = location.idRep;
  var idUser = obj.user.id;

  const dayyy = new Date();

  const jour = dayyy.getDate();
  const mois = dayyy.getMonth() + 1;
  const annee = dayyy.getFullYear();

  const insertDate = `${mois}/${jour}/${annee}`;

  const getGrille = useCallback(async () => {
    var grilles = await dispatch(getGrilles({ idRep }));
    setData(grilles.payload.grillesObject);
    setUser(grilles.payload.userr);
    setScoreObject(grilles.payload.scoreObject);
  }, [dispatch]);

  const handleRadioClick = (idAxe, grilleIndex, kpiIndex, scoreCon) => {
    var newData = JSON.parse(JSON.stringify(data));

    newData[idAxe][grilleIndex].kpis.forEach((kpi) => {
      kpi.etat = 0;
    });
    newData[idAxe][grilleIndex].kpis[kpiIndex].etat = 1;

    var arrayNb = [];
    newData[idAxe].forEach((element) => {
      var kpi = element.kpis.find((kpi) => kpi.etat === 1);
      if (kpi) {
        arrayNb.push(kpi.kpi);
      }
    });

    var sum = 0;
    for (var i = 0; i < arrayNb.length; i++) {
      sum += arrayNb[i];
    }
    var score = ((sum * 100) / 15).toFixed(2);

    newData[2][0].grille.score = scoreCon;
    if (idAxe !== 4) {
      for (let index = 0; index < newData[idAxe].length; index++) {
        newData[idAxe][index].grille.score = score;
      }
    }

    setData(newData);
  };

  const handleChange = (idAxe, grilleIndex, score) => {
    var newData = JSON.parse(JSON.stringify(data));
    newData[idAxe][grilleIndex].grille.score = score;
    setData(newData);
  };

  const handleRadioClick2 = (idAxe, grilleIndex, k, scoreCon) => {
    var newData = JSON.parse(JSON.stringify(data));
    if (k === 0) {
      var objet1 = { etat: 1, designation: "", kpi: "1" };
      var objet2 = { etat: 0, designation: "", kpi: "2" };
      var objet3 = { etat: 0, designation: "", kpi: "3" };
    } else if (k === 1) {
      var objet1 = { etat: 0, designation: "", kpi: "1" };
      var objet2 = { etat: 1, designation: "", kpi: "2" };
      var objet3 = { etat: 0, designation: "", kpi: "3" };
    } else {
      var objet1 = { etat: 0, designation: "", kpi: "1" };
      var objet2 = { etat: 0, designation: "", kpi: "2" };
      var objet3 = { etat: 1, designation: "", kpi: "3" };
    }
    newData[idAxe][grilleIndex].kpis.push(objet1);
    newData[idAxe][grilleIndex].kpis.push(objet2);
    newData[idAxe][grilleIndex].kpis.push(objet3);

    var arrayNb = [];
    newData[idAxe].forEach((element) => {
      var kpi = element.kpis.find((kpi) => kpi.etat === 1);
      if (kpi) {
        arrayNb.push(kpi.kpi);
      }
    });
    var sum = 0;
    for (var i = 0; i < arrayNb.length; i++) {
      sum += arrayNb[i];
    }
    var score = ((sum * 100) / 15).toFixed(2);

    newData[2][0].grille.score = scoreCon;
    if (idAxe !== 4) {
      for (let index = 0; index < newData[idAxe].length; index++) {
        newData[idAxe][index].grille.score = score;
      }
    }
    setData(newData);
  };

  const addNote = (idAxe, grilleIndex, note) => {
    var newData = JSON.parse(JSON.stringify(data));
    newData[idAxe][grilleIndex].grille.remarque = note;
    setData(newData);
  };

  function submitForm() {
    if (saveTableCalled.current) return;
    saveTableCalled.current = true;

    var trimestre1 = ["01", "02", "03", "04"];
    var trimestre2 = ["05", "06", "07", "08"];
    var trimestre3 = ["09", "10", "11", "12"];

    var date = new Date();
    var mois = String(date.getMonth() + 1).padStart(2, "0");

    if (trimestre1.includes(mois.toString())) {
      var trimestre = 1;
    }

    if (trimestre2.includes(mois.toString())) {
      var trimestre = 2;
    }
    if (trimestre3.includes(mois.toString())) {
      var trimestre = 3;
    }
    setTestBtn(true);
    dispatch(evaluationAdded({ data, id, idRep, idUser, trimestre }));
    if (isNaN(location.id) === true) notify(1, "Insertion avec succès");
    else notify(1, "Modifié avec succès");
    setTimeout(async () => {
      saveTableCalled.current = false;
      navigate.goBack();
    }, 1500);
  }

  function sendEval() {
    if (saveTableCalled.current) return;
    saveTableCalled.current = true;
    var note = localStorage.getItem("commentaire");

    var trimestre1 = ["01", "02", "03", "04"];
    var trimestre2 = ["05", "06", "07", "08"];
    var trimestre3 = ["09", "10", "11", "12"];

    var date = new Date();
    var mois = String(date.getMonth() + 1).padStart(2, "0");

    if (trimestre1.includes(mois.toString())) {
      var trimestre = 1;
    }

    if (trimestre2.includes(mois.toString())) {
      var trimestre = 2;
    }
    if (trimestre3.includes(mois.toString())) {
      var trimestre = 3;
    }

    var arrayData = Object.values(data);
    var evaluationData = arrayData.flat();
    var arrayKpis = [];
    var testScore = true;
    var testNote = false;
    evaluationData.forEach((element) => {
      if (
        (element.grille.id_axe == 4 && element.grille.score === undefined) ||
        element.grille.score === null
      ) {
        testScore = false;
      }
      if (
        element.grille.type === 2 &&
        (element.grille.remarque === undefined ||
          element.grille.remarque === null ||
          element.grille.remarque === "")
      ) {
        testNote = false;
      }
      var kpiObj = element.kpis.find((kpi) => kpi.etat === 1);
      if (kpiObj) {
        arrayKpis.push(1);
      } else {
        arrayKpis.push(0);
      }
    });

    if (!testScore) {
      notify(2, "Écrire un score pour les connaissances professionnellesx ");
    }

    if (arrayKpis.includes(0) === false && testScore) {
      if (note !== "") {
        setTestBtn(true);
        dispatch(
          evaluationSended({ data, id, idRep, idUser, idRepp, trimestre, note })
        );
        notify(1, "Envoie avec succès");
        saveTableCalled.current = false;
        setTimeout(async () => {
          navigate.goBack();
        }, 1500);
      } else {
        saveTableCalled.current = false;
        notify(2, "Écrire une note");
      }
    } else {
      saveTableCalled.current = false;
      notify(2, "Vérifier vos données");
    }
  }
  const confirmMessage = () => {
    localStorage.setItem("commentaire", "");
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={"Êtes-vous sûr d'envoyer cette evaluation trimestrielle ? "}
        onConfirm={() => sendEval()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
        <div>
          <Form.Group className="input-comment">
            <label>Note</label>
            <textarea
              className="form-control"
              onChange={(value) => {
                localStorage.setItem("commentaire", value.target.value);
              }}
              rows="5"
            ></textarea>
          </Form.Group>
        </div>
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (isNaN(location.id) === false) {
          var evaluation = await dispatch(evaluationGetById(location.id));
          setData(evaluation.payload.grillesObject);
          setUser(evaluation.payload.userr);
          setIdRepp(evaluation.payload.userr.id);
          setScoreObject(evaluation.payload.scoreObject);
          setId(location.id);
        } else {
          resolve(0);
          getGrille();
        }
      }, 0);
    });
    promise.then((value) => {});
  }, [location.id, dispatch]);

  return (
    <>
      {!testBtn ? alert : ""}
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <>
              <Card>
                <Card>
                  <Card.Header className="background-white">
                    <Col md="12">
                      <Button
                        id="returnBtn"
                        className=""
                        type="button"
                        variant="info"
                        onClick={(event) => {
                          navigate.goBack();
                        }}
                        style={{ float: "right" }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-list"></i>
                        </span>
                        Retour à la liste
                      </Button>
                    </Col>
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong> Rep : {user.nom}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Card>
                  <Card.Header className="background-white">
                    <Card.Title className="background-white" as="h3">
                      <div style={{ display: "flex" }}>
                        <strong>{insertDate}</strong>
                      </div>
                    </Card.Title>
                  </Card.Header>
                </Card>
                <Col md="18">
                  {Object.entries(data).map(([key, val]) => {
                    var sommePourcentage = `Somme des pourcentages : ${scoreObject.sommePourcentage}`;
                    var nbVads = `Nombre des evaluations VAD : ${scoreObject.nbVads}`;
                    var scoree =
                      parseFloat(scoreObject.sommePourcentage) /
                      parseInt(scoreObject.nbVads);
                    return (
                      <Col md="12">
                        <Card>
                          <Card.Header>
                            <Card.Title as="h4">
                              <strong>{val[0].grille.axes.designation}</strong>
                              <strong style={{ marginLeft: "20px" }}>
                                SCORE :
                                {val[0].grille.axes.id !== 2 ? (
                                  <b style={{ marginLeft: "5px" }}>
                                    {val[0].grille.score === undefined
                                      ? 0
                                      : val[0].grille.score}
                                  </b>
                                ) : (
                                  <b>{scoree}</b>
                                )}
                              </strong>
                            </Card.Title>
                          </Card.Header>
                          <Card.Body>
                            {val.map((v, k) => {
                              return (
                                <div key={k}>
                                  <b>
                                    {isNaN(location.id) === false
                                      ? v.grille.grilles.designation
                                      : v.grille.designation}
                                  </b>
                                  <br clear="all"></br>
                                  {v.grille.axes.id == 2 ? (
                                    <div>
                                      <p>{sommePourcentage}</p>
                                      <p>{nbVads}</p>
                                    </div>
                                  ) : v.grille.axes.id == 4 ? (
                                    <Row>
                                      <Col className="pr-1" md="6">
                                        <Form.Group>
                                          <Form.Control
                                            defaultValue={val[0].grille.score}
                                            placeholder="Score"
                                            type="number"
                                            onChange={(value) =>
                                              handleChange(
                                                v.grille.id_axe,
                                                k,
                                                value.target.value
                                              )
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}
                                  <br clear="all"></br>
                                  {v.kpis.length > 0 ? (
                                    v.kpis.map((element, index) => {
                                      if (element.designation === "") {
                                        var designation = "";
                                      } else {
                                        var designation = `:${element.designation}`;
                                      }
                                      return (
                                        <div key={index}>
                                          <Form.Check className="form-check-radio">
                                            <Form.Check.Label>
                                              <Form.Check.Input
                                                checked={
                                                  parseInt(element.etat) === 1
                                                    ? true
                                                    : false
                                                }
                                                disabled={v.grille.id_axe === 2}
                                                defaultValue="1"
                                                type="radio"
                                                onClick={() =>
                                                  handleRadioClick(
                                                    v.grille.id_axe,
                                                    k,
                                                    index,
                                                    scoree
                                                  )
                                                }
                                              ></Form.Check.Input>
                                              <span className="form-check-sign"></span>
                                              {element.kpi == 1
                                                ? "I" + designation
                                                : element.kpi == 2
                                                ? "S" + designation
                                                : "E" + designation}
                                            </Form.Check.Label>
                                          </Form.Check>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div>
                                      <Form.Check className="form-check-radio">
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            checked={false}
                                            defaultValue="1"
                                            type="radio"
                                            onClick={() =>
                                              handleRadioClick2(
                                                v.grille.id_axe,
                                                k,
                                                0,
                                                scoree
                                              )
                                            }
                                          ></Form.Check.Input>
                                          <span className="form-check-sign">
                                            I
                                          </span>
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check className="form-check-radio">
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            checked={false}
                                            defaultValue="1"
                                            type="radio"
                                            onClick={() =>
                                              handleRadioClick2(
                                                v.grille.id_axe,
                                                k,
                                                1,
                                                scoree
                                              )
                                            }
                                          ></Form.Check.Input>
                                          <span className="form-check-sign">
                                            S
                                          </span>
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check className="form-check-radio">
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            checked={false}
                                            defaultValue="1"
                                            type="radio"
                                            onClick={() =>
                                              handleRadioClick2(
                                                v.grille.id_axe,
                                                k,
                                                2,
                                                scoree
                                              )
                                            }
                                          ></Form.Check.Input>
                                          <span className="form-check-sign">
                                            E
                                          </span>
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </div>
                                  )}
                                  <Form.Group className="input-comment">
                                    <label>Remarque</label>
                                    <textarea
                                      onChange={(value) => {
                                        addNote(
                                          v.grille.id_axe,
                                          k,
                                          value.target.value
                                        );
                                      }}
                                      value={v.grille.remarque}
                                      className="form-control text-area"
                                      id={`noteClass${v.grille.type}`}
                                      rows="5"
                                    ></textarea>
                                  </Form.Group>
                                  <br clear="all"></br>
                                </div>
                              );
                            })}
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                  <br clear="all"></br>
                  {!testBtn ? (
                    <Button
                      className="btn-fill pull-right"
                      type="button"
                      variant="info"
                      onClick={submitForm}
                    >
                      Enregistrer
                    </Button>
                  ) : (
                    ""
                  )}
                  <br clear="all"></br> <br clear="all"></br>
                  <Button
                    className="btn-fill pull-right send-tn"
                    type="button"
                    variant="success"
                    onClick={() => confirmMessage()}
                  >
                    Envoyer
                  </Button>
                </Col>
              </Card>
            </>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default DetailEvaluation;
