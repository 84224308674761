import { createAsyncThunk } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");


export const fetchPlanCoaching= createAsyncThunk("planCoaching/fetchPlanCoaching", async () => {
    const response = await fetch(Configuration.BACK_BASEURL + "planCoaching/allPlanCoaching", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
  
    });
    const planCoaching = await response.json();
    return planCoaching;
  });

  
export const getPlanCoachings = createAsyncThunk("planCoaching/getPlanCoachings", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "planCoaching/getPlanCoachings", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action),
  });
  const planCoaching = await response.json();
  return planCoaching;
});

  
  export const planCoachingAdded = createAsyncThunk("planCoaching/addPlanCoaching", async (action) => {
      const response = await fetch(Configuration.BACK_BASEURL + "planCoaching/addPlanCoaching", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(action),
      });
      const planCoaching = await response.json();
      return planCoaching;
    });

    export const planCoachingGetById = createAsyncThunk("planCoaching/planCoachingGetById", async (id1) => {
        const  id  = id1;
        const response = await fetch(Configuration.BACK_BASEURL + "planCoaching/getPlanCoaching", {
          method: 'POST',
          headers: {
            'id':id,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token':token
          },
        
        });
        const planCoaching = await response.json();
        return planCoaching;
      });

      export const planCoachingChangeEtat = createAsyncThunk("planCoaching/changeEtat",async (id) => {
        const response = await fetch(
          Configuration.BACK_BASEURL + "planCoaching/changeEtat/" + id,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          }
        );
        const planCoaching = await response.json();
        return planCoaching;
      }
    );
    
      