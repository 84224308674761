import React, { useEffect } from "react";
import Select from "react-select";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { planAdded, planGetById } from "../../../Redux/planReduce";

function UpdatePlan() {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();

  const [avancement, setAvancement] = React.useState({
    value: "0",
    label: "Avancement",
    isDisabled: true,
  });
  const [optionsAvancement] = React.useState([
    {
      value: "0",
      label: "Avancement",
      isDisabled: true,
    },
    {
      value: "1",
      label: "0%",
    },
    {
      value: "2",
      label: "20%",
    },
    {
      value: "3",
      label: "40%",
    },
    {
      value: "4",
      label: "60%",
    },
    {
      value: "5",
      label: "80%",
    },
    {
      value: "6",
      label: "100%",
    },
  ]);

  const [id, setId] = React.useState(0);
  const [testBtn, setTestBtn] = React.useState(false);

  function submitForm() {
    var avancementLabel = avancement.label;
    setTestBtn(true);
    dispatch(
      planAdded({
        id,
        avancementLabel,
      })
    );
    notify(1, "Modifié avec succès");
    setTimeout(async () => {
      listPlan();
    }, 1500);
  }

  useEffect(() => {
    async function getPlan() {
      if (isNaN(location.id) === false) {
        var plan = await dispatch(planGetById(location.id));
        setId(plan.payload.id);
        if (plan.payload.avancement == "0%") {
          setAvancement({
            value: "1",
            label: "0%",
          });
        } else if (plan.payload.avancement == "20%") {
          setAvancement({
            value: "2",
            label: "20%",
          });
        } else if (plan.payload.avancement == "40%") {
          setAvancement({
            value: "3",
            label: "40%",
          });
        } else if (plan.payload.avancement == "60%") {
          setAvancement({
            value: "3",
            label: "60%",
          });
        } else if (plan.payload.avancement == "80%") {
          setAvancement({
            value: "3",
            label: "80%",
          });
        } else if (plan.payload.avancement == "100%") {
          setAvancement({
            value: "3",
            label: "100%",
          });
        }
      }
    }
    getPlan();
  }, [location.id, dispatch]);

  function listPlan() {
    navigate.push("/listPlan");
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={(event) => {
                    navigate.goBack();
                  }}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">{"Modifier Plan"}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="axeClass">
                            <label> Avancement * </label>
                            <Select
                              placeholder="Référence"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={avancement}
                              onChange={(value) => {
                                setAvancement(value);
                              }}
                              options={optionsAvancement}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          onClick={() => submitForm()}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default UpdatePlan;
